import React, { useContext, useState, useEffect } from "react";
import { Formik, isEmptyArray } from "formik";
import * as Yup from "yup";
import CurrencyFormat from "react-currency-format";
import "react-datepicker/dist/react-datepicker.css";
import AddCassettesConfiguration from "./AddCassettesConfiguration";
import { useDateTime } from "../../../../components/Helper/DateTime";
import MultiStepFormContext from "./MultiStepFormContext";

export const EjCashUnload = () => {
  const [errors, setErrors] = useState({});
  const {
    ejCashUnLoadData,
    setEjCashUnLoadData,
    ejRetractCashUnLoadData,
    setEjRetractCashUnLoadData,
    ejRejectedCashUnLoadData,
    setEjRejectedCashUnLoadData,
    next,
    devicesData,
    details,
    setDetails,
  } = useContext(MultiStepFormContext);

  const { currency } = useDateTime();
  // const addTableRows = () => {
  //   const rowsInput = {
  //     cassetteType: "",
  //     denomination: "",
  //     billCount: "",
  //     isNewRow: true,
  //   };
  //   setEjCashUnLoadData([...ejCashUnLoadData, rowsInput]);
  // };

  const deleteTableRows = (index) => {
    const rows = [...ejCashUnLoadData];
    rows.splice(index, 1);
    setEjCashUnLoadData(rows);
  };

  const handleChange = (index, evnt, type) => {
    const { name, value } = evnt.target;
    if (value)
      setErrors((prev) => ({
        ...prev,
        billCount: {
          ...prev.billCount,
          [index]: null,
        },
      }));
    if (type === "ejCashUnLoadData") {
      setEjCashUnLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    } else if (type === "ejRejectedCashUnLoadData") {
      setEjRejectedCashUnLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    } else if (type === "ejRetractCashUnLoadData") {
      setEjRetractCashUnLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    }
  };

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      "0"
    );

  const grandTotal = () => {
    setDetails({
      ...details,
      ejCashUnLoadTotalAmount: ejCashUnLoadData?.reduce(
        (acc, row) =>
          acc + parseInt(row.denomination ?? 0) * parseInt(row.billCount),
        0
      ),
      ejRejectedCashUnLoadTotalAmount: ejRejectedCashUnLoadData?.reduce(
        (acc, row) =>
          acc + parseInt(row.denomination ?? 0) * parseInt(row.billCount),
        0
      ),
      ejRetractCashUnLoadTotalAmount: ejRetractCashUnLoadData?.reduce(
        (acc, row) =>
          acc + parseInt(row.denomination ?? 0) * parseInt(row.billCount),
        0
      ),
    });
  };

  useEffect(() => {
    if (!isEmptyArray(ejCashUnLoadData)) {
      return;
    } else {
      let deviceCassettes = devicesData?.[0]?.billCassetteConfig?.map(
        (item) => ({
          denomination: item.denom,
          cassetteType: item.type,
          billCount: "",
        })
      );
      setEjCashUnLoadData(deviceCassettes || []);
    }
    if (!isEmptyArray(ejRetractCashUnLoadData)) {
      return;
    } else {
      let deviceCassettes = devicesData?.[0]?.retractCassetteConfig?.map(
        (item) => ({
          denomination: item.denom,
          cassetteType: item.type,
          billCount: "",
        })
      );
      setEjRetractCashUnLoadData(deviceCassettes || []);
    }
    if (!isEmptyArray(ejRejectedCashUnLoadData)) {
      return;
    } else {
      let deviceCassettes = devicesData?.[0]?.rejectedCassetteConfig?.map(
        (item) => ({
          denomination: item.denom,
          cassetteType: item.type,
          billCount: "",
        })
      );
      setEjRejectedCashUnLoadData(deviceCassettes || []);
    }
  }, [
    devicesData,
    setEjCashUnLoadData,
    ejCashUnLoadData,
    setEjRejectedCashUnLoadData,
    ejRejectedCashUnLoadData,
    setEjRetractCashUnLoadData,
    ejRetractCashUnLoadData,
  ]);

  useEffect(() => {
    grandTotal();
  }, [ejCashUnLoadData, ejRejectedCashUnLoadData, ejRetractCashUnLoadData]);

  return (
    <Formik
      initialValues={ejCashUnLoadData}
      enableReinitialize={true}
      // onSubmit={(values) => {
      //   let error = false;
      //   values.forEach((el, i) => {
      //     if (!el.billCount) {
      //       error = true;
      //       setErrors((prev) => ({
      //         ...prev,
      //         billCount: {
      //           ...prev.billCount,
      //           [i]: "Required*",
      //         },
      //       }));
      //     }
      //   });
      //   if (!error) {
      //     setEjCashUnLoadData(values);
      //     next();
      //   }
      // }}
      onSubmit={(values) => {
        let error = false;
        values.forEach((el, i) => {
          if (!el.billCount || isNaN(el.billCount)) {
            error = true;
            setErrors((prev) => ({
              ...prev,
              billCount: {
                ...prev.billCount,
                [i]: "Required number",
              },
            }));
          }
        });
        if (!error) {
          setEjCashUnLoadData(values);
          next();
        }
      }}
    >
      {({ handleSubmit, touched }) => {
        return (
          <>
            <div className="container pb-5">
              <div className="row pt-3">
                <h5 className="pl-3">{"Cash Unload > ATM Scroll"}</h5>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="row mt-3 d-flex justify-content-start  align-items-start">
                    <div className="col-md">
                      <div className="text-left">Denomination</div>
                    </div>
                    <div className="col-md">
                      <div className="text-left">Bill Count</div>
                    </div>

                    <div className="col-md">
                      <div className="text-left">Cassette Type</div>
                    </div>

                    <div className="col-md">
                      <div className="text-left">Total</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-10">
                  <AddCassettesConfiguration
                    rowsData={[ejCashUnLoadData]}
                    deleteTableRows={deleteTableRows}
                    handleChange={(index, evnt) =>
                      handleChange(index, evnt, "ejCashUnLoadData")
                    }
                    isCassetteType={true}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              {/* <div className="row">
                <div className="col-12 mt-5">
                  <div className="text-center">
                    <h5>
                      <strong>
                        Total Cash Unload:{" "}
                        {formatAmount(details?.ejCashUnLoadTotalAmount)}
                      </strong>
                    </h5>
                  </div>
                </div>
              </div> */}
              {ejRetractCashUnLoadData?.length > 0 && (
                <>
                  {" "}
                  <div className="row pt-5">
                    <h5 className="pl-3">Retract Bins</h5>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <div className="row mt-3 d-flex justify-content-start  align-items-start">
                        <div className="col-md">
                          <div className="text-left">Denomination</div>
                        </div>
                        <div className="col-md">
                          <div className="text-left">Bill Count</div>
                        </div>

                        <div className="col-md">
                          <div className="text-left">Cassette Type</div>
                        </div>

                        <div className="col-md">
                          <div className="text-left">Total</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10">
                      <AddCassettesConfiguration
                        rowsData={[ejRetractCashUnLoadData]}
                        deleteTableRows={deleteTableRows}
                        handleChange={(index, evnt) =>
                          handleChange(index, evnt, "ejRetractCashUnLoadData")
                        }
                        isCassetteType={true}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>
                </>
              )}
              {ejRejectedCashUnLoadData?.length > 0 && (
                <>
                  {" "}
                  <div className="row pt-5">
                    <h5 className="pl-3">Reject Bins</h5>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <div className="row mt-3 d-flex justify-content-start  align-items-start">
                        <div className="col-md">
                          <div className="text-left">Denomination</div>
                        </div>
                        <div className="col-md">
                          <div className="text-left">Bill Count</div>
                        </div>

                        <div className="col-md">
                          <div className="text-left">Cassette Type</div>
                        </div>

                        <div className="col-md">
                          <div className="text-left">Total</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10">
                      <AddCassettesConfiguration
                        rowsData={[ejRejectedCashUnLoadData]}
                        deleteTableRows={deleteTableRows}
                        handleChange={(index, evnt) =>
                          handleChange(index, evnt, "ejRejectedCashUnLoadData")
                        }
                        isCassetteType={true}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                {(ejRejectedCashUnLoadData?.length > 0 ||
                  ejRetractCashUnLoadData?.length > 0) && (
                  <div className="col-8 mt-5">
                    <div className="text-right">
                      <h5>
                        <strong className="text-info">
                          Total:{" "}
                          {formatAmount(
                            details?.ejRejectedCashUnLoadTotalAmount +
                              details?.ejCashUnLoadTotalAmount +
                              details?.ejRetractCashUnLoadTotalAmount
                          )}
                        </strong>
                      </h5>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-info ml-2"
                    onClick={handleSubmit}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};
export default EjCashUnload;
