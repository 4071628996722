import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import NewFilter from "../../components/FilterDropdown/NewFilter";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import { useDateTime } from "../../components/Helper/DateTime";
import axiosInstance from "../../utils/axiosInstance";
import Toast from "../../components/Toast/Toast";
import { generalSettings } from "./data";
import {
  Accordion,
  Button,
  Card,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const Profile = () => {
  const { date, time, dateTime, setDate, setTime, setDateTime, setRefresh } =
    useDateTime();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [generalSetting, setGeneralSetting] = useState({});
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
    oldPassword: "",
  });
  const userID = JSON.parse(localStorage.getItem("userData"))?.userId;
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [userPreferences, setUserPreferences] = useState([]);
  const [notificationSetting, setNotificationSetting] = useState([]);
  const [generalSettingFlag, setGeneralSettingFlag] = useState(false);
  const [securitySettingFlag, setSecuritySettingFlag] = useState(false);
  const [userPreferencesFlag, setUserPreferencesFlag] = useState(false);
  const [notificationSettingFlag, setNotificationSettingFlag] = useState(false);
  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordError(errMsg);
    }
    // for confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" &&
        passwordInput.confirmPassword.length > 0)
    ) {
      if (passwordInput.confirmPassword !== passwordInput.password) {
        setConfirmPasswordError("Confirm password is not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };
  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {
      ...passwordInput,
      [passwordInputFieldName]: passwordInputValue,
    };
    setPasswordInput(NewPasswordInput);
  };
  const handleSubmit = () => {
    if (
      passwordInput.password.length > 0 &&
      passwordInput.confirmPassword.length > 0 &&
      passwordInput.password === passwordInput.confirmPassword &&
      passwordError === "" &&
      confirmPasswordError === ""
    ) {
      axiosInstance
        .post("/users/change-password", {
          data: {
            newPassword: passwordInput.password,
            currentPassword: passwordInput.oldPassword,
            userId: userID,
            loggedInUserId: userID,
          },
        })
        .then((res) => {
          // console.log(res);
          if (res.message.status === 200) {
            Toast("User password updated successfully!", "success");
            setPasswordInput({
              password: "",
              confirmPassword: "",
              oldPassword: "",
            });
            setPasswordError("");
            setConfirmPasswordError("");
          }
        })
        .catch((err) => {
          console.log(err);
          Toast(err?.response?.data?.message?.description, "error");
        });
    }
    setDate(filters.date);
    setTime(filters.time);
    setDateTime(filters.dateTime);
    axiosInstance
      .put(`/users/${userID}/preferences`, {
        data: {
          userPreferences: [
            {
              key: "dateFormat",
              value: filters.date,
            },
            {
              key: "timeFormat",
              value: filters.time,
            },
            {
              key: "dateTimeFormat",
              value: filters.dateTime,
            },
          ],
        },
      })
      .then((res) => {
        if (res.message.status === "200") {
          Toast("User preferences updated successfully!", "success");
        } else {
          Toast(res.message.description, "error");
        }
      });

    axiosInstance
      .put(`/users/profile`, {
        data: {
          user: {
            username: generalSetting.email,
            firstName: generalSetting.firstName,
            middleName: generalSetting.middleName,
            lastName: generalSetting.lastName,
            empCode: generalSetting.empCode,
            mobileNo: generalSetting.mobileNo,
            officePhone: generalSetting.officePhone,
            officePhoneExt: generalSetting.officePhoneExt,
          },
        },
      })
      .then((res) => {
        if (res.message.status === "200") {
          const existingDataString = localStorage.getItem("userData");

          if (existingDataString) {
            const existingData = JSON.parse(existingDataString);
            if (res && res.data.user) {
              Object.assign(existingData, res.data.user);
            }
            setTime(filters.time);
            setDate(filters.date);
            setDateTime(filters.dateTime);
            localStorage.setItem(
              "userData",
              JSON.stringify({
                ...existingData,
                userPreferences: [
                  {
                    prefKeys: "dateFormat",
                    prefValues: filters.date,
                  },
                  {
                    prefKeys: "timeFormat",
                    prefValues: filters.time,
                  },
                  {
                    prefKeys: "dateTimeFormat",
                    prefValues: filters.dateTime,
                  },
                ],
              })
            );
          }
          Toast("User setting updated successfully!", "success");
        } else {
          Toast(res.message.description, "error");
        }
      });
    const webNotifyData = notificationSetting.map((item) => {
      return item?.webNotification
        ? {
            id: item?.webNotification?.id,
            userId: item?.webNotification?.userId,
            notificationId: item?.webNotification?.notificationId,
            isEnabled: item?.webNotification?.isEnabled,
          }
        : null;
    });
    const emailNotifyData = notificationSetting.map((item) => {
      return item?.emailNotification
        ? {
            id: item?.emailNotification?.id,
            userId: item?.emailNotification?.userId,
            notificationId: item?.emailNotification?.notificationId,
            isEnabled: item?.emailNotification?.isEnabled,
          }
        : null;
    });

    const notifyData = [
      ...webNotifyData.filter((item) => item !== null),
      ...emailNotifyData.filter((item) => item !== null),
    ];
    axiosInstance
      .put("/notification", {
        data: notifyData,
      })
      .then((res) => {
        if (res?.message?.status === "200") {
          Toast("User notification settings updated successfully!", "success");
        } else {
          Toast(res?.message?.description, "error");
        }
      });
  };

  const getPreferences = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance("/common?types=preference_list");
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        // console.log("response", response);
        let prefArray = mapToArray(response.data.preferenceList);
        setUserPreferences(prefArray);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const getNotificationConfig = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/notification`);
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        setNotificationSetting(response.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const mapToArray = (pref) => {
    let prefArray = [];
    for (let key in pref) {
      if (key === "dateFormat") {
        pref[key].type = "date";
      } else if (key === "timeFormat") {
        pref[key].type = "time";
      } else {
        pref[key].type = "dateTime";
      }
      pref[key].values = pref[key].values.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      prefArray.push(pref[key]);
    }
    return prefArray;
  };
  const setGeneralSettings = () => {
    setGeneralSetting(JSON.parse(localStorage.getItem("userData")));
  };
  useEffect(() => {
    getPreferences();
    getNotificationConfig();
    setFilters({ date, time, dateTime });
    setGeneralSettings();
    setRefresh(() => () => {
      getPreferences();
      getNotificationConfig();
      setFilters({ date, time, dateTime });
      setGeneralSettings();
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, [date, time, dateTime]);
  const onSwitchChange = (notifyId) => {
    setNotificationSetting(
      notificationSetting.map((item) => {
        if (item?.emailNotification?.notificationId === notifyId) {
          return {
            ...item,
            emailNotification: {
              ...item.emailNotification,
              isEnabled: !item.emailNotification.isEnabled,
            },
          };
        } else if (item?.webNotification?.notificationId === notifyId) {
          return {
            ...item,
            webNotification: {
              ...item.webNotification,
              isEnabled: !item.webNotification.isEnabled,
            },
          };
        }
        return item;
      })
    );
  };
  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div
        className="card-secondary"
        style={{
          opacity: !loading ? "1" : "0.07",
        }}
      >
        <Accordion activeKey={generalSettingFlag} className="pt-3">
          <Card className="card-primary">
            <Card.Header>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={true}
                onClick={() => setGeneralSettingFlag(!generalSettingFlag)}
                className="p-0"
                style={{
                  textDecoration: "none",
                  boxShadow: "none",
                }}
              >
                <i class="fa fa-cog" aria-hidden="true"></i> General Settings
              </Accordion.Toggle>
              {generalSettingFlag ? (
                <Button
                  className="float-right p-0"
                  style={{
                    textDecoration: "none",
                    boxShadow: "none",
                  }}
                  variant="link"
                  onClick={() => setGeneralSettingFlag(!generalSettingFlag)}
                >
                  <i className="fa fa-caret-up" aria-hidden="true"></i>
                </Button>
              ) : (
                <Button
                  className="float-right p-0"
                  style={{
                    textDecoration: "none",
                    boxShadow: "none",
                  }}
                  variant="link"
                  onClick={() => setGeneralSettingFlag(!generalSettingFlag)}
                >
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </Button>
              )}
            </Card.Header>
            <Accordion.Collapse
              eventKey={generalSettingFlag}
              in={generalSettingFlag}
            >
              <Card.Body className="card-secondary m-0 pb-2">
                {" "}
                <div className="row ml-5">
                  <div className="col-lg-12 col-xl-12">
                    {generalSettings?.map((item, index) => (
                      <div className="row" key={index}>
                        <div className="col-lg-6 col-xl-6 p-0 mr-1">
                          <div className="info-tile value  ">{item.label}</div>
                        </div>
                        <div className="col-lg-6 col-xl-5 p-0">
                          <div className="info-tile value color-light border-left-sq border-right-rounded">
                            {/* {localStorage.getItem(item.key) !== "null"
                        ? localStorage.getItem(item.key)
                        : "Not set"} */}
                            <input
                              type={"text"}
                              value={generalSetting[item.key]}
                              disabled={item.label === "Email"}
                              className="value  "
                              placeholder={"Enter " + item.label}
                              name={item.label}
                              onChange={(e) => {
                                setGeneralSetting({
                                  ...generalSetting,
                                  [item.key]: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* </div> */}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion activeKey={securitySettingFlag} className="pt-3">
          <Card className="card-primary">
            <Card.Header>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={true}
                onClick={() => setSecuritySettingFlag(!securitySettingFlag)}
                className="p-0"
                style={{
                  textDecoration: "none",
                  boxShadow: "none",
                }}
              >
                <i class="fa fa-shield" aria-hidden="true"></i> Security
                Settings
              </Accordion.Toggle>
              {securitySettingFlag ? (
                <Button
                  className="float-right p-0"
                  style={{
                    textDecoration: "none",
                    boxShadow: "none",
                  }}
                  variant="link"
                  onClick={() => setSecuritySettingFlag(!securitySettingFlag)}
                >
                  <i className="fa fa-caret-up" aria-hidden="true"></i>
                </Button>
              ) : (
                <Button
                  className="float-right p-0"
                  style={{
                    textDecoration: "none",
                    boxShadow: "none",
                  }}
                  variant="link"
                  onClick={() => setSecuritySettingFlag(!securitySettingFlag)}
                >
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </Button>
              )}
            </Card.Header>
            <Accordion.Collapse
              eventKey={securitySettingFlag}
              in={securitySettingFlag}
            >
              <Card.Body className="card-secondary m-0 pb-2 h-100">
                <div className="row ml-4">
                  <div className="col-12">
                    <div className="col-lg-12 col-xl-12">
                      <div className="row">
                        <div className="col-lg-6 col-xl-6 p-0 mr-1">
                          <div className="info-tile value  ">Old Password</div>
                        </div>
                        <div className="col-lg-6 col-xl-5 p-0">
                          <div className="info-tile value color-light border-left-sq border-right-rounded">
                            <input
                              type={"password"}
                              className="value  "
                              placeholder={"Enter old password"}
                              name={"oldPassword"}
                              onChange={handlePasswordChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={`row ${passwordError ? "mb-4" : ""}`}>
                        <div className="col-lg-6 col-xl-6 p-0 mr-1">
                          <div className="info-tile value  ">New Password</div>
                        </div>
                        <div className="col-lg-6 col-xl-5 p-0">
                          <div className="info-tile value color-light border-left-sq border-right-rounded">
                            <input
                              type={"password"}
                              className="value   mb-3"
                              placeholder={"Enter new password"}
                              name={"password"}
                              onChange={handlePasswordChange}
                              onKeyUp={handleValidation}
                            />
                            <p className="text-danger">{passwordError}</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`row ${confirmPasswordError ? "mb-4" : ""}`}
                      >
                        <div className="col-lg-6 col-xl-6 p-0 mr-1">
                          <div className="info-tile value  ">
                            Confirm Password
                          </div>
                        </div>
                        <div className="col-lg-6 col-xl-5 p-0">
                          <div className="info-tile value color-light border-left-sq border-right-rounded">
                            <input
                              type={"password"}
                              className="value   mb-3"
                              placeholder={"Enter confirm password"}
                              name="confirmPassword"
                              onChange={handlePasswordChange}
                              onKeyUp={handleValidation}
                            />
                            <p className="text-danger">
                              {confirmPasswordError}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-xl-6 p-0 mr-1">
                          <div className="info-tile value  ">
                            Two-Factor Authentication
                          </div>
                        </div>
                        <div className="col-lg-6 col-xl-5 p-0">
                          <div className="info-tile value color-light border-left-sq border-right-rounded">
                            <input
                              type={"text"}
                              className="value  "
                              placeholder={"Disabled"}
                              value={"Disabled"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion activeKey={userPreferencesFlag} className="pt-3">
          <Card className="card-primary">
            <Card.Header>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={true}
                onClick={() => setUserPreferencesFlag(!userPreferencesFlag)}
                className="p-0"
                style={{
                  textDecoration: "none",
                  boxShadow: "none",
                }}
              >
                <i class="fa fa-user" aria-hidden="true"></i> User Preferences
              </Accordion.Toggle>
              {userPreferencesFlag ? (
                <Button
                  className="float-right p-0"
                  style={{
                    textDecoration: "none",
                    boxShadow: "none",
                  }}
                  variant="link"
                  onClick={() => setUserPreferencesFlag(!userPreferencesFlag)}
                >
                  <i className="fa fa-caret-up" aria-hidden="true"></i>
                </Button>
              ) : (
                <Button
                  className="float-right p-0"
                  style={{
                    textDecoration: "none",
                    boxShadow: "none",
                  }}
                  variant="link"
                  onClick={() => setUserPreferencesFlag(!userPreferencesFlag)}
                >
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </Button>
              )}
            </Card.Header>
            <Accordion.Collapse
              eventKey={userPreferencesFlag}
              in={userPreferencesFlag}
            >
              <Card.Body className="card-secondary m-0 pb-2 h-100">
                <div
                  className="row ml-4"
                  style={{
                    maxHeight: "none",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <div className="col-12">
                    <div className="col-lg-12 col-xl-12">
                      {userPreferences?.map((item, index) => (
                        <div className="row" key={index}>
                          <div className="col-lg-6 col-xl-6 p-0 mr-1">
                            <div className="info-tile value  ">
                              {item.label}
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-5 p-0">
                            <NewFilter
                              classes="no-border mt-2 ml-1"
                              btnClasses="border-left-sq"
                              title={
                                filters[item.type]
                                  ? filters[item.type]
                                  : "Select Format"
                              }
                              data={item.values}
                              type="checkbox"
                              filter={filters[item.type]}
                              field={"value"}
                              onChange={(val) => {
                                setFilters({
                                  ...filters,
                                  [item.type]: val,
                                });
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion activeKey={notificationSettingFlag} className="pt-3">
          <Card className="card-primary">
            <Card.Header>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={notificationSettingFlag}
                onClick={() =>
                  setNotificationSettingFlag(!notificationSettingFlag)
                }
                className="p-0"
                style={{
                  textDecoration: "none",
                  boxShadow: "none",
                }}
              >
                <i class="fa fa-bell" aria-hidden="true"></i> Notification
                Settings
              </Accordion.Toggle>
              {notificationSettingFlag ? (
                <Button
                  className="float-right p-0"
                  style={{
                    textDecoration: "none",
                    boxShadow: "none",
                  }}
                  variant="link"
                  onClick={() =>
                    setNotificationSettingFlag(!notificationSettingFlag)
                  }
                >
                  <i className="fa fa-caret-up" aria-hidden="true"></i>
                </Button>
              ) : (
                <Button
                  className="float-right p-0"
                  style={{
                    textDecoration: "none",
                    boxShadow: "none",
                  }}
                  variant="link"
                  onClick={() =>
                    setNotificationSettingFlag(!notificationSettingFlag)
                  }
                >
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </Button>
              )}
            </Card.Header>
            <Accordion.Collapse
              eventKey={notificationSettingFlag}
              in={notificationSettingFlag}
            >
              {notificationSetting?.length > 0 ? (
                <Card.Body className="card-secondary m-0 pb-2">
                  <div className="row ml-4">
                    <div className="col-12">
                      <div className="col-lg-12 col-xl-12">
                        <div className="row">
                          <div className="col-lg-12 col-xl-12 p-0">
                            <div className="row mt-2">
                              <div className="offset-lg-8 col-lg-2 col-xl-2">
                                Web
                              </div>
                              <div className="col-lg-2 col-xl-2">Email</div>
                            </div>
                            {notificationSetting?.map((item, index) => (
                              <div
                                className="info-tile"
                                style={{
                                  borderRadius: "0px",
                                  maxHeight: "none",
                                }}
                                key={index}
                              >
                                <Form>
                                  <div className="row">
                                    <div className="col-md-8">
                                      <Form.Label
                                        className="mb-0"
                                        style={{ textTransform: "math-auto" }}
                                      >
                                        {item?.notificationLabel}
                                      </Form.Label>
                                    </div>
                                    {item?.webNotification ? (
                                      <div className="col-md-2">
                                        <Form.Check
                                          type="switch"
                                          id={`custom-switch-${index}`}
                                          className="ml-2"
                                          defaultChecked={
                                            item?.webNotification?.isEnabled
                                          }
                                          onChange={() => {
                                            onSwitchChange(
                                              item?.webNotification
                                                ?.notificationId
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div className="col-md-2">
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id={`tooltip-${index}`}>
                                              Disabled
                                            </Tooltip>
                                          }
                                        >
                                          <div className="d-inline-block">
                                            <Form.Check
                                              type="switch"
                                              id={`custom-switch-${index}`}
                                              className="ml-2"
                                              defaultChecked={false}
                                              disabled
                                            />
                                          </div>
                                        </OverlayTrigger>
                                      </div>
                                    )}
                                    {item?.emailNotification ? (
                                      <div className="col-md-2">
                                        <Form.Check
                                          type="switch"
                                          id={`custom-switch-${99 - index}`}
                                          className="ml-2"
                                          defaultChecked={
                                            item?.emailNotification?.isEnabled
                                          }
                                          onChange={() => {
                                            onSwitchChange(
                                              item.emailNotification
                                                ?.notificationId
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div className="col-md-2">
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id={`tooltip-${index}`}>
                                              Disabled
                                            </Tooltip>
                                          }
                                        >
                                          <div className="d-inline-block">
                                            <Form.Check
                                              type="switch"
                                              id={`custom-switch-${99 - index}`}
                                              className="ml-2"
                                              defaultChecked={false}
                                              disabled
                                            />
                                          </div>
                                        </OverlayTrigger>
                                      </div>
                                    )}
                                  </div>
                                </Form>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              ) : (
                <div className="card-secondary m-0 pb-2">
                  <p className="text-center">No Notification Available</p>
                </div>
              )}
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <div className="row mt-sm-5">
          <div className="col-12">
            <div className="row">
              <div className="col-sm-1 col-lg-1 offset-5">
                <button className="btn btn-info" onClick={handleSubmit}>
                  Save
                </button>
              </div>
              <div className="col-sm-1 col-lg-1">
                <button
                  className="btn btn-info"
                  onClick={() => history.push("/")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
