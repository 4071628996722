import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FormCheck, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select, { components } from "react-select";
import ButtonBasic from "../../../../components/Buttons/ButtonBasic";
import OperatorDescription from "../OperatorDescription.json";
import {
  errorStyling,
  MatchingRuleValidationSchema,
} from "../ValidationSchema";
import Toast from "../../../../components/Toast/Toast";

const MatchingRuleModal = ({
  values,
  operatorOptions,
  dsaFields,
  dsbFields,
  handleSubmit,
  show,
  onHide,
  editObject,
}) => {
  const [formValues, setFormValues] = useState(values);
  useEffect(() => {
    if (values) setFormValues(values);
  }, [values]);
  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={(values) => {
        if (
          values?.operator?.split("(")?.[0]?.includes("FUZZY") &&
          !values?.operator?.match(/\(([^)]+)\)/)?.[1]
        ) {
          Toast(
            "Matching % is mandatory for " +
              operatorOptions?.matchingRuleOperators?.find(
                (operator) =>
                  operator.value === values?.operator?.split("(")?.[0]
              )?.label +
              " operator",
            "error"
          );
          return;
        }
        if (
          values?.hasOwnProperty("ds_a_field_func") &&
          !values?.ds_a_field_func
        ) {
          Toast("DSA Field Function is mandatory", "error");
          return;
        } else if (values?.ds_a_field_func?.split("(")[0] === "VAR") {
          if (values?.ds_a_field_func?.includes("%")) {
            if (
              !values.ds_a_field_func?.match(/-([\d.]+)%/)?.[1] ||
              !values.ds_a_field_func?.match(/\+([\d.]+)%/)?.[1]
            ) {
              Toast("Minimum and Maximum fields are mandatory", "error");
              return;
            }
          }
          if (
            !values.ds_a_field_func?.match(/(?<=-)[\d.]+/)?.[0] ||
            !values.ds_a_field_func?.match(/(?<=\+)[\d.]+/)?.[0]
          ) {
            Toast("Minimum and Maximum fields are mandatory", "error");
            return;
          }
        } else if (values?.ds_a_field_func?.split("(")[0] === "LEFT") {
          if (!values.ds_a_field_func?.match(/(?<=\().*?(?=\))/)?.[0]) {
            Toast("No. of characters are mandatory", "error");
            return;
          }
        } else if (values?.ds_a_field_func?.split("(")[0] === "RIGHT") {
          if (!values.ds_a_field_func?.match(/(?<=\().*?(?=\))/)?.[0]) {
            Toast("No. of characters are mandatory", "error");
            return;
          }
        } else if (values?.ds_a_field_func?.split("(")[0] === "SUBSTR") {
          if (
            !values.ds_a_field_func?.match(/(?<=\().*?(?=:)/)?.[0] ||
            !values.ds_a_field_func?.match(/(?<=:).*?(?=\))/)?.[0]
          ) {
            Toast("Start and End Characters are mandatory", "error");
            return;
          }
        } else if (
          values?.ds_a_field_func?.split("(")[0] === "REMOVE-SPECIAL-CHAR"
        ) {
          if (!values.ds_a_field_func?.match(/(?<=\().*?(?=\))/)?.[0]) {
            Toast("Regex are mandatory", "error");
            return;
          }
        }
        if (
          values?.hasOwnProperty("ds_b_field_func") &&
          !values?.ds_b_field_func
        ) {
          Toast("DSB Field Function is mandatory", "error");
          return;
        } else if (values?.ds_b_field_func?.split("(")[0] === "VAR") {
          if (values?.ds_b_field_func?.includes("%")) {
            if (
              !values.ds_b_field_func?.match(/-([\d.]+)%/)?.[1] ||
              !values.ds_b_field_func?.match(/\+([\d.]+)%/)?.[1]
            ) {
              Toast("Minimum and Maximum fields are mandatory", "error");
              return;
            }
          }
          if (
            !values.ds_b_field_func?.match(/(?<=-)[\d.]+/)?.[0] ||
            !values.ds_b_field_func?.match(/(?<=\+)[\d.]+/)?.[0]
          ) {
            Toast("Minimum and Maximum fields are mandatory", "error");
            return;
          }
        } else if (values?.ds_b_field_func?.split("(")[0] === "LEFT") {
          if (!values.ds_b_field_func?.match(/(?<=\().*?(?=\))/)?.[0]) {
            Toast("No. of characters are mandatory", "error");
            return;
          }
        } else if (values?.ds_b_field_func?.split("(")[0] === "RIGHT") {
          if (!values.ds_b_field_func?.match(/(?<=\().*?(?=\))/)?.[0]) {
            Toast("No. of characters are mandatory", "error");
            return;
          }
        } else if (values?.ds_b_field_func?.split("(")[0] === "SUBSTR") {
          if (
            !values.ds_b_field_func?.match(/(?<=\().*?(?=:)/)?.[0] ||
            !values.ds_b_field_func?.match(/(?<=:).*?(?=\))/)?.[0]
          ) {
            Toast("Start and End Characters are mandatory", "error");
            return;
          }
        } else if (
          values?.ds_b_field_func?.split("(")[0] === "REMOVE-SPECIAL-CHAR"
        ) {
          if (!values.ds_b_field_func?.match(/(?<=\().*?(?=\))/)?.[0]) {
            Toast("Regex are mandatory", "error");
            return;
          }
        }
        values = Object.fromEntries(
          Object.entries(values).filter(([, v]) => v !== "")
        );
        handleSubmit(editObject?.path, values);
        setFormValues({});
        onHide();
      }}
      validationSchema={MatchingRuleValidationSchema}
    >
      {({ handleSubmit, errors, values, setFieldValue, setValues }) => (
        <Form id="matchingRuleForm" className="form" onSubmit={handleSubmit}>
          <Modal
            backdrop={true}
            size="xl"
            aria-labelledby="example-modal-sizes-title-lg"
            show={show}
            onHide={() => onHide()}
          >
            <Modal.Header closeButton>
              {editObject?.isNew
                ? "Add Matching Rule Field"
                : "Edit Matching Rule Field"}
            </Modal.Header>
            <Modal.Body scrollable="true">
              <div className="p-3 my-3">
                <div className="row">
                  <div className="col-3">
                    {/* DropDown */}
                    <div className="workflowModalTitle">DSA Field Name</div>
                    <div title={values?.ds_a_field_name}>
                      <Select
                        options={dsaFields}
                        name={"ds A Field Name"}
                        value={dsaFields?.find(
                          (field) => field.value === values?.ds_a_field_name
                        )}
                        onChange={(e) =>
                          setFieldValue(`ds_a_field_name`, e?.value)
                        }
                        isClearable
                      />
                    </div>
                    <ErrorMessage
                      component={() => (
                        <div style={errorStyling}>
                          {errors?.ds_a_field_name}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-3">
                    <div className="workflowModalTitle">Operator</div>
                    <div
                      title={
                        operatorOptions?.matchingRuleOperators?.find(
                          (operator) =>
                            operator.value === values?.operator?.split("(")[0]
                        )?.label
                      }
                    >
                      <Select
                        options={operatorOptions?.matchingRuleOperators}
                        name={"Operator"}
                        value={operatorOptions?.matchingRuleOperators?.find(
                          (operator) =>
                            operator.value === values?.operator?.split("(")[0]
                        )}
                        onChange={(e) => {
                          const newValue = e?.value;
                          if (newValue?.includes("FUZZY")) {
                            setFieldValue(`operator`, `${newValue}(0)`);
                          } else if (newValue?.split("(")?.[0] === "IN") {
                            setFieldValue(
                              `operator`,
                              `IN(ds_a_field_name,ds_b_field_name)`
                            );
                          } else if (newValue?.split("(")?.[0] === "NIN") {
                            setFieldValue(
                              `operator`,
                              `NIN(ds_a_field_name,ds_b_field_name)`
                            );
                          } else if (
                            newValue?.split("(")?.[0] === "REGEX-MATCH"
                          ) {
                            setValues({
                              ...values,
                              operator: newValue,
                              regex: "",
                            });
                          } else {
                            setFieldValue(`operator`, newValue);
                          }
                        }}
                        components={{
                          Option: (props) =>
                            OperatorDescription?.find(
                              (op) => op?.operator == props?.data?.value
                            )?.description ? (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {
                                      OperatorDescription?.find(
                                        (op) =>
                                          op?.operator == props?.data?.value
                                      )?.description
                                    }
                                  </Tooltip>
                                }
                              >
                                <div>
                                  <components.Option {...props} />
                                </div>
                              </OverlayTrigger>
                            ) : (
                              <components.Option {...props} />
                            ),
                        }}
                        isClearable
                      />
                    </div>
                    <ErrorMessage
                      component={() => (
                        <div style={errorStyling}>{errors?.operator}</div>
                      )}
                    />
                  </div>

                  {values?.operator?.includes("FUZZY") && (
                    <div className="col-2">
                      <div className="workflowModalTitle">Matching %</div>
                      <Field
                        title={
                          values?.operator?.match(/\(([^)]+)\)/)?.length > 0
                            ? values?.operator?.match(/\(([^)]+)\)/)[1]
                            : ""
                        }
                        className="form-control rs-input highlight"
                        type="number"
                        placeholder="Enter Matching %"
                        min="0"
                        max="100"
                        value={
                          values?.operator?.match(/\(([^)]+)\)/)?.length > 0
                            ? values?.operator?.match(/\(([^)]+)\)/)[1]
                            : ""
                        }
                        onChange={(e) => {
                          const newPercentage = e.target.value;
                          const updatedOperator = values.operator.replace(
                            /\(.*?\)/,
                            `(${newPercentage})`
                          );
                          setFieldValue(`operator`, updatedOperator);
                        }}
                      />
                    </div>
                  )}

                  {values?.operator?.split("(")?.[0] === "REGEX-MATCH" && (
                    <div className="col-2">
                      <div className="workflowModalTitle">Regex</div>
                      <Field
                        title={values?.regex}
                        className="form-control rs-input highlight"
                        placeholder="Regex"
                        name={`regex`}
                        onChange={(e) => {
                          setFieldValue(`regex`, e.target.value);
                        }}
                        value={values?.regex}
                      />
                      <ErrorMessage
                        component={() => (
                          <div style={errorStyling}>{errors?.regex}</div>
                        )}
                      />
                    </div>
                  )}

                  {values?.operator?.split("(")[0] === "IN" && (
                    <div
                      className="col-2 d-flex"
                      style={{ paddingTop: "2rem" }}
                    >
                      <span className="mr-2 cursor-context-menu">{"A->B"}</span>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch1"
                          checked={
                            values?.operator
                              ?.match(/IN\(([^,]+),([^,]+)\)/)?.[1]
                              ?.trim() === "ds_a_field_name" &&
                            values?.operator
                              ?.match(/IN\(([^,]+),([^,]+)\)/)?.[2]
                              ?.trim() === "ds_b_field_name"
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue(
                                `operator`,
                                "IN(ds_b_field_name,ds_a_field_name)"
                              );
                            } else {
                              setFieldValue(
                                `operator`,
                                "IN(ds_a_field_name,ds_b_field_name)"
                              );
                            }
                          }}
                        />
                        <label
                          className="custom-control-label"
                          for="customSwitch1"
                        ></label>
                      </div>
                      <span class="ml-2 cursor-context-menu">{"B->A"}</span>
                    </div>
                  )}
                  {values?.operator?.split("(")[0] === "NIN" && (
                    <div
                      className="col-2 d-flex"
                      style={{ paddingTop: "2rem" }}
                    >
                      <span className="mr-2 cursor-context-menu">{"A->B"}</span>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch1"
                          checked={
                            values?.operator
                              ?.match(/NIN\(([^,]+),([^,]+)\)/)?.[1]
                              ?.trim() === "ds_a_field_name" &&
                            values?.operator
                              ?.match(/NIN\(([^,]+),([^,]+)\)/)?.[2]
                              ?.trim() === "ds_b_field_name"
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue(
                                `operator`,
                                "NIN(ds_b_field_name,ds_a_field_name)"
                              );
                            } else {
                              setFieldValue(
                                `operator`,
                                "NIN(ds_a_field_name,ds_b_field_name)"
                              );
                            }
                          }}
                        />
                        <label
                          className="custom-control-label"
                          for="customSwitch1"
                        ></label>
                      </div>
                      <span class="ml-2 cursor-context-menu">{"B->A"}</span>
                    </div>
                  )}

                  <div className="col-3">
                    {/* DropDown */}
                    <div className="workflowModalTitle">DSB Field Name</div>
                    <div title={values?.ds_b_field_name}>
                      <Select
                        options={dsbFields}
                        name={"ds B Field Name"}
                        value={dsbFields?.find(
                          (field) => field.value === values?.ds_b_field_name
                        )}
                        onChange={(e) =>
                          setFieldValue(`ds_b_field_name`, e?.value)
                        }
                        isClearable
                      />
                    </div>
                    <ErrorMessage
                      component={() => (
                        <div style={errorStyling}>
                          {errors?.ds_b_field_name}
                        </div>
                      )}
                    />
                  </div>
                </div>
                {/* Advanced Options for Matching Rule starts here*/}

                <div className="row mt-3">
                  <div className="col-3">
                    <div className="workflowModalTitle">Label</div>
                    <Field
                      title={values?.label}
                      className="form-control rs-input highlight"
                      type="text"
                      placeholder="Label"
                      name={`label`}
                      onChange={(e) => setFieldValue(`label`, e.target.value)}
                    />
                  </div>
                </div>
                {/* DSA - FIELD FUNCTIONS STARTS */}
                <div className="row mt-3">
                  <div className="col-3">
                    <div className="workflowModalTitle">DSA Field Function</div>
                    <div className="form-check py-2">
                      <FormCheck
                        type="switch"
                        name="dsaFieldFunction"
                        style={{
                          zIndex: 0,
                        }}
                        checked={values?.hasOwnProperty("ds_a_field_func")}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValues({
                              ...values,
                              ds_a_field_func: "",
                            });
                          } else {
                            //remove  ds_b_field_value from object
                            const { ds_a_field_func, ...updatedField } = values;
                            // Update the state with the new object
                            setValues(updatedField);
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* FOR DSA - VAR function */}
                  {values?.hasOwnProperty("ds_a_field_func") && (
                    <>
                      <div className="col-3">
                        <div className="workflowModalTitle">Function</div>
                        <div
                          title={
                            operatorOptions?.matchingRuleFunctions?.find(
                              (option) =>
                                option.value ===
                                values?.ds_a_field_func?.split("(")[0]
                            )?.label
                          }
                        >
                          <Select
                            options={operatorOptions?.matchingRuleFunctions}
                            name={"ds A Field Function"}
                            value={
                              operatorOptions?.matchingRuleFunctions?.find(
                                (option) =>
                                  option.value ===
                                  values?.ds_a_field_func?.split("(")[0]
                              ) || {}
                            }
                            onChange={(e) => {
                              const newValue = e?.value;
                              if (newValue === "VAR") {
                                setFieldValue(
                                  `ds_a_field_func`,
                                  `${newValue}(-0:+0)`
                                );
                              } else if (
                                newValue === "LEFT" ||
                                newValue === "RIGHT"
                              ) {
                                setFieldValue(
                                  `ds_a_field_func`,
                                  `${newValue}(0)`
                                );
                              } else if (newValue === "SUBSTR") {
                                setFieldValue(
                                  `ds_a_field_func`,
                                  `${newValue}(0:0)`
                                );
                              } else if (newValue === "REMOVE-SPECIAL-CHAR") {
                                setFieldValue(
                                  `ds_a_field_func`,
                                  `${newValue}()`
                                );
                              }
                            }}
                            isClearable
                            components={{
                              ClearIndicator: () => (
                                <i
                                  style={{
                                    color: "#d9d9d9",
                                    padding: "8px",
                                  }}
                                  className="fa fa-times"
                                  onClick={() =>
                                    setFieldValue(`ds_a_field_func`, "")
                                  }
                                ></i>
                              ),
                              Option: (props) =>
                                OperatorDescription?.find(
                                  (op) => op?.operator == props?.data?.value
                                )?.description ? (
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        {
                                          OperatorDescription?.find(
                                            (op) =>
                                              op?.operator == props?.data?.value
                                          )?.description
                                        }
                                      </Tooltip>
                                    }
                                  >
                                    <div>
                                      <components.Option {...props} />
                                    </div>
                                  </OverlayTrigger>
                                ) : (
                                  <components.Option {...props} />
                                ),
                            }}
                          />
                        </div>
                      </div>
                      {values?.ds_a_field_func?.includes("VAR") && (
                        <>
                          <div className="col-2">
                            <div className="workflowModalTitle">Minimum</div>
                            <div className="input-icon-wrapper">
                              <span className="input-icon">-</span>
                              <Field
                                title={
                                  values?.ds_a_field_func &&
                                  (/-([\d.]+)%/.test(values.ds_a_field_func)
                                    ? values.ds_a_field_func?.match(
                                        /-([\d.]+)%/
                                      )[1]
                                    : /(?<=-)[\d.]+/.test(
                                        values.ds_a_field_func
                                      )
                                    ? values.ds_a_field_func?.match(
                                        /(?<=-)[\d.]+/
                                      )[0]
                                    : "-")
                                }
                                className="form-control rs-input highlight with-icon"
                                type="number"
                                placeholder="Minimum"
                                name="Minimum"
                                value={
                                  values?.ds_a_field_func &&
                                  (/-([\d.]+)%/.test(values.ds_a_field_func)
                                    ? values.ds_a_field_func?.match(
                                        /-([\d.]+)%/
                                      )[1]
                                    : /(?<=-)[\d.]+/.test(
                                        values.ds_a_field_func
                                      )
                                    ? values.ds_a_field_func?.match(
                                        /(?<=-)[\d.]+/
                                      )[0]
                                    : "-")
                                }
                                onChange={(e) => {
                                  const minusValue = parseFloat(e.target.value);
                                  if (e.target.value === "") {
                                    setFieldValue(
                                      `ds_a_field_func`,
                                      values.ds_a_field_func.replace(
                                        /(?<=-)[\d.]+/,
                                        `0`
                                      )
                                    );
                                    return;
                                  }
                                  if (isNaN(minusValue) || minusValue < 0) {
                                    return;
                                  }
                                  if (
                                    /%\s*\)/.test(values.ds_a_field_func) &&
                                    minusValue <= 100
                                  ) {
                                    const updatedOperator =
                                      values.ds_a_field_func.replace(
                                        /(?<=-)[\d.]+/,
                                        `${minusValue}`
                                      );
                                    setFieldValue(
                                      `ds_a_field_func`,
                                      updatedOperator
                                    );
                                  } else if (
                                    !/%\s*\)/.test(values.ds_a_field_func)
                                  ) {
                                    const updatedOperator =
                                      values.ds_a_field_func.replace(
                                        /(?<=-)[\d.]+/,
                                        `${minusValue}`
                                      );
                                    setFieldValue(
                                      `ds_a_field_func`,
                                      updatedOperator
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-2">
                            <div className="workflowModalTitle">Maximum</div>
                            <div className="input-icon-wrapper">
                              <span className="input-icon">+</span>
                              <Field
                                title={
                                  values?.ds_a_field_func &&
                                  (/\+([\d.]+)%/.test(values.ds_a_field_func)
                                    ? values.ds_a_field_func?.match(
                                        /\+([\d.]+)%/
                                      )[1]
                                    : /(?<=\+)[\d.]+/.test(
                                        values.ds_a_field_func
                                      )
                                    ? values.ds_a_field_func?.match(
                                        /(?<=\+)[\d.]+/
                                      )[0]
                                    : "+")
                                }
                                className="form-control rs-input highlight with-icon"
                                type="number"
                                placeholder="Maximum"
                                name="Maximum"
                                value={
                                  values?.ds_a_field_func &&
                                  (/\+([\d.]+)%/.test(values.ds_a_field_func)
                                    ? values.ds_a_field_func?.match(
                                        /\+([\d.]+)%/
                                      )[1]
                                    : /(?<=\+)[\d.]+/.test(
                                        values.ds_a_field_func
                                      )
                                    ? values.ds_a_field_func?.match(
                                        /(?<=\+)[\d.]+/
                                      )[0]
                                    : "+")
                                }
                                onChange={(e) => {
                                  const plusValue = parseFloat(e.target.value);
                                  if (e.target.value === "") {
                                    setFieldValue(
                                      `ds_a_field_func`,
                                      values.ds_a_field_func.replace(
                                        /(?<=\+)[\d.]+/,
                                        `0`
                                      )
                                    );
                                    return;
                                  }
                                  if (isNaN(plusValue) || plusValue < 0) {
                                    return;
                                  }
                                  if (
                                    /%\s*\)/.test(values.ds_a_field_func) &&
                                    plusValue <= 100
                                  ) {
                                    const updatedOperator =
                                      values.ds_a_field_func.replace(
                                        /(?<=\+)[\d.]+/,
                                        `${plusValue}`
                                      );
                                    setFieldValue(
                                      `ds_a_field_func`,
                                      updatedOperator
                                    );
                                  } else if (
                                    !/%\s*\)/.test(values.ds_a_field_func)
                                  ) {
                                    const updatedOperator =
                                      values.ds_a_field_func.replace(
                                        /(?<=\+)[\d.]+/,
                                        `${plusValue}`
                                      );
                                    setFieldValue(
                                      `ds_a_field_func`,
                                      updatedOperator
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {values?.ds_a_field_func?.includes("VAR") && (
                        <div className="col-2">
                          <div className="workflowModalTitle">Variance %</div>
                          <div className="form-check py-2">
                            <FormCheck
                              type="switch"
                              checked={/%\s*\)/.test(values.ds_a_field_func)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue(
                                    `ds_a_field_func`,
                                    `VAR(-0%:+0%)`
                                  );
                                } else {
                                  setFieldValue(
                                    `ds_a_field_func`,
                                    `VAR(-0:+0)`
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {/* FOR DSA - LEFT function */}
                  {values?.ds_a_field_func?.includes("LEFT") && (
                    <>
                      <div className="col-2">
                        <div className="workflowModalTitle">
                          No. of Characters
                        </div>
                        <Field
                          title={
                            values?.ds_a_field_func &&
                            /(?<=\().*?(?=\))/.test(values.ds_a_field_func)
                              ? values.ds_a_field_func?.match(
                                  /(?<=\().*?(?=\))/
                                )[0]
                              : ""
                          }
                          className="form-control rs-input highlight"
                          type="number"
                          placeholder="No. of Characters"
                          name="NumOfLeftCharacters"
                          value={
                            values?.ds_a_field_func &&
                            /(?<=\().*?(?=\))/.test(values.ds_a_field_func)
                              ? values.ds_a_field_func?.match(
                                  /(?<=\().*?(?=\))/
                                )[0]
                              : ""
                          }
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              return;
                            }
                            const updatedOperator =
                              values.ds_a_field_func.replace(
                                /(?<=\().*?(?=\))/,
                                `${e.target.value}`
                              );
                            setFieldValue(`ds_a_field_func`, updatedOperator);
                          }}
                        />
                      </div>
                    </>
                  )}

                  {/* FOR DSA `RIGHT` function */}
                  {values?.ds_a_field_func?.includes("RIGHT") && (
                    <>
                      <div className="col-2">
                        <div className="workflowModalTitle">
                          No. of Characters
                        </div>
                        <Field
                          title={
                            values?.ds_a_field_func &&
                            /(?<=\().*?(?=\))/.test(values.ds_a_field_func)
                              ? values.ds_a_field_func?.match(
                                  /(?<=\().*?(?=\))/
                                )[0]
                              : ""
                          }
                          className="form-control rs-input highlight"
                          type="number"
                          placeholder="No. of Characters"
                          name="NumOfRightCharacters"
                          value={
                            values?.ds_a_field_func &&
                            /(?<=\().*?(?=\))/.test(values.ds_a_field_func)
                              ? values.ds_a_field_func?.match(
                                  /(?<=\().*?(?=\))/
                                )[0]
                              : ""
                          }
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              return;
                            }
                            const updatedOperator =
                              values.ds_a_field_func.replace(
                                /(?<=\().*?(?=\))/,
                                `${e.target.value}`
                              );
                            setFieldValue(`ds_a_field_func`, updatedOperator);
                          }}
                        />
                      </div>
                    </>
                  )}

                  {/* FOR DSA `SUBSTR` FUNCTION */}
                  {values?.ds_a_field_func?.includes("SUBSTR") && (
                    <>
                      <div className="col-2">
                        <div className="workflowModalTitle">
                          Start Character
                        </div>
                        <Field
                          title={
                            values?.ds_a_field_func &&
                            /(?<=\().*?(?=:)/.test(values.ds_a_field_func)
                              ? values.ds_a_field_func?.match(
                                  /(?<=\().*?(?=:)/
                                )[0]
                              : ""
                          }
                          className="form-control rs-input highlight"
                          type="number"
                          placeholder="Start Character"
                          name="startCharacter"
                          value={
                            values?.ds_a_field_func &&
                            /(?<=\().*?(?=:)/.test(values.ds_a_field_func)
                              ? values.ds_a_field_func?.match(
                                  /(?<=\().*?(?=:)/
                                )[0]
                              : ""
                          }
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              return;
                            }
                            const updatedOperator =
                              values.ds_a_field_func.replace(
                                /(?<=\().*?(?=:)/,
                                `${e.target.value}`
                              );
                            setFieldValue(`ds_a_field_func`, updatedOperator);
                          }}
                        />
                      </div>

                      <div className="col-2">
                        <div className="workflowModalTitle">End Character</div>
                        <Field
                          title={
                            values?.ds_a_field_func &&
                            /(?<=:).*?(?=\))/.test(values.ds_a_field_func)
                              ? values.ds_a_field_func?.match(
                                  /(?<=:).*?(?=\))/
                                )[0]
                              : ""
                          }
                          className="form-control rs-input highlight"
                          type="number"
                          placeholder="End Character"
                          name="endCharacter"
                          value={
                            values?.ds_a_field_func &&
                            /(?<=:).*?(?=\))/.test(values.ds_a_field_func)
                              ? values.ds_a_field_func?.match(
                                  /(?<=:).*?(?=\))/
                                )[0]
                              : ""
                          }
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              return;
                            }
                            const updatedOperator =
                              values.ds_a_field_func.replace(
                                /(?<=:).*?(?=\))/,
                                `${e.target.value}`
                              );
                            setFieldValue(`ds_a_field_func`, updatedOperator);
                          }}
                        />
                      </div>
                    </>
                  )}

                  {/* FOR DSA `REMOVE-SPECIAL-CHAR` FUNCTION */}
                  {values?.ds_a_field_func?.includes("REMOVE-SPECIAL-CHAR") && (
                    <>
                      <div className="col-3">
                        <div className="workflowModalTitle">Regex</div>
                        <Field
                          title={
                            values?.ds_a_field_func &&
                            values.ds_a_field_func.startsWith(
                              "REMOVE-SPECIAL-CHAR("
                            ) &&
                            values.ds_a_field_func.endsWith(")")
                              ? values.ds_a_field_func.slice(20, -1)
                              : ""
                          }
                          className="form-control rs-input highlight"
                          type="text"
                          placeholder="Regex"
                          value={
                            values?.ds_a_field_func &&
                            values.ds_a_field_func.startsWith(
                              "REMOVE-SPECIAL-CHAR("
                            ) &&
                            values.ds_a_field_func.endsWith(")")
                              ? values.ds_a_field_func.slice(20, -1)
                              : ""
                          }
                          onChange={(e) => {
                            setFieldValue(
                              `ds_a_field_func`,
                              e.target.value
                                ? `REMOVE-SPECIAL-CHAR(${e.target.value})`
                                : "REMOVE-SPECIAL-CHAR()"
                            );
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                {/* DSA - FIELD FUNCTIONS ENDS  */}

                {/* DSB - FIELD FUNCTIONS STARTS */}
                <div className="row mt-3">
                  <div className="col-3">
                    <div className="workflowModalTitle">DSB Field Function</div>
                    <div className="form-check py-2">
                      <FormCheck
                        type="switch"
                        name="dsbFieldFunction"
                        style={{
                          zIndex: 0,
                        }}
                        checked={values?.hasOwnProperty("ds_b_field_func")}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValues({
                              ...values,
                              ds_b_field_func: "",
                            });
                          } else {
                            //remove  ds_b_field_func from object
                            const { ds_b_field_func, ...updatedField } = values;
                            // Update the state with the new object
                            setValues(updatedField);
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* FOR DSB - VAR function */}
                  {values?.hasOwnProperty("ds_b_field_func") && (
                    <>
                      <div className="col-3">
                        <div className="workflowModalTitle">Function</div>
                        <div
                          title={
                            operatorOptions?.matchingRuleFunctions?.find(
                              (option) =>
                                option.value ===
                                values?.ds_b_field_func?.split("(")[0]
                            )?.label
                          }
                        >
                          <Select
                            options={operatorOptions?.matchingRuleFunctions}
                            name={"ds B Field Function"}
                            value={
                              operatorOptions?.matchingRuleFunctions?.find(
                                (option) =>
                                  option.value ===
                                  values?.ds_b_field_func?.split("(")[0]
                              ) || {}
                            }
                            onChange={(e) => {
                              const newValue = e?.value;
                              if (newValue === "VAR") {
                                setFieldValue(
                                  `ds_b_field_func`,
                                  `${newValue}(-0:+0)`
                                );
                              } else if (
                                newValue === "LEFT" ||
                                newValue === "RIGHT"
                              ) {
                                setFieldValue(
                                  `ds_b_field_func`,
                                  `${newValue}(0)`
                                );
                              } else if (newValue === "SUBSTR") {
                                setFieldValue(
                                  `ds_b_field_func`,
                                  `${newValue}(0:0)`
                                );
                              } else if (newValue === "REMOVE-SPECIAL-CHAR") {
                                setFieldValue(
                                  `ds_b_field_func`,
                                  `${newValue}()`
                                );
                              }
                            }}
                            isClearable
                            components={{
                              ClearIndicator: () => (
                                <i
                                  style={{
                                    color: "#d9d9d9",
                                    padding: "8px",
                                  }}
                                  className="fa fa-times"
                                  onClick={() =>
                                    setFieldValue(`ds_b_field_func`, "")
                                  }
                                ></i>
                              ),
                              Option: (props) =>
                                OperatorDescription?.find(
                                  (op) => op?.operator == props?.data?.value
                                )?.description ? (
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        {
                                          OperatorDescription?.find(
                                            (op) =>
                                              op?.operator == props?.data?.value
                                          )?.description
                                        }
                                      </Tooltip>
                                    }
                                  >
                                    <div>
                                      <components.Option {...props} />
                                    </div>
                                  </OverlayTrigger>
                                ) : (
                                  <components.Option {...props} />
                                ),
                            }}
                          />
                        </div>
                      </div>
                      {values?.ds_b_field_func?.includes("VAR") && (
                        <>
                          <div className="col-2">
                            <div className="workflowModalTitle">Minimum</div>
                            <div className="input-icon-wrapper">
                              <span className="input-icon">-</span>
                              <Field
                                title={
                                  values?.ds_b_field_func &&
                                  (/-([\d.]+)%/.test(values.ds_b_field_func)
                                    ? values.ds_b_field_func?.match(
                                        /-([\d.]+)%/
                                      )[1]
                                    : /(?<=-)[\d.]+/.test(
                                        values.ds_b_field_func
                                      )
                                    ? values.ds_b_field_func?.match(
                                        /(?<=-)[\d.]+/
                                      )[0]
                                    : "-")
                                }
                                className="form-control rs-input highlight with-icon"
                                type="number"
                                placeholder="Minimum"
                                name="Minimum"
                                value={
                                  values?.ds_b_field_func &&
                                  (/-([\d.]+)%/.test(values.ds_b_field_func)
                                    ? values.ds_b_field_func?.match(
                                        /-([\d.]+)%/
                                      )[1]
                                    : /(?<=-)[\d.]+/.test(
                                        values.ds_b_field_func
                                      )
                                    ? values.ds_b_field_func?.match(
                                        /(?<=-)[\d.]+/
                                      )[0]
                                    : "-")
                                }
                                onChange={(e) => {
                                  const minusValue = parseFloat(e.target.value);
                                  if (e.target.value === "") {
                                    setFieldValue(
                                      `ds_b_field_func`,
                                      values.ds_b_field_func.replace(
                                        /(?<=-)[\d.]+/,
                                        `0`
                                      )
                                    );
                                    return;
                                  }
                                  if (isNaN(minusValue) || minusValue < 0) {
                                    return;
                                  }
                                  if (
                                    /%\s*\)/.test(values.ds_b_field_func) &&
                                    minusValue <= 100
                                  ) {
                                    const updatedOperator =
                                      values.ds_b_field_func.replace(
                                        /(?<=-)[\d.]+/,
                                        `${minusValue}`
                                      );
                                    setFieldValue(
                                      `ds_b_field_func`,
                                      updatedOperator
                                    );
                                  } else if (
                                    !/%\s*\)/.test(values.ds_b_field_func)
                                  ) {
                                    const updatedOperator =
                                      values.ds_b_field_func.replace(
                                        /(?<=-)[\d.]+/,
                                        `${minusValue}`
                                      );
                                    setFieldValue(
                                      `ds_b_field_func`,
                                      updatedOperator
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-2">
                            <div className="workflowModalTitle">Maximum</div>
                            <div className="input-icon-wrapper">
                              <span className="input-icon">+</span>
                              <Field
                                title={
                                  values?.ds_b_field_func &&
                                  (/\+([\d.]+)%/.test(values.ds_b_field_func)
                                    ? values.ds_b_field_func?.match(
                                        /\+([\d.]+)%/
                                      )[1]
                                    : /(?<=\+)[\d.]+/.test(
                                        values.ds_b_field_func
                                      )
                                    ? values.ds_b_field_func?.match(
                                        /(?<=\+)[\d.]+/
                                      )[0]
                                    : "+")
                                }
                                className="form-control rs-input highlight with-icon"
                                type="number"
                                placeholder="Maximum"
                                name="Maximum"
                                value={
                                  values?.ds_b_field_func &&
                                  (/\+([\d.]+)%/.test(values.ds_b_field_func)
                                    ? values.ds_b_field_func?.match(
                                        /\+([\d.]+)%/
                                      )[1]
                                    : /(?<=\+)[\d.]+/.test(
                                        values.ds_b_field_func
                                      )
                                    ? values.ds_b_field_func?.match(
                                        /(?<=\+)[\d.]+/
                                      )[0]
                                    : "+")
                                }
                                onChange={(e) => {
                                  const plusValue = parseFloat(e.target.value);
                                  if (e.target.value === "") {
                                    setFieldValue(
                                      `ds_b_field_func`,
                                      values.ds_b_field_func.replace(
                                        /(?<=\+)[\d.]+/,
                                        `0`
                                      )
                                    );
                                    return;
                                  }
                                  if (isNaN(plusValue) || plusValue < 0) {
                                    return;
                                  }
                                  if (
                                    /%\s*\)/.test(values.ds_b_field_func) &&
                                    plusValue <= 100
                                  ) {
                                    const updatedOperator =
                                      values.ds_b_field_func.replace(
                                        /(?<=\+)[\d.]+/,
                                        `${plusValue}`
                                      );
                                    setFieldValue(
                                      `ds_b_field_func`,
                                      updatedOperator
                                    );
                                  } else if (
                                    !/%\s*\)/.test(values.ds_b_field_func)
                                  ) {
                                    const updatedOperator =
                                      values.ds_b_field_func.replace(
                                        /(?<=\+)[\d.]+/,
                                        `${plusValue}`
                                      );
                                    setFieldValue(
                                      `ds_b_field_func`,
                                      updatedOperator
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {values?.ds_b_field_func?.includes("VAR") && (
                        <div className="col-2">
                          <div className="workflowModalTitle">Variance %</div>
                          <div className="form-check py-2">
                            <FormCheck
                              type="switch"
                              checked={/%\s*\)/.test(values.ds_b_field_func)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue(
                                    `ds_b_field_func`,
                                    `VAR(-0%:+0%)`
                                  );
                                } else {
                                  setFieldValue(
                                    `ds_b_field_func`,
                                    `VAR(-0:+0)`
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {/* FOR DSA - LEFT function */}
                  {values?.ds_b_field_func?.includes("LEFT") && (
                    <>
                      <div className="col-2">
                        <div className="workflowModalTitle">
                          No. of Characters
                        </div>
                        <Field
                          title={
                            values?.ds_b_field_func &&
                            /(?<=\().*?(?=\))/.test(values.ds_b_field_func)
                              ? values.ds_b_field_func?.match(
                                  /(?<=\().*?(?=\))/
                                )[0]
                              : ""
                          }
                          className="form-control rs-input highlight"
                          type="number"
                          placeholder="No. of Characters"
                          name="NumOfLeftCharacters"
                          value={
                            values?.ds_b_field_func &&
                            /(?<=\().*?(?=\))/.test(values.ds_b_field_func)
                              ? values.ds_b_field_func?.match(
                                  /(?<=\().*?(?=\))/
                                )[0]
                              : ""
                          }
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              return;
                            }
                            const updatedOperator =
                              values.ds_b_field_func.replace(
                                /(?<=\().*?(?=\))/,
                                `${e.target.value}`
                              );
                            setFieldValue(`ds_b_field_func`, updatedOperator);
                          }}
                        />
                      </div>
                    </>
                  )}

                  {/* FOR DSA `RIGHT` function */}
                  {values?.ds_b_field_func?.includes("RIGHT") && (
                    <>
                      <div className="col-2">
                        <div className="workflowModalTitle">
                          No. of Characters
                        </div>
                        <Field
                          title={
                            values?.ds_b_field_func &&
                            /(?<=\().*?(?=\))/.test(values.ds_b_field_func)
                              ? values.ds_b_field_func?.match(
                                  /(?<=\().*?(?=\))/
                                )[0]
                              : ""
                          }
                          className="form-control rs-input highlight"
                          type="number"
                          placeholder="No. of Characters"
                          name="NumOfRightCharacters"
                          value={
                            values?.ds_b_field_func &&
                            /(?<=\().*?(?=\))/.test(values.ds_b_field_func)
                              ? values.ds_b_field_func?.match(
                                  /(?<=\().*?(?=\))/
                                )[0]
                              : ""
                          }
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              return;
                            }
                            const updatedOperator =
                              values.ds_b_field_func.replace(
                                /(?<=\().*?(?=\))/,
                                `${e.target.value}`
                              );
                            setFieldValue(`ds_b_field_func`, updatedOperator);
                          }}
                        />
                      </div>
                    </>
                  )}

                  {/* FOR DSA `SUBSTR` FUNCTION */}
                  {values?.ds_b_field_func?.includes("SUBSTR") && (
                    <>
                      <div className="col-2">
                        <div className="workflowModalTitle">
                          Start Character
                        </div>
                        <Field
                          title={
                            values?.ds_b_field_func &&
                            /(?<=\().*?(?=:)/.test(values.ds_b_field_func)
                              ? values.ds_b_field_func?.match(
                                  /(?<=\().*?(?=:)/
                                )[0]
                              : ""
                          }
                          className="form-control rs-input highlight"
                          type="number"
                          placeholder="Start Character"
                          name="startCharacter"
                          value={
                            values?.ds_b_field_func &&
                            /(?<=\().*?(?=:)/?.test(values.ds_b_field_func)
                              ? values.ds_b_field_func?.match(
                                  /(?<=\().*?(?=:)/
                                )[0]
                              : ""
                          }
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              return;
                            }
                            const updatedOperator =
                              values.ds_b_field_func.replace(
                                /(?<=\().*?(?=:)/,
                                `${e.target.value}`
                              );
                            setFieldValue(`ds_b_field_func`, updatedOperator);
                          }}
                        />
                      </div>

                      <div className="col-2">
                        <div className="workflowModalTitle">End Character</div>
                        <Field
                          title={
                            values?.ds_b_field_func &&
                            /(?<=:).*?(?=\))/.test(values.ds_b_field_func)
                              ? values.ds_b_field_func?.match(
                                  /(?<=:).*?(?=\))/
                                )[0]
                              : ""
                          }
                          className="form-control rs-input highlight"
                          type="number"
                          placeholder="End Character"
                          name="endCharacter"
                          value={
                            values?.ds_b_field_func &&
                            /(?<=:).*?(?=\))/.test(values.ds_b_field_func)
                              ? values.ds_b_field_func?.match(
                                  /(?<=:).*?(?=\))/
                                )[0]
                              : ""
                          }
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              return;
                            }
                            const updatedOperator =
                              values.ds_b_field_func.replace(
                                /(?<=:).*?(?=\))/,
                                `${e.target.value}`
                              );
                            setFieldValue(`ds_b_field_func`, updatedOperator);
                          }}
                        />
                      </div>
                    </>
                  )}

                  {/* FOR DSB `REMOVE-SPECIAL-CHAR` FUNCTION */}
                  {values?.ds_b_field_func?.includes("REMOVE-SPECIAL-CHAR") && (
                    <>
                      <div className="col-3">
                        <div className="workflowModalTitle">Regex</div>
                        <Field
                          title={
                            values?.ds_b_field_func &&
                            values?.ds_b_field_func?.startsWith(
                              "REMOVE-SPECIAL-CHAR("
                            ) &&
                            values?.ds_b_field_func?.endsWith(")")
                              ? values?.ds_b_field_func?.slice(20, -1)
                              : ""
                          }
                          className="form-control rs-input highlight"
                          type="text"
                          placeholder="Regex"
                          value={
                            values?.ds_b_field_func &&
                            values?.ds_b_field_func?.startsWith(
                              "REMOVE-SPECIAL-CHAR("
                            ) &&
                            values?.ds_b_field_func?.endsWith(")")
                              ? values?.ds_b_field_func?.slice(20, -1)
                              : ""
                          }
                          onChange={(e) => {
                            setFieldValue(
                              `ds_b_field_func`,
                              e.target.value
                                ? `REMOVE-SPECIAL-CHAR(${e.target.value})`
                                : "REMOVE-SPECIAL-CHAR()"
                            );
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                {/* DSB FIELD FUNCTIONS ENDS  */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <ButtonBasic
                  id="cancel"
                  title="Cancel"
                  onClick={() => onHide()}
                />
              </div>
              <button
                id="submit"
                type="submit"
                name="submit"
                className={"btn btn-info btn-md mr-2"}
                onClick={handleSubmit}
              >
                {editObject?.isNew ? "Add" : "Update"}
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default MatchingRuleModal;
