import React, { useState, useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import axiosInstance from "../../utils/axiosInstance";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import { TransactionLogStyle } from "./TransactionsStyle";
import { useDateTime } from "../../components/Helper/DateTime";
import Toast from "../../components/Toast/Toast";
import { CustomTooltip } from "../../utils/helpers/custom-tooltip";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import LoaderComponent from "../../components/Spinner/LoaderComponent";

const SuspiciousSchema = Yup.object().shape({
  comment: Yup.string().min(2, "Too Short!").required("Required*"),
});

const SuspiciousModal = ({
  selectedAllRows,
  setShowSuspiciousModal,
  showSuspiciousModal,
  balancingSummaryId,
  deviceId,
  ReconStatus,
  fetchTransactionData,
  filter,
  batchId,
  reconAccountId,
}) => {
  const { date, dateTime, currency, setRefresh } = useDateTime();
  const [isLoading, setIsLoading] = useState(false);
  const [ATMBalanceNotes, setATMBalanceNotes] = useState({});
  const [newNotes, setNewNotes] = useState([]);
  const username =
    JSON.parse(localStorage.getItem("userData"))?.firstName +
    " " +
    JSON.parse(localStorage.getItem("userData"))?.lastName;
  const [adjustmentEntries, setAdjustmentEntries] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDebitAccount, setSelectedDebitAccount] = useState("");
  const [selectedCreditAccount, setSelectedCreditAccount] = useState("");
  const [categories, setCategories] = useState([]);
  const [debitAccounts, setDebitAccounts] = useState([]);
  const [creditAccounts, setCreditAccounts] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const adjustmentEntriesColumns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "transactionDetails",
      text: "Transaction Details",
      formatter: (cell, row, rowIndex) => (
        <div>
          <BootstrapTable
            bordered={false}
            keyField="id"
            wrapperClasses="overflow-y-hidden overflow-x-hidden"
            classes="rs-table table-layout-auto mb-0 table-custom-style"
            columns={[
              {
                dataField: "glAccount",
                text: "GL Account",
                style: { minWidth: "100px" },
                formatter: (cell) => (cell ? cell : "-"),
              },
              {
                dataField: "postingDate",
                text: "Posting Date",
                formatter: (cell) => (cell ? cell : "-"),
              },
              {
                dataField: "description",
                text: "Description",
                style: {
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  minWidth: "342px",
                  lineHeight: "1.5",
                },
                formatter: (cell) => (cell ? cell : "-"),
              },
              {
                dataField: "creditAmount",
                text: "Credit Amount",
                formatter: (cell, row) => formatAmount(cell, row),
              },
              {
                dataField: "debitAmount",
                text: "Debit Amount",
                formatter: (cell, row) => formatAmount(cell, row),
              },
              {
                dataField: "entryReference",
                text: "Entry Reference",
                formatter: (cell) => (cell ? cell : "-"),
              },
            ]}
            data={cell || []}
            headerClasses={rowIndex === 0 ? null : "visibility-collapse"}
          />
          {adjustmentEntries?.length - 1 !== rowIndex && <hr />}
        </div>
      ),
    },
  ];

  const getAdjustmentCategory = async () => {
    await axiosInstance
      .get("/common/code?codeTypeId=11")
      .then((response) => {
        setCategories(
          response.data?.map((item) => ({
            value: item.codeValue,
            label: item.name,
          })) || []
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShowAdjustment = async () => {
    try {
      let requestPayload = {
        data: {
          matchingData: selectedAllRows?.map((item) => {
            return {
              id: item?.id,
              atmBalancingFlowType: "flow2",
            };
          }),
          creditAccount: selectedCreditAccount,
          debitAccount: selectedDebitAccount,
          category: selectedCategory,
        },
      };
      let response = await axiosInstance.post(
        "/matching/adjustment-entries",
        requestPayload
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        setAdjustmentEntries(response?.data);
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
    }
  };

  useEffect(() => {
    getAdjustmentCategory();
  }, []);
  useEffect(() => {
    if (showSuspiciousModal) {
      handleShowAdjustment();
    }
  }, [
    selectedCategory,
    selectedCreditAccount,
    selectedDebitAccount,
    showSuspiciousModal,
  ]);
  useEffect(() => {
    if (showSuspiciousModal) {
      setATMBalanceNotes({
        notes:
          selectedAllRows
            ?.map((obj) => (obj.notes !== null ? obj.notes : []))
            .flat() || [],
      });
    }
    // getAllAccounts();
  }, [showSuspiciousModal]);

  const handleAddNotes = (values) => {
    let now = moment();
    let note = {
      comment: values?.comment,
      author: username,
      date: now.format(date),
      time: now.hour() + ":" + now.minute(),
    };

    let _notes = ATMBalanceNotes?.notes || [];
    _notes.push(note);
    setATMBalanceNotes({ notes: _notes });
    setNewNotes([...newNotes, note]);
  };

  const validateDateTime = (cell) => {
    return cell ? moment(cell, "DD-MM-YYYY HH:mm:ss").format(dateTime) : "-";
  };
  const reconStatus = (row) => (
    <BadgeRounded
      color={
        row === "MATCHED" ? "success" : row === "MISSING" ? "warning" : "danger"
      }
      title={row}
    />
  );

  const formatAmount = (cell, row) => {
    return (
      <>
        <div className="d-flex justify-content-between">
          <span className="item px-3">{currency}</span>
          <span className="item">
            <CurrencyFormat
              value={cell ?? 0}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
            />
          </span>
        </div>
      </>
    );
  };

  const columns = [
    { hidden: true, dataField: "id", text: "ID" },

    {
      dataField: "dateTime",
      text: "Date Time",
      formatter: (cell) => validateDateTime(cell),
    },
    {
      dataField: "transactionType",
      text: "Transaction",
      formatter: (cell) => cell ?? "-",
    },

    {
      dataField: "deviceId",
      text: "Device ID",
    },

    {
      dataField: "amount",
      text: "Amount",
      formatter: formatAmount,
      headerAlign: "right",
    },
    {
      dataField: "stan",
      text: "STAN",
      formatter: (cell) => cell ?? "-",
    },
    {
      dataField: "responseCode",
      text: "Response Code",
      formatter: (cell) => cell ?? "-",
    },
  ];

  const manualReconAccounts = useCallback(async () => {
    setIsLoading(true);
    let status = "";
    if (ReconStatus === "SHORTAGE") {
      status = 100002;
    } else if (ReconStatus === "SURPLUS") {
      status = 100001;
    }
    try {
      const response = await axiosInstance.get(
        // `/accounts/manual-recon-accounts?manual_recon_flow=Suspicious-Transaction&adjustment_category=${status}&account_required=${deviceId}`
        `/accounts/manual-recon-accounts-v2?batch_id=${batchId}&recon_account_ids=${reconAccountId}`
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setIsLoading(false);
      } else {
        if (response?.data) {
          // setApiData(response?.data);
          // updateStateFromresponse(response?.data);
          setIsLoading(false);
          populateOptions(response?.data);
        }
      }
    } catch (err) {
      setIsLoading(false);
      Toast(err.response.data.message.description, "error");
    }
  }, []);
  const populateOptions = (data) => {
    // Transforming data for Select components
    const transformedCategories = data.categories.map((item) => ({
      value: item.id,
      label: item.categoryName,
    }));

    const transformedDebitAccounts = data.debitAccounts.map((item) => ({
      value: item.reconAccountId,
      label: item.name,
    }));

    const transformedCreditAccounts = data.creditAccounts.map((item) => ({
      value: item.reconAccountId,
      label: item.name,
    }));

    // Set state variables
    setCategories(transformedCategories);
    setDebitAccounts(transformedDebitAccounts);
    setCreditAccounts(transformedCreditAccounts);
  };

  const handleDone = async () => {
    setIsLoading(true);
    try {
      let adjustmentCategory = categories.filter((item) => {
        return item.value === selectedCategory;
      });

      const data = {
        notes: newNotes,
        adjustmentCategory: Number(selectedCategory),
        adjustmentCategoryName: adjustmentCategory[0]?.label,
        transactionIds: selectedAllRows?.map((item) => item.id),
        accountDetails: {
          creditAccount: selectedCreditAccount,
          debitAccount: selectedDebitAccount,
        },
        balancingSummaryId: balancingSummaryId,
        customData: JSON.stringify(adjustmentEntries),
      };
      const response = await axiosInstance.post(
        "/transactions/balance-atm-transaction",
        { data: data }
      );
      if (response.message.status !== "200") {
        setIsLoading(false);
        Toast(response.message.description, "error");
      } else {
        setIsLoading(false);
        fetchTransactionData(filter);
        setShowSuspiciousModal(false);
        Toast(response.message.description, "success");
        setNewNotes([]);
        setShowNotes(false);
      }
    } catch (err) {
      setIsLoading(false);
      Toast(
        err.response?.data?.message?.description || "An error occurred",
        "error"
      );
    }
  };

  useEffect(() => {
    if (deviceId && ReconStatus) {
      manualReconAccounts();
    }
  }, [ReconStatus, deviceId, manualReconAccounts]);
  return (
    <>
      {isLoading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <TransactionLogStyle>
        <Modal
          backdrop={true}
          size="xl"
          show={showSuspiciousModal}
          onHide={() => {
            setShowSuspiciousModal(false);
            setNewNotes([]);
            setShowNotes(false);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <div className=" ">Match With Adjustment</div>
          </Modal.Header>
          <Modal.Body scrollable="true">
            <div className="row">
              <div
                className={`${
                  showNotes ? "col-lg-8" : "col-lg-12"
                } col-sm-12 mb-3`}
              >
                <div className="row">
                  <div className="col-4">
                    <label style={{ fontSize: "14px" }}>
                      Adjustment Category*
                    </label>
                    <Select
                      placeholder="Select Category"
                      options={categories}
                      value={categories.find(
                        (item) => item.value === selectedCategory
                      )}
                      onChange={(e) => setSelectedCategory(e.value)}
                      isSearchable
                      className="no-border mt-2"
                    />
                  </div>

                  {/* Debit Account Select */}
                  <div className="col-4">
                    <label style={{ fontSize: "14px" }}>Debit Account*</label>
                    <Select
                      placeholder=" Debit Account"
                      isDisabled={debitAccounts.length > 0 ? false : true}
                      options={debitAccounts}
                      value={debitAccounts.find(
                        (item) => item.value === selectedDebitAccount
                      )}
                      onChange={(e) => setSelectedDebitAccount(e.value)}
                      isSearchable
                      className="no-border mt-2"
                    />
                  </div>

                  {/* Credit Account Select */}
                  <div className="col-4">
                    <label style={{ fontSize: "14px" }}>Credit Account*</label>
                    <Select
                      placeholder="Credit Account"
                      isDisabled={creditAccounts.length > 0 ? false : true}
                      options={creditAccounts}
                      value={creditAccounts.find(
                        (item) => item.value === selectedCreditAccount
                      )}
                      onChange={(e) => setSelectedCreditAccount(e.value)}
                      isSearchable
                      className="no-border mt-2"
                    />
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <BootstrapTable
                      columns={columns}
                      bootstrap4={true}
                      keyField="id"
                      bordered={false}
                      classes="rs-table table-layout-auto table-custom-style table-custom-style"
                      wrapperClasses="overflow-y-auto fancy-scroll"
                      data={selectedAllRows}
                      noDataIndication={"No data found!"}
                    />
                  </div>
                </div>
              </div>
              {showNotes && (
                <div className="col-lg-4 col-sm-12">
                  <div className="notes-wrapper">
                    <h5 className="mb-3 color-gray-dim">Notes</h5>
                    <div className="overflow-auto" style={{ height: "22em" }}>
                      {ATMBalanceNotes?.notes?.map(
                        ({ author, comment, date, time }, i) => (
                          <ul className="rs-notes-container" key={i}>
                            <li className="rs-notes-item">
                              <h6>{author}</h6>
                              <div className="note-comment">{comment}</div>
                              <sub>
                                {date} {time}
                              </sub>
                            </li>
                          </ul>
                        )
                      )}
                    </div>
                    <Formik
                      initialValues={{
                        comment: "",
                      }}
                      validationSchema={SuspiciousSchema}
                      onSubmit={(values, { resetForm }) => {
                        handleAddNotes(values);
                        resetForm({ values: "" });
                      }}
                    >
                      {({ errors, touched, handleSubmit, handleReset }) => (
                        <Form
                          id="add-notes"
                          className="form"
                          onSubmit={handleSubmit}
                          onReset={handleReset}
                        >
                          <div className="row mt-3">
                            <div className="col-12 mt-3">
                              <div className="d-flex flex-column">
                                <Field
                                  as="textarea"
                                  className="rs-input"
                                  placeholder="Write a Note"
                                  rows="3"
                                  name="comment"
                                />
                                {errors.comment && touched.comment ? (
                                  <div className="field-error">
                                    {errors.comment}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="w-100 mt-2">
                            <ButtonBasic
                              type="submit"
                              classes={"primary px-4 w-100"}
                              title={"Add Note"}
                              onClick={handleSubmit}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
            </div>
            {adjustmentEntries?.length > 0 && (
              <div className="row mt-4 w-100">
                <div className="col-12">
                  <div className="mt-4 color-success mb-2">
                    Manual Adjustment Entries
                  </div>
                  <BootstrapTable
                    keyField="id"
                    data={adjustmentEntries}
                    columns={adjustmentEntriesColumns}
                    bordered={false}
                    classes="rs-table table-layout-auto table-custom-style"
                    headerClasses="d-none"
                    noDataIndication="No data found!"
                    wrapperClasses="overflow-y-auto fancy-scroll"
                  ></BootstrapTable>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Modal.Footer>
              <div className="d-flex">
                <ButtonBasic
                  title={showNotes ? "Hide Notes" : "Show Notes"}
                  onClick={() => {
                    setShowNotes(!showNotes);
                  }}
                />
                <ButtonBasic
                  title="Cancel"
                  onClick={() => {
                    setShowSuspiciousModal(false);
                    setNewNotes([]);
                    setShowNotes(false);
                  }}
                />

                <CustomTooltip
                  placement="top"
                  tooltipId="button-tooltip-2"
                  disabled={
                    selectedCategory &&
                    selectedDebitAccount &&
                    selectedCreditAccount &&
                    ATMBalanceNotes?.notes?.length > 0
                      ? false
                      : true
                  }
                  disabledMessage={
                    "Select any category, debit, credit and add Notes"
                  }
                  onClick={handleDone}
                  buttonText="Done"
                  style={{ marginLeft: "10px" }}
                >
                  Done
                </CustomTooltip>
              </div>
            </Modal.Footer>
          </Modal.Footer>
        </Modal>
      </TransactionLogStyle>
    </>
  );
};
export default SuspiciousModal;
