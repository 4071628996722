import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { Formik, Field } from "formik";
import { useDateTime } from "../../../../components/Helper/DateTime";
import MultiStepFormContext from "./MultiStepFormContext";
import AddCassettesConfiguration from "./AddCassettesConfiguration";
import axiosInstance from "../../../../utils/axiosInstance";
import Toast from "../../../../components/Toast/Toast";
import LoaderComponent from "../../../../components/Spinner/LoaderComponent";

const Review = () => {
  const history = useHistory();
  const {
    details,
    ejCashUnLoadData,
    citCashUnLoadData,
    ejRejectedCashUnLoadData,
    ejRetractCashUnLoadData,
    citRejectedCashUnLoadData,
    citRetractCashUnLoadData,
    //ejCashLoadData,
    citCashLoadData,
    selectedDeviceId,
    // next,
    prev,
  } = useContext(MultiStepFormContext);

  const {
    ejCashUnLoadTotalAmount,
    citCashUnLoadTotalAmount,
    ejCashLoadTotalAmount,
    citCashLoadTotalAmount,
  } = details;
  const [loading, setLoading] = useState(false);
  const [cashUnloadWarning, setCashUnloadWarning] = useState(false);
  // const [cashLoadWarning, setCashLoadWarning] = useState(false);

  const { currency, dateTime } = useDateTime();
  // const warning =
  //   "Warning: Total Cash Unload amounts (ATM Scroll & Physical Cash ) are not equal!";

  const convertedDateTime = moment(details?.dateTime);
  const formattedDateTime = convertedDateTime.format(dateTime);

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      "0"
    );

  const handleSaveAndPerform = async (isPerformBalancing) => {
    let data = {
      deviceId: selectedDeviceId,
      // lastRef: details?.lastRef,
      balancingSummaryId: details?.balancingSummaryId,
      dateTime: moment(details?.dateTime).format("YYYY-MM-DD HH:mm:ss"),
      performBalancing: isPerformBalancing,
      ejCashUnLoadBillData: ejCashUnLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      ejCashUnLoadRetractedData: ejRetractCashUnLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      ejCashUnLoadRejectedData: ejRejectedCashUnLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      citCashUnLoadBillData: citCashUnLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      citCashUnLoadRetractedData: citRetractCashUnLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      citCashUnLoadRejectedData: citRejectedCashUnLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      citCashLoadData: citCashLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      citCashLoadTotalAmount: details?.citCashLoadTotalAmount,
      ejCashUnLoadTotalAmount:
        details?.ejCashUnLoadTotalAmount +
        details?.ejRejectedCashUnLoadTotalAmount +
        details?.ejRetractCashUnLoadTotalAmount,
      citCashUnLoadTotalAmount:
        details?.citCashUnLoadTotalAmount +
        details?.citRejectedCashUnLoadTotalAmount +
        details?.citRetractCashUnLoadTotalAmount,
      switchTransactionTotal: details?.switchTransactionTotal,
    };
    try {
      let response = await axiosInstance.post(
        "/devices/atm-cash-replenishment",
        {
          data,
        }
      );
      if (response.message.status !== "200") {
        setLoading(false);
        Toast(response.message.description, "error");
      } else {
        Toast(response.message.description, "success");
        // Redirect to Cash Replenishment Screen
        history.push("/cash-replenishment");
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
    }
  };

  useEffect(() => {
    if (ejCashUnLoadTotalAmount !== citCashUnLoadTotalAmount) {
      setCashUnloadWarning(true);
    }
    // if (ejCashLoadTotalAmount !== citCashLoadTotalAmount) {
    //   setCashLoadWarning(true);
    // }
  }, [
    citCashLoadTotalAmount,
    citCashUnLoadTotalAmount,
    ejCashLoadTotalAmount,
    ejCashUnLoadTotalAmount,
  ]);

  const handlePrint = () => {
    window.print();
  };
  return (
    <>
      <Formik
        initialValues={details}
        // onSubmit={(values) => {

        // }}
      >
        {() => {
          return (
            <>
              {loading && (
                <div
                  className="spinner-center"
                  style={{ top: "0%", left: "0%", zIndex: 2000 }}
                >
                  <LoaderComponent />
                </div>
              )}
              <div className="container">
                <div className="row">
                  <div className="col-3">
                    <label className="form-label">Date & Time</label>

                    <div
                      style={{
                        padding: "11px",
                        border: "2px solid #b4b4b7",
                        borderRadius: "8px",
                        background: "#f4f4f8",
                      }}
                    >
                      {formattedDateTime}
                    </div>
                  </div>
                  <div className="col-3">
                    <label className="form-label">Device ID</label>
                    <Field
                      type="text"
                      placeholder="Device ID"
                      name="deviceId"
                      value={details?.deviceId}
                      disabled={true}
                      style={{
                        padding: "10px",
                        borderRadius: "7px",
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <label className="form-label">
                      switch Transaction Total
                    </label>
                    <Field
                      type="text"
                      placeholder="switch Transaction Total"
                      name="deviceId"
                      value={details?.switchTransactionTotal}
                      disabled={true}
                      style={{
                        padding: "10px",
                        borderRadius: "7px",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row mt-5">
                  {/* 50% column left starts*/}
                  <div className="col-md border p-2">
                    <h4 className="pt-2">Cash Unload</h4> <br />
                    <h5 className="text-info ">ATM Scroll</h5>
                    <div className="row mt-3 d-flex justify-content-start  align-items-start">
                      <div className="col-md">
                        <div className="text-left">Denom</div>
                      </div>
                      <div className="col-md">
                        <div className="text-left">Bill Count</div>
                      </div>

                      <div className="col-md">
                        <div className="text-left">Type</div>
                      </div>

                      <div className="col-md">
                        <div className="text-left">Total</div>
                      </div>
                    </div>
                    <AddCassettesConfiguration
                      rowsData={[ejCashUnLoadData]}
                      isCassetteType={true}
                      isReview={true}
                    />
                    {ejRetractCashUnLoadData?.length > 0 && (
                      <>
                        {" "}
                        <div className="row pt-5">
                          <h5 className="pl-3">Retract Bins</h5>
                        </div>
                        <div className="row mt-3 d-flex justify-content-start  align-items-start">
                          <div className="col-md">
                            <div className="text-left">Denom</div>
                          </div>
                          <div className="col-md">
                            <div className="text-left">Bill Count</div>
                          </div>

                          <div className="col-md">
                            <div className="text-left">Type</div>
                          </div>

                          <div className="col-md">
                            <div className="text-left">Total</div>
                          </div>
                        </div>
                        <AddCassettesConfiguration
                          rowsData={[ejRetractCashUnLoadData]}
                          isCassetteType={true}
                          isReview={true}
                        />
                      </>
                    )}
                    {ejRejectedCashUnLoadData?.length > 0 && (
                      <>
                        <div className="row pt-5">
                          <h5 className="pl-3">Reject Bins</h5>
                        </div>
                        <div className="row mt-3 d-flex justify-content-start  align-items-start">
                          <div className="col-md">
                            <div className="text-left">Denom</div>
                          </div>
                          <div className="col-md">
                            <div className="text-left">Bill Count</div>
                          </div>

                          <div className="col-md">
                            <div className="text-left">Type</div>
                          </div>

                          <div className="col-md">
                            <div className="text-left">Total</div>
                          </div>
                        </div>
                        <AddCassettesConfiguration
                          rowsData={[ejRejectedCashUnLoadData]}
                          isCassetteType={true}
                          isReview={true}
                        />
                      </>
                    )}
                    {(ejRetractCashUnLoadData?.length > 0 ||
                      ejRejectedCashUnLoadData?.length > 0) && (
                      <div className="row d-flex justify-content-center mt-4">
                        <div className="col-10 text-right">
                          <p className="font-weight-bold text-dark">
                            Total:
                            {formatAmount(
                              details?.ejCashUnLoadTotalAmount +
                                details?.ejRetractCashUnLoadTotalAmount +
                                details?.ejRejectedCashUnLoadTotalAmount
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* 50% column right starts*/}
                  <div className="col-md border p-2 ml-5">
                    <h4 className="pt-5">Cash Load</h4> <br />
                    <h5 className="text-info">Physical Cash Count</h5>
                    <div className="row mt-3 d-flex justify-content-start  align-items-start">
                      <div className="col-md">
                        <div className="text-left">Denom</div>
                      </div>
                      <div className="col-md">
                        <div className="text-left">Bill Count</div>
                      </div>

                      <div className="col-md">
                        <div className="text-left">Total</div>
                      </div>
                    </div>
                    <AddCassettesConfiguration
                      rowsData={[citCashLoadData]}
                      isCassetteType={false}
                      isReview={true}
                    />
                    <div className="row d-flex justify-content-center mt-4">
                      <div className="col-10">
                        <p className="font-weight-bold text-dark text-right">
                          Total Cash Load:
                          {formatAmount(details?.citCashLoadTotalAmount)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container mt-5 pb-5">
                <div className="row">
                  {/* Cash Unload 50% column left starts*/}
                  <div className="col-md border p-2">
                    <h5 className="text-info">Physical Cash Count</h5>
                    <div className="row mt-3 d-flex justify-content-start  align-items-start">
                      <div className="col-md">
                        <div className="text-left">Denom</div>
                      </div>
                      <div className="col-md">
                        <div className="text-left">Bill Count</div>
                      </div>

                      <div className="col-md">
                        <div className="text-left">Total</div>
                      </div>
                    </div>
                    <AddCassettesConfiguration
                      rowsData={[citCashUnLoadData]}
                      isCassetteType={false}
                      isReview={true}
                    />

                    {citRetractCashUnLoadData?.length > 0 && (
                      <>
                        <div className="row pt-5">
                          <h5 className="pl-3">Retract Bins</h5>
                        </div>
                        <div className="row mt-3 d-flex justify-content-start  align-items-start">
                          <div className="col-md">
                            <div className="text-left">Denom</div>
                          </div>
                          <div className="col-md">
                            <div className="text-left">Bill Count</div>
                          </div>

                          <div className="col-md">
                            <div className="text-left">Type</div>
                          </div>

                          <div className="col-md">
                            <div className="text-left">Total</div>
                          </div>
                        </div>
                        <AddCassettesConfiguration
                          rowsData={[citRetractCashUnLoadData]}
                          isCassetteType={true}
                          isReview={true}
                        />
                      </>
                    )}
                    {citRejectedCashUnLoadData?.length > 0 && (
                      <>
                        <div className="row pt-5">
                          <h5 className="pl-3">Reject Bins</h5>
                        </div>
                        <div className="row mt-3 d-flex justify-content-start  align-items-start">
                          <div className="col-md">
                            <div className="text-left">Denom</div>
                          </div>
                          <div className="col-md">
                            <div className="text-left">Bill Count</div>
                          </div>

                          <div className="col-md">
                            <div className="text-left">Type</div>
                          </div>

                          <div className="col-md">
                            <div className="text-left">Total</div>
                          </div>
                        </div>
                        <AddCassettesConfiguration
                          rowsData={[citRejectedCashUnLoadData]}
                          isCassetteType={true}
                          isReview={true}
                        />
                      </>
                    )}
                    {(citRetractCashUnLoadData?.length > 0 ||
                      citRejectedCashUnLoadData?.length > 0) && (
                      <div className="row d-flex justify-content-center mt-4">
                        <div className="col-10 text-right">
                          <p className="font-weight-bold text-dark">
                            Total:{" "}
                            {formatAmount(
                              details?.citCashUnLoadTotalAmount +
                                details?.citRetractCashUnLoadTotalAmount +
                                details?.citRejectedCashUnLoadTotalAmount
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  {/*Cit Cash Load 50% column right starts*/}
                  <div className="col-md"></div>
                </div>

                <div className="row mt-5 d-flex justify-space-between align-items-end">
                  <div className="col-md">
                    <button className="btn btn-secondary" onClick={prev}>
                      Back
                    </button>
                  </div>
                  <div className="col-md text-right">
                    <button
                      className="btn btn-secondary ml-2"
                      onClick={handlePrint}
                    >
                      Print
                    </button>
                    <button
                      className="btn btn-info ml-3"
                      onClick={() => handleSaveAndPerform(true)}
                      //disabled={cashLoadWarning ? true : false}
                    >
                      Save & Perform Balancing
                    </button>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-12 d-flex justify-content-center ">
                    {cashUnloadWarning && (
                      <Alert variant="warning">
                        {`Warning: Total Cash Unload amounts (ATM Scroll & Physical Cash Count ) are not equal!`}
                      </Alert>
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default Review;
