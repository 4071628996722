import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BadgeRounded from "../../../components/BadgeRounded/BadgeRounded";
import { useDateTime } from "../../../components/Helper/DateTime";
const POSDetailViewModal = ({ id }) => {
  const { currency } = useDateTime();
  const [data] = useState([
    {
      id: 1,
      teller_id: 12324,
      opening_balance: 234,
      deposits: 65,
      withdrawals: 53,
      closing_balance: 76,
      status: "Balanced",
    },
    {
      id: 2,
      teller_id: 12324,
      opening_balance: 234,
      deposits: 65,
      withdrawals: 53,
      closing_balance: 76,
      status: "Shortage",
    },
  ]);
  const status = (row) => (
    <BadgeRounded
      color={
        row === "Balanced"
          ? "success"
          : row === "Shortage"
          ? "warning"
          : "danger"
      }
      title={row}
    />
  );
  const columns = [
    { hidden: true, dataField: "id", text: "ID" },
    {
      dataField: "teller_id",
      text: "Teller ID",
    },
    {
      dataField: "opening_balance",
      // formatter:
      text: "Opening Balance",
    },
    {
      dataField: "deposits",
      // formatter:
      text: "Deposits",
    },
    {
      dataField: "withdrawals",
      // formatter:
      text: "Withdrawals",
    },
    {
      dataField: "closing_balance",
      // formatter:
      text: "Closing Balance",
    },
    {
      dataField: "status",
      formatter: status,
      text: "Status",
    },
  ];
  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Branch Id:</div>
            <div className="value  ">{"ABC123"}</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Location:</div>
            <div className="value  ">{"Silver Street"}</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Branch Type:</div>
            <div className="value  ">{"123456"}</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">GL Account:</div>
            <div className="value  ">{"Computers, Equipments, Software"}</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">GL Opening Balance:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">GL Closing Balance:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-12">Total Transactions Cash:</div>
        <div className="col-sm-12 col-lg-4">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Cash Deposit:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Check Deposit:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Cash Dispense:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Check Clear:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-8">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Check Cash-in:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-12">Total Transactions Non-Cash:</div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Pay Orders/Bank Drafts:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Bulk/Retail Deposit:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">3rd party payments:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">RTGS:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
      </div>
      <div className="row p-3">
        <div className="ml-2 mb-3 mt-2  ">Teller list:</div>
        <BootstrapTable
          bootstrap4={true}
          classes="rs-table table-dim-dark border-0 modal-table table-layout-auto fancy-scroll table-custom-style"
          wrapperClasses="overflow-y-auto"
          keyField="cashDisbursementAmount"
          data={data}
          columns={columns}
        ></BootstrapTable>
      </div>
    </div>
  );
};
export default POSDetailViewModal;
