import { useContext, useEffect, useState } from "react";
import { Field } from "formik";
import Select from "react-select";
import NewFilter from "../../../../components/FilterDropdown/GenericFilter";
import CurrencyFormat from "react-currency-format";
import { useDateTime } from "../../../../components/Helper/DateTime";
import MultiStepFormContext from "./MultiStepFormContext";

const AddCassettesConfiguration = ({
  type,
  rowsData,
  prevRowsData,
  deleteTableRows,
  handleChange,
  isCassetteType,
  isReview,
  errors,
  touched,
  isDisabled,
}) => {
  const { currency } = useDateTime();

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      "0"
    );
  return rowsData[0]?.map((data, index) => {
    const { billCount, denomination, cassetteType, isNewRow } = data;
    return (
      <div
        key={index}
        className="row mt-3 d-flex justify-content-start  align-items-start"
      >
        <div className="col-md">
          <Field
            className="form-control rs-input"
            type="number"
            placeholder=""
            name="denomination"
            value={denomination}
            onChange={(evnt) => handleChange(index, evnt)}
            disabled={isDisabled || !isNewRow || !!isReview} // Disable for prefilled rows and when in review mode
          />
        </div>
        <div className="col-md">
          <Field
            type="text"
            className={`form-control rs-input ${
              type === "LOAD_CASH" &&
              prevRowsData?.length > 0 &&
              prevRowsData?.[index]?.billCount !== billCount
                ? "is-invalid"
                : type === "UNLOAD_CASH" &&
                  prevRowsData?.length > 0 &&
                  prevRowsData?.[index]?.billCount !== billCount
                ? "is-warning"
                : ""
            }`}
            placeholder=""
            id="billCount"
            name="billCount"
            value={billCount}
            onChange={(evnt) => {
              handleChange(index, evnt);
            }}
            disabled={isDisabled || !!isReview}
          />
          {errors?.billCount && (
            <div className="field-error">{errors?.billCount[index]}</div>
          )}
        </div>
        {isCassetteType && (
          <div className="col-md">
            <div className="text-left">
              <Field
                className="form-control rs-input"
                type="text"
                placeholder=""
                name="cassetteType"
                value={cassetteType}
                onChange={(evnt) => handleChange(index, evnt)}
                disabled={isDisabled || !!isReview || !isNewRow}
              />

              {/* <Field
                className="form-control rs-input"
                type="text"
                placeholder=""
                name="cassetteType"
                value={cassetteType}
                onChange={(evnt) => handleChange(index, evnt)}
                disabled={!!isReview}
              /> */}
            </div>
          </div>
        )}
        <div className="col-md">
          <p className="text-left">{formatAmount(denomination * billCount)}</p>
        </div>
        {!isReview && (
          <div className="col-md">
            {isNewRow && (
              <button
                className="btn btn-outline-danger"
                type="button"
                onClick={() => deleteTableRows(index)}
                // disabled={!isNewRow}
              >
                x
              </button>
            )}
          </div>
        )}
      </div>
    );
  });
};

export default AddCassettesConfiguration;
