const FontBaseValue = 10;
const SpacingBaseValue = 10;

export const Fonts = {
  primary: {
    regular: "Montserrat Regular",
    medium: "Montserrat Medium",
    semiBold: "Montserrat SemiBold",
    bold: "Montserrat Bold",
    black: "Montserrat Black",
  },
};

// Light Theme
export const lightTheme = {
  body: "#f7f7fc",
  text: {
    black: "#000",
    base: "#1f2729",
    bright: "#1f2729",
    hover: "#333",
    light: "#1f2729",
    dark: "#606060",
  },
  icon_color: "brightness(0.6)",
  hover: "#f5f5f5",
  border: "#1f2729",
  dropdown_border: "#ccc",
  border_Light: "#ccc",
  dropdown_border_hover: "#1f2729",
  background: "#F2F3FA",
  card_background: "#fff",
  customTableBg: "#f2f2f2",
  modal_background: "#F2F3FA",
  table_background: "#fff",
  table_card_background: "#F2F3FA",
  table_color: "#1f2729",
  body_color: "#1f2729",
  // sidebar_text: "#777",
  invert: 1,
  badge: "#fff",
  input_border: "#c4c4c4",
  input_placeholder: "#a7a7a7",
  placeholder: "#ccc",
  spacing: {
    xxs: SpacingBaseValue * 0.8,
    xs: SpacingBaseValue,
    sm: SpacingBaseValue * 1.2,
    md: SpacingBaseValue * 1.4,
    lg: SpacingBaseValue * 1.6,
    xl: SpacingBaseValue * 1.8,
    xxl: SpacingBaseValue * 2,

    s40: SpacingBaseValue * 4,
    s30: SpacingBaseValue * 3,
  },

  fonts: {
    size: {
      xs: FontBaseValue,
      sm: FontBaseValue * 1.2,
      md: FontBaseValue * 1.4,
      lg: FontBaseValue * 1.6,
      xl: FontBaseValue * 1.8,
      xxl: FontBaseValue * 2,

      h1: FontBaseValue * 4,
      h2: FontBaseValue * 3,
    },
    family: {
      primary: {
        regular: Fonts.primary.regular,
        medium: Fonts.primary.medium,
        semiBold: Fonts.primary.semiBold,
        bold: Fonts.primary.bold,
        black: Fonts.primary.black,
      },
    },
  },

  chartColors: {
    info: "#118AB2",
    danger: "#EF476F",
    success: "#00AF91",
    warning: "#FFD166",
    purple: "#845ec2",
    lightBlue: "#00B8D9",
    darkSlateGrey: "#2F4F4F",
    darkOrange: "#FF8C00",
    slateBlue: "#6A5ACD",
    navy: "#000080",
    stateBlue: "#6A5ACD",
    cyan: "#00bcd4",
    brown: "#964B00",
  },
};

// Dark Theme
export const darkTheme = {
  body: "#121212",
  text: {
    black: "#fff",
    base: "#fff",
    bright: "#ccc",
    hover: "#ccc",
    light: "#666",
    dark: "#ccc",
  },
  icon_color: "brightness(1)",
  hover: "#333",
  border: "#333",
  border_Light: "#333",
  dropdown_border: "#fff",
  dropdown_border_hover: "#ccc",
  background: "#121212",
  card_background: "#1D1D1D",
  modal_background: "#272727",
  body_color: "#fff",
  // sidebar_text: "#ccc",
  badge: "#121212",
  table_background: "#272727",
  table_color: "#fff",
  table_card_background: "#272727",
  input_border: "#fff",
  input_placeholder: "#fff",
  placeholder: "#424040",
  invert: 0,
  spacing: {
    xs: SpacingBaseValue,
    sm: SpacingBaseValue * 1.2,
    md: SpacingBaseValue * 1.4,
    lg: SpacingBaseValue * 1.6,
    xl: SpacingBaseValue * 1.8,
    xxl: SpacingBaseValue * 2,

    s40: SpacingBaseValue * 4,
    s30: SpacingBaseValue * 3,
  },

  fonts: {
    size: {
      xs: FontBaseValue,
      sm: FontBaseValue * 1.2,
      md: FontBaseValue * 1.4,
      lg: FontBaseValue * 1.6,
      xl: FontBaseValue * 1.8,
      xxl: FontBaseValue * 2,

      h1: FontBaseValue * 4,
      h2: FontBaseValue * 3,
    },
    family: {
      primary: {
        regular: Fonts.primary.regular,
        medium: Fonts.primary.medium,
        semiBold: Fonts.primary.semiBold,
        bold: Fonts.primary.bold,
        black: Fonts.primary.black,
      },
    },
  },

  chartColors: {
    info: "#118AB2",
    danger: "#EF476F",
    success: "#00AF91",
    warning: "#FFD166",
    purple: "#845ec2",
    lightBlue: "#00B8D9",
  },
};
