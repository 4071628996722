import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axiosInstance from "../../../utils/axiosInstance";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
const ViewModal = ({ id, show, onHide }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axiosInstance
      .get(`/institutions/${id}`)
      .then((res) => {
        setData(res.data.institutions[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    return () => {
      setData({});
      setLoading(true);
    };
  }, [id]);
  return (
    <Modal
      backdrop={true}
      size="xl"
      show={show}
      onHide={() => onHide(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body scrollable="true">
        {loading && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div
          className="row"
          style={{
            opacity: !loading ? "1" : "0.07",
          }}
        >
          <div className="col-12 overflow-auto">
            <div className="row">
              <div className="col-sm-12 col-lg-12" id="institutionId">
                <span className="color-gray-dim">Institution Item:</span> {id}
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Name</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="value  " id="institutionName">
                    {data?.institutionName}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Base Currency</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="value  " id="baseCurrencyCode">
                    {data?.baseCurrencyCode}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">BIC Code</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="value  " id="bicCode">
                    {data?.bicCode ?? "-"}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Bin</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="value  " id="bin">
                    {data?.bin ?? "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewModal;
