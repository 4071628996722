import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FormCheck, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select, { components } from "react-select";
import ButtonBasic from "../../../../components/Buttons/ButtonBasic";
import OperatorDescription from "../OperatorDescription.json";
import { DSAValidationSchema, errorStyling } from "../ValidationSchema";
const DSAModal = ({
  values,
  operatorOptions,
  dsaFields,
  dsbFields,
  handleSubmit,
  show,
  onHide,
  editObject,
}) => {
  const [formValues, setFormValues] = useState(values);
  useEffect(() => {
    if (values) setFormValues(values);
  }, [values]);
  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={(values) => {
        //exlude the empty fields from object
        values = Object.fromEntries(
          Object.entries(values).filter(([, v]) => v !== "")
        );
        handleSubmit(editObject?.path, values);
        setFormValues({});
        onHide();
      }}
      validationSchema={DSAValidationSchema}
    >
      {({ handleSubmit, errors, values, setFieldValue, setValues }) => (
        <Form id="dsaForm" className="form" onSubmit={handleSubmit}>
          <Modal
            backdrop={true}
            size="xl"
            aria-labelledby="example-modal-sizes-title-lg"
            show={show}
            onHide={() => onHide()}
          >
            <Modal.Header closeButton>
              {editObject?.isNew
                ? "Add DSA Fetch Criteria Field"
                : "Edit DSA Fetch Criteria Field"}
            </Modal.Header>
            <Modal.Body scrollable="true">
              <div className="p-3 my-3">
                <div className="row">
                  <div className="col-4">
                    {/*DS A Field Name DropDown For DSA Fetch Criteria */}
                    <div className="workflowModalTitle">DSA Field Name</div>
                    <div title={values?.ds_a_field_name}>
                      <Select
                        options={dsaFields}
                        name={"ds A Field Name"}
                        value={dsaFields?.find(
                          (field) => field.value === values?.ds_a_field_name
                        )}
                        onChange={(e) =>
                          setFieldValue("ds_a_field_name", e?.value)
                        }
                        isClearable
                      />
                    </div>
                    <ErrorMessage
                      component={() => (
                        <div style={errorStyling}>
                          {errors?.ds_a_field_name}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-4">
                    {/* Operator DropDown For DSA Fetch Criteria */}
                    <div className="workflowModalTitle">Operator</div>
                    <div
                      title={
                        operatorOptions?.leftCriteriaOperators?.find(
                          (operator) => operator.value === values?.operator
                        )?.label
                      }
                    >
                      <Select
                        options={operatorOptions?.leftCriteriaOperators}
                        name={"Operator"}
                        value={operatorOptions?.leftCriteriaOperators?.find(
                          (operator) => operator.value === values?.operator
                        )}
                        onChange={(e) => {
                          // if (
                          //   operatorOptions?.leftCriteriaOperators?.find(
                          //     (x) => x.value == e?.value
                          //   )?.requiredValue == false
                          // ) {
                          //   if (e?.value === "BTW") {
                          //     setFieldValue(
                          //       `fromFetchCriteria.fields[${index}].description`,
                          //       "value will be picked from start and end parameter from recons scheduler"
                          //     );
                          //   } else {
                          //     setFieldValue(`fromFetchCriteria.fields[${index}]`, {
                          //       ...row,
                          //     });
                          //   }
                          // } else {
                          //   if (e?.value === "BTW") {
                          //     setFieldValue(
                          //       `fromFetchCriteria.fields[${index}].description`,
                          //       "value will be picked from start and end parameter from recons scheduler"
                          //     );
                          //   } else {
                          //     setFieldValue(
                          //       `fromFetchCriteria.fields[${index}].ds_a_field_value`,
                          //       ""
                          //     );
                          //   }
                          // }
                          if (e?.value === "BTW") {
                            //except ds_a_field_value
                            const { ds_a_field_value, ...rest } = values;
                            setValues({
                              description:
                                "value will be picked from start and end parameter from recons scheduler",
                              ...rest,
                            });
                          } else {
                            const { description, ds_a_field_value, ...rest } =
                              values;
                            setValues({
                              ds_a_field_value: "",
                              ...rest,
                            });
                          }

                          setFieldValue("operator", e?.value);
                        }}
                        components={{
                          Option: (props) =>
                            OperatorDescription?.find(
                              (op) => op?.operator == props?.data?.value
                            )?.description ? (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {
                                      OperatorDescription?.find(
                                        (op) =>
                                          op?.operator == props?.data?.value
                                      )?.description
                                    }
                                  </Tooltip>
                                }
                              >
                                <div>
                                  <components.Option {...props} />
                                </div>
                              </OverlayTrigger>
                            ) : (
                              <components.Option {...props} />
                            ),
                        }}
                        isClearable
                      />
                    </div>
                    <ErrorMessage
                      component={() => (
                        <div style={errorStyling}>{errors?.operator}</div>
                      )}
                    />
                  </div>

                  <div className="col-4">
                    {/* DS A Field Value Text Field For DSA Fetch Criteria */}
                    <div className="workflowModalTitle">DSA Field Value</div>
                    <Field
                      title={values?.ds_a_field_value ?? values?.description}
                      className="form-control rs-input highlight"
                      type="text"
                      placeholder={
                        values?.operator === "LT" ||
                        values?.operator === "GT" ||
                        values?.operator === "LTE" ||
                        values?.operator === "GTE"
                          ? "Enter numeric value"
                          : values?.operator === "IN" ||
                            values?.operator === "NIN"
                          ? "Multiple comma separated values are allowed"
                          : "DSA Field Value"
                      }
                      value={
                        values?.ds_a_field_value ?? values?.description ?? ""
                      }
                      onChange={(e) => {
                        if (
                          values?.operator === "LT" ||
                          values?.operator === "GT" ||
                          values?.operator === "LTE" ||
                          values?.operator === "GTE"
                        ) {
                          const value = parseInt(e.target.value);
                          isNaN(value)
                            ? setFieldValue("ds_a_field_value", "")
                            : setFieldValue("ds_a_field_value", value);
                        } else {
                          setFieldValue("ds_a_field_value", e.target.value);
                        }
                      }}
                      // disabled={
                      //   // If Operator is BTW then disable that field
                      //   row?.operator === "BTW"
                      //     ? true
                      //     : // Search That selected Operator in left Criteria Operators array and check if requiredValue is true or false
                      //     operatorOptions?.leftCriteriaOperators?.find(
                      //         (x) => x.value == row?.operator
                      //       )?.requiredValue
                      //     ? false
                      //     : true
                      // }
                      disabled={
                        values?.operator
                          ? values?.operator === "BTW"
                            ? true
                            : false
                          : true
                      }
                    />
                  </div>
                  {/* Show and Hide Icon */}
                </div>

                <div className="row mt-3">
                  <div className="col-4">
                    {/*  Label Text Field For DSA Fetch Criteria */}
                    <div className="workflowModalTitle">Label</div>
                    <Field
                      title={values?.label}
                      className="form-control rs-input highlight"
                      type="text"
                      placeholder="Label"
                      value={values?.label}
                      onChange={(e) => setFieldValue("label", e.target.value)}
                    />
                  </div>
                  {console.log("values", values)}
                  {/* DS B Field Name DropDown this depends on Reverse Lookup */}
                  {values?.hasOwnProperty("ds_b_field_name") && (
                    <div className="col-4">
                      {/* DS B Field Name Dropdown For DSA Fetch Criteria */}
                      <div className="workflowModalTitle">DSB Field Name</div>
                      <div title={values?.ds_b_field_name}>
                        <Select
                          options={dsbFields}
                          name={"DSB Field Name"}
                          value={dsbFields?.find(
                            (field) => field.value === values?.ds_b_field_name
                          )}
                          onChange={(e) =>
                            setFieldValue("ds_b_field_name", e?.value)
                          }
                          isClearable
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-2">
                    {/* Reverse Lookup Checkout Box in DSA for showing DS B Field Name */}
                    <div className="workflowModalTitle">Reverse Lookup</div>
                    <div className="form-check py-2">
                      <FormCheck
                        id="reverseLookup"
                        type="switch"
                        name="reverseLookup"
                        style={{ zIndex: 0 }}
                        checked={
                          values?.hasOwnProperty("ds_b_field_name")
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          // If checked then show DS B Field Name
                          if (e.target.checked) {
                            setFieldValue("ds_b_field_name", "");
                          } else {
                            // If not checked then remove DS B Field Name
                            //remove  ds_b_field_name from object
                            const { ds_b_field_name, ...updatedField } = values;
                            // Update the state with the new object
                            setValues(updatedField);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <ButtonBasic
                  id="cancel"
                  title="Cancel"
                  onClick={() => onHide()}
                />
              </div>
              <button
                id="submit"
                type="submit"
                name="submit"
                className={"btn btn-info btn-md mr-2"}
                onClick={handleSubmit}
              >
                {editObject?.isNew ? "Add" : "Update"}
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default DSAModal;
