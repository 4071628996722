import React, { useContext, useState, useEffect } from "react";
import { Formik, isEmptyArray } from "formik";
import CurrencyFormat from "react-currency-format";
import "react-datepicker/dist/react-datepicker.css";
import AddCassettesConfiguration from "./AddCassettesConfiguration";
import { useDateTime } from "../../../../components/Helper/DateTime";
import MultiStepFormContext from "./MultiStepFormContext";
import { Alert } from "react-bootstrap";

export const CitCashLoad = () => {
  const {
    atmBalancingData,
    citCashLoadData,
    ejCashLoadData,
    setCitCashLoadData,
    next,
    prev,
    devicesData,
    details,
    setDetails,
  } = useContext(MultiStepFormContext);

  const { currency } = useDateTime();
  const [errors, setErrors] = useState({});

  const deleteTableRows = (index) => {
    const rows = [...citCashLoadData];
    rows.splice(index, 1);
    setCitCashLoadData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    if (value)
      setErrors((prev) => ({
        ...prev,
        billCount: {
          ...prev.billCount,
          [index]: null,
        },
      }));
    setCitCashLoadData((prevState) => {
      const rowsInput = [...prevState];
      rowsInput[index][name] = value;
      return rowsInput;
    });
  };

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      "0"
    );

  const grandTotal = () => {
    setDetails({
      ...details,
      citCashLoadTotalAmount: citCashLoadData?.reduce(
        (acc, row) =>
          acc + parseInt(row.denomination ?? 0) * parseInt(row.billCount),
        0
      ),
    });
  };

  useEffect(() => {
    if (!isEmptyArray(citCashLoadData)) {
      return;
    } else {
      const deviceCassettes = atmBalancingData?.cashReportCashLoad?.map(
        (item) => ({
          denomination: item.denom,
          cassetteType: item.type,
          billCount: item.counts,
        })
      );
      setCitCashLoadData(deviceCassettes || []);
    }
  }, [devicesData, setCitCashLoadData, citCashLoadData, atmBalancingData?.cashReportCashLoad]);

  useEffect(() => {
    grandTotal();
  }, [citCashLoadData]);

  return (
    <Formik
      initialValues={citCashLoadData}
      enableReinitialize={true}
      onSubmit={(values) => {
        // let error = false;
        // values.forEach((el, i) => {
        //   if (!el.billCount || isNaN(el.billCount)) {
        //     error = true;
        //     setErrors((prev) => ({
        //       ...prev,
        //       billCount: {
        //         ...prev.billCount,
        //         [i]: "Required number",
        //       },
        //     }));
        //   }
        // });
        // if (
        //   !error
        //   && details?.ejCashLoadTotalAmount === details?.citCashLoadTotalAmount
        // ) {
          setCitCashLoadData(values);
          next();
        // }
      }}
    >
      {({ handleSubmit, touched }) => {
        return (
          <>
            <div className="container">
              <div className="row pt-3">
                <h5 className="pl-3">
                  {"Cash Load > Physical Cash Count (Previous Replenishment)"}
                </h5>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="row mt-3 d-flex justify-content-start  align-items-start">
                    <div className="col-md">
                      <div className="text-left">Denomination</div>
                    </div>
                    <div className="col-md">
                      <div className="text-left">Bill Count</div>
                    </div>

                    <div className="col-md">
                      <div className="text-left">Total</div>
                    </div>

                    {/* <div className="col-md">
                      <button
                        className="btn btn-outline-success"
                        type="button"
                        onClick={addTableRows}
                      >
                        +
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-10">
                  <AddCassettesConfiguration
                    type="LOAD_CASH"
                    rowsData={[citCashLoadData]}
                    prevRowsData={ejCashLoadData}
                    deleteTableRows={deleteTableRows}
                    handleChange={handleChange}
                    isCassetteType={false}
                    errors={errors}
                    touched={touched}
                    isDisabled={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-5">
                  <div className="text-center">
                    <h5>
                      <strong>
                        Total Cash Load:{" "}
                        {formatAmount(details?.citCashLoadTotalAmount)}
                      </strong>
                    </h5>
                  </div>
                </div>

                <div className="d-flex">
                  <button className="btn btn-info" onClick={prev}>
                    Back
                  </button>
                  <button className="btn btn-info ml-2" onClick={handleSubmit}>
                    Next
                  </button>
                </div>
              </div>
              {/* {details?.ejCashLoadTotalAmount !==
                details?.citCashLoadTotalAmount && (
                <div className="row mt-3">
                  <div className="col-12 d-flex justify-content-start">
                    <Alert variant="danger">
                      {`Error: Total Cash Load amounts (EJ & Physical Cash Count ) are not equal!`}
                    </Alert>
                  </div>
                </div>
              )} */}
            </div>
          </>
        );
      }}
    </Formik>
  );
};
export default CitCashLoad;
