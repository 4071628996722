import React, { useEffect, useState } from "react";
import AddCassettesConfiguration from "./AddCassettesConfiguration";
import { Formik } from "formik";
import CurrencyFormat from "react-currency-format";
import { useDateTime } from "../../../components/Helper/DateTime";
import { Modal } from "react-bootstrap";
import moment from "moment";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import axiosInstance from "../../../utils/axiosInstance";
import Toast from "../../../components/Toast/Toast";
import BadgeRounded from "../../../components/BadgeRounded/BadgeRounded";

const ATMGLBalancing = ({ showModal, setShowModal, rowData }) => {
  const { currency, dateTime } = useDateTime();
  const convertedDateTime = moment(
    new Date(`${rowData?.[0]?.date}T${rowData?.[0]?.time}`)
  );
  const [ATMs, setATMs] = useState([]);
  const formattedDateTime = convertedDateTime.format(dateTime);
  const [remainingCashATM, setRemainingCashATM] = useState([]);
  const [additionalCashReplenishment, setAdditionalCashReplenishment] =
    useState([]);
  const [glBalanceTotal, setGlBalanceTotal] = useState(0);
  const [totalCashInAtm, setTotalCashInATM] = useState(0);
  const [cashDispensedEOD, setCashDispensedEOD] = useState([]);
  const [grandTotal, setGrandTotal] = useState({
    remainingCashATMAmount: 0,
    additionalCashReplenishmentAmount: 0,
    cashDispensedEODAmount: 0,
  });

  const handleChange = (index, evnt, type) => {
    const { name, value } = evnt.target;
    if (type === "remainingCashATM") {
      setRemainingCashATM((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    } else if (type === "additionalCashReplenishment") {
      setAdditionalCashReplenishment((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    } else if (type === "cashDispensedEOD") {
      setCashDispensedEOD((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    }
  };

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
      />
    ) : (
      `${currency} 0`
    );

  const getDevice = async () => {
    try {
      let response = await axiosInstance.get(
        `/devices/atm-devices?terminal_id=${rowData
          ?.map((item) => item?.terminalId)
          ?.join(",")}`
      );
      if (response.message.status !== "200") {
        setATMs(null);
      } else {
        setATMs(response.data);
      }
    } catch (error) {
      console.log("devices error", error);
    }
  };
  useEffect(() => {
    getDevice();
  }, []);

  const handleSaveAndPerform = async () => {
    let data = {
      date: moment(new Date(convertedDateTime)).format("YYYY-MM-DD HH:mm:ss"),
      performGlBalancing: true,
      glBalanceTotal: glBalanceTotal,
      cashUnloadedTotal: totalCashInAtm,
      payload: {
        remainingCashATM: remainingCashATM,
        additionalCashReplenishment: additionalCashReplenishment,
        cashDispensedEOD: cashDispensedEOD,
        grandTotal: grandTotal,
        terminalId: rowData?.map((item) => item?.terminalId)?.join(","),
        dateTime: formattedDateTime,
        totalCashInAtm: totalCashInAtm,
      },
    };
    try {
      let response = await axiosInstance.post(
        "/devices/atm-cash-replenishment",
        {
          data,
        }
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        Toast(response.message.description, "success");
        setShowModal(false);
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
    }
  };
  useEffect(() => {
    setGrandTotal({
      remainingCashATMAmount: remainingCashATM
        .map((item) => Number(item?.amount))
        .reduce((a, b) => a + b, 0),
      additionalCashReplenishmentAmount: additionalCashReplenishment
        .map((item) => Number(item?.amount))
        .reduce((a, b) => a + b, 0),
      cashDispensedEODAmount: cashDispensedEOD
        .map((item) => Number(item?.amount))
        .reduce((a, b) => a + b, 0),
    });
  }, [additionalCashReplenishment, cashDispensedEOD, remainingCashATM]);

  useEffect(() => {
    setTotalCashInATM(
      grandTotal?.remainingCashATMAmount -
        grandTotal?.additionalCashReplenishmentAmount +
        grandTotal?.cashDispensedEODAmount
    );
  }, [
    grandTotal?.remainingCashATMAmount,
    grandTotal?.additionalCashReplenishmentAmount,
    grandTotal?.cashDispensedEODAmount,
  ]);

  useEffect(() => {
    const remainingCashATMCassettes = rowData?.map((data, index) => {
      return {
        atmId: data?.terminalId,
        amount: data?.ejResidualCashAmount,
      };
    });
    const additionalCashReplenishmentCassettes = rowData?.map((data, index) => {
      return {
        atmId: data?.terminalId,
        amount: "",
      };
    });
    const cashDispensedEODCassettes = rowData?.map((data, index) => {
      return {
        atmId: data?.terminalId,
        amount: "",
      };
    });

    setAdditionalCashReplenishment(additionalCashReplenishmentCassettes);
    setCashDispensedEOD(cashDispensedEODCassettes);
    setRemainingCashATM(remainingCashATMCassettes);
  }, [rowData]);
  const Status = ({ cell }) => (
    <BadgeRounded
      color={
        cell === "CASH BALANCED"
          ? "success"
          : cell === "CASH SHORTAGE"
          ? "danger"
          : ""
      }
      customStyle={
        cell === "CASH EXCESS" ? { backgroundColor: "#007bff" } : null
      }
      title={cell}
    />
  );

  const userBranch = JSON.parse(localStorage.getItem("userData"))?.branchName;

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="xl"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>ATM GL Balancing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Branch Name:</div>
                  <div className="value color-dark">{userBranch}</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Branch Code:</div>
                  <div className="value color-dark">1001</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">GL EOD Date:</div>
                  <div className="value color-dark">{formattedDateTime}</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">GL Balancing Date:</div>
                  <div className="value color-dark">
                    {moment(new Date()).format(dateTime)}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Type of Sheet:</div>
                  <div className="value color-dark">Current/Revised</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Branch ATM-IDs:</div>
                  <div className="value color-dark">
                    {rowData?.map((atm) => atm?.terminalId)?.join(",")}
                  </div>
                </div>
              </div>
            </div>
            <br />

            {/* First Section */}
            {remainingCashATM?.length > 0 && (
              <section className="border border-dark p-2 py-3">
                <div className="row">
                  <div className="col-5">
                    <h5 className="text-left text-bold">
                      REMAINING CASH IN THE ATMs
                    </h5>
                    <div className="text-left pl-5">
                      (Remaining cash in each ATM that physicaly counted at the
                      time of Cash Replenishment at 12:00 Hrs for balancing of
                      ATM Cash for the day )
                    </div>
                  </div>
                  <div className="col-7">
                    <AddCassettesConfiguration
                      rowsData={remainingCashATM || []}
                      handleChange={(index, evnt) =>
                        handleChange(index, evnt, "remainingCashATM")
                      }
                      isDisabled={true}
                    />
                  </div>
                </div>
              </section>
            )}

            {/* 2nd Section */}
            {additionalCashReplenishment?.length > 0 && (
              <section className="border border-dark p-2 py-3 mt-3">
                <div className="row">
                  <div className="col-5">
                    <h5 className="text-left text-bold">
                      ADDITIONAL CASH REPLENISHED, IF ANY
                    </h5>
                    <div className="text-left pl-5">
                      (Amount of 'additional cash replenished' after midnight
                      (00:00) and before the Cash Replenishment time - 12:00 Hrs
                      for the day. )
                    </div>
                  </div>
                  <div className="col-7">
                    <AddCassettesConfiguration
                      rowsData={additionalCashReplenishment || []}
                      handleChange={(index, evnt) =>
                        handleChange(index, evnt, "additionalCashReplenishment")
                      }
                    />
                  </div>
                </div>
              </section>
            )}

            {/* 3rd Section */}
            <section className="border border-dark p-2 py-3 mt-3">
              <div className="row">
                <div className="col-5">
                  <h5 className="text-left text-bold">
                    TOTAL CASH IN ATMs AT '00:00 Hrs' FOR THE DAY
                  </h5>
                </div>
                <div className="offset-3 col-3">
                  <h5 className="border border-dark text-dark text-center py-2">
                    Total: {formatAmount(totalCashInAtm)}
                  </h5>
                </div>
              </div>
            </section>

            {/* 4th Section */}
            {cashDispensedEOD?.length > 0 && (
              <section className="border border-dark p-2 py-3 mt-3">
                <div className="row">
                  <div className="col-5">
                    <h5 className="text-left text-bold">
                      CASH DISPENSED AFTER END-OF-DAY
                    </h5>
                    <div className="text-left pl-5">
                      (Note down the 'FIRST' transaction sequence number after
                      midnight (00:00) Hrs and 'LAST' transaction sequence
                      number before the replenishment time (12:00 Hrs) for the
                      day to determine despensed amount value of each ATM from
                      RAMS report. )
                    </div>
                  </div>
                  <div className="col-7">
                    <AddCassettesConfiguration
                      rowsData={cashDispensedEOD || []}
                      handleChange={(index, evnt) =>
                        handleChange(index, evnt, "cashDispensedEOD")
                      }
                    />
                  </div>
                </div>
              </section>
            )}

            {/* 5th Section */}
            <section className="border border-dark p-2 py-3 mt-3">
              <div className="row">
                <div className="col-5">
                  <h5 className="text-left text-bold">
                    Status of cash as per General Ledger
                  </h5>
                </div>
                <div className="offset-4 col-3">
                  <div className="form-group m-0">
                    <input
                      className="form-control rs-input"
                      placeholder="GL Balance Total"
                      value={glBalanceTotal}
                      onChange={(e) => {
                        setGlBalanceTotal(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>

            {/* 6th Section */}
            <section className="p-2 py-3 mt-3">
              <div className="row">
                <div className="col-5">
                  <h5 className="text-left text-bold">
                    Difference of Physical Cash VS Cash with ATM GL
                  </h5>
                </div>
                <div className="col-2 ">
                  {totalCashInAtm > glBalanceTotal ? (
                    <Status cell={"CASH EXCESS"} />
                  ) : totalCashInAtm < glBalanceTotal ? (
                    <Status cell={"CASH SHORT"} />
                  ) : (
                    ""
                  )}
                </div>
                <div className="offset-1 col-3">
                  <h5 className="border border-dark text-dark text-center py-2">
                    Total: {formatAmount(totalCashInAtm - glBalanceTotal)}
                  </h5>
                </div>
              </div>
            </section>
            <br />

            <div className="row">
              <div className=" col-12 d-flex justify-content-end">
                <ButtonBasic
                  title="Cancel"
                  onClick={() => setShowModal(false)}
                />
                <ButtonBasic
                  title="Submit for Review"
                  onClick={() => handleSaveAndPerform()}
                />
              </div>
            </div>
          </div>
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ATMGLBalancing;
