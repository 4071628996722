import React, { useContext, useState } from "react";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { Formik, Field } from "formik";
import { useDateTime } from "../../../../../components/Helper/DateTime";
import MultiStepFormContext from "./MultiStepFormContext";
import AddCassettesConfiguration from "./AddCassettesConfiguration";
import axiosInstance from "../../../../../utils/axiosInstance";
import Toast from "../../../../../components/Toast/Toast";
import LoaderComponent from "../../../../../components/Spinner/LoaderComponent";

const Review = () => {
  const {
    details,

    citCashLoadData,
    selectedDeviceId,
    next,
    prev,
  } = useContext(MultiStepFormContext);

  const [loading, setLoading] = useState(false);
  const { currency, dateTime } = useDateTime();

  const convertedDateTime = moment(details?.dateTime);
  const formattedDateTime = convertedDateTime.format(dateTime);

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      "0"
    );

  const handleSaveAndPerform = async (isPerformBalancing) => {
    let data = {
      deviceId: selectedDeviceId,
      lastRef: details?.lastRef,
      dateTime: moment(details?.dateTime).format("YYYY-MM-DD HH:mm:ss"),
      performBalancing: isPerformBalancing,

      citCashLoadData: citCashLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      citCashLoadTotalAmount: details?.citCashLoadTotalAmount,
    };
    try {
      let response = await axiosInstance.post(
        "/devices/atm-cash-replenishment",
        {
          data,
        }
      );
      if (response.message.status !== "200") {
        setLoading(false);
        Toast(response.message.description, "error");
      } else {
        Toast(response.message.description, "success");
        next();
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
    }
  };
  const handlePrint = () => {
    window.print();
  };
  return (
    <>
      <Formik initialValues={details}>
        {() => {
          return (
            <>
              {loading && (
                <div
                  className="spinner-center"
                  style={{ top: "0%", left: "0%", zIndex: 2000 }}
                >
                  <LoaderComponent />
                </div>
              )}
              <div className="container">
                <div className="row">
                  <div className="col-3">
                    <label className="form-label">Last Ref</label>
                    <Field
                      type="text"
                      placeholder="Ref Id"
                      name="lastRef"
                      value={details?.lastRef}
                      disabled={true}
                      style={{
                        padding: "10px",
                        borderRadius: "7px",
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <label className="form-label">Date & Time</label>

                    <div
                      style={{
                        padding: "11px",
                        border: "2px solid #b4b4b7",
                        borderRadius: "8px",
                        background: "#f4f4f8",
                      }}
                    >
                      {formattedDateTime}
                    </div>
                  </div>
                  <div className="col-3">
                    <label className="form-label">Device ID</label>
                    <Field
                      type="text"
                      placeholder="Device ID"
                      name="deviceId"
                      value={details?.deviceId}
                      disabled={true}
                      style={{
                        padding: "10px",
                        borderRadius: "7px",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row"></div>
              </div>

              <div className="container mt-5 pb-5">
                <div className="row">
                  <div className="col-md">
                    <h6 className="text-info">Physical Cash Count</h6>
                    <div className="row mt-3 d-flex justify-content-start  align-items-start">
                      <div className="col-md">
                        <div className="text-left">Denom</div>
                      </div>
                      <div className="col-md">
                        <div className="text-left">Bill Count</div>
                      </div>

                      <div className="col-md">
                        <div className="text-left">Total</div>
                      </div>
                    </div>
                    <AddCassettesConfiguration
                      rowsData={[citCashLoadData]}
                      isCassetteType={false}
                      isReview={true}
                    />
                    <div className="row d-flex justify-content-center mt-4">
                      <div className="col-8 ">
                        <h5 className="text-right">
                          Total Cash Load:
                          {formatAmount(details?.citCashLoadTotalAmount)}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5 d-flex justify-space-between align-items-end">
                  <div className="col-md">
                    <button className="btn btn-secondary" onClick={prev}>
                      Back
                    </button>
                  </div>
                  <div className="col-md text-right">
                    {/* <button
                      className="btn btn-secondary ml-2"
                      onClick={handlePrint}
                    >
                      Print
                    </button> */}
                    <button
                      className="btn btn-info ml-2"
                      onClick={() => handleSaveAndPerform(false)}
                    >
                      Submit for Review
                    </button>{" "}
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default Review;
