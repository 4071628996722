import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { ErrorMessage, Field, Formik } from "formik";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import NewFilter from "../../../components/FilterDropdown/NewFilter";
import axiosInstance from "../../../utils/axiosInstance";
import Toast from "../../../components/Toast/Toast";
import * as Yup from "yup";

const AddUpdateReconAccount = ({
  showModal,
  setShowModal,
  selectedAccountID,
  setSelectedAccount,
  getAccounts,
}) => {
  const [accountTypes, setAccountTypes] = useState([]);
  const [sourceAccounts, setSourceAccounts] = useState([]);
  const [relationTypes, setRelationTypes] = useState([]);
  const [showRelationship, setShowRelationship] = useState(false);
  const [formValues, setFormValues] = useState({
    id: null,
    name: "",
    accountNumber: "",
    description: "",
    accountType: "",
    currency: "",
    activeInd: "true",
    associatedAccounts: [
      { id: null, associatedAccountId: "", relationshipType: "" },
    ],
    financialInstitution: "",
    iban: "",
    bic: "",
  });
  const formValidation = Yup.object().shape({
    name: Yup.string()
      .required("Required*")
      .typeError("Required*")
      .trim()
      .matches(/^\S/, "Account Name cannot consist of only blank spaces"),
    accountType: Yup.string().required("Select Account Type"),
    activeInd: Yup.string().required("Required*").typeError("Required*"),
    currency: Yup.string()
      .optional()
      .trim()
      .matches(/^\S/, "Currency cannot consist of only blank spaces")
      .nullable(),
    accountNumber: Yup.string()
      .optional()
      .trim()
      .matches(/^\S/, "Account Number cannot consist of only blank spaces")
      .nullable(),
    // associatedAccounts: Yup.array().of(
    //   Yup.object().shape({
    //     associatedAccountId: Yup.string()
    //       .required("Required*")
    //       .typeError("Required*"),
    //     relationshipType: Yup.string()
    //       .required("Required*")
    //       .typeError("Required*"),
    //   })
    // ),
  });
  const getRelationshipsType = async () => {
    try {
      axiosInstance.get("/common/code?codeTypeId=3004").then((res) => {
        setRelationTypes(
          res?.data?.map((i) => {
            return {
              label: i?.description,
              value: i?.name,
              codeValue: i?.codeValue,
            };
          })
        );
      });
    } catch (e) {
      console.log(e);
    }
  };
  const getAccountsIndicator = async () => {
    try {
      axiosInstance
        .get("/accounts/account-types?typeIndicator=recon")
        .then((res) => {
          let indicator = res?.data?.map((i) => {
            return {
              label: i?.accountType,
              value: i?.accountId,
              showAccountFields: i?.showAccountFields,
              showStatementFields: i?.showStatementFields,
            };
          });
          setAccountTypes(indicator);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const getAssociatedAccounts = async () => {
    try {
      axiosInstance
        .get("/accounts/account-list?acountId&typeIndicator=gl")
        .then((res) => {
          setSourceAccounts(
            res?.data?.accounts?.map((i) => {
              return {
                label: i?.name,
                value: i?.id,
              };
            })
          );
        });
    } catch (e) {
      console.log(e);
    }
  };
  const getSingleAccount = useCallback(async () => {
    try {
      axiosInstance.get(`/accounts/${selectedAccountID}`).then((res) => {
        setFormValues({
          ...res?.data,
          associatedAccounts: res?.data?.associatedAccounts
            ? res?.data?.associatedAccounts
            : [{ id: null, associatedAccountId: "", relationshipType: "" }],
          relationshipType:
            res?.data?.associatedAccounts?.length > 0
              ? res?.data?.associatedAccounts[0]?.relationshipType
              : "",
          activeInd:
            res?.data?.activeInd !== null
              ? res?.data?.activeInd === true
                ? "true"
                : "false"
              : "",
        });
      });
    } catch (e) {
      console.log(e);
    }
  }, [selectedAccountID]);

  const addUpdateAccount = (values) => {
    const associatedAccounts = values.associatedAccounts
      ?.map((i) => {
        if (i?.associatedAccountId !== "" || i?.relationshipType !== "")
          return {
            ...i,
            relationshipType: relationTypes?.find(
              (item) => item?.value === i?.relationshipType
            )?.codeValue,
          };
        else {
          return null;
        }
      })
      ?.filter((i) => i !== null);
    const payload = {
      data: {
        id: selectedAccountID ? values?.id : null,
        accountType: values?.accountType,
        name: values?.name,
        accountNumber: accountTypes?.find(
          (option) => option?.value === values?.accountType
        )?.showAccountFields
          ? values?.accountNumber
          : null,
        description: values?.description,
        currency: accountTypes?.find(
          (option) => option?.value === values?.accountType
        )?.showAccountFields
          ? values?.currency
          : null,
        activeInd: values?.activeInd.toString() === "true" ? true : false,
        associatedAccounts: associatedAccounts,
        financialInstitution: accountTypes?.find(
          (option) => option?.value === values?.accountType
        )?.showStatementFields
          ? values?.financialInstitution
          : null,
        iban: accountTypes?.find(
          (option) => option?.value === values?.accountType
        )?.showStatementFields
          ? values?.iban
          : null,
        bic: accountTypes?.find(
          (option) => option?.value === values?.accountType
        )?.showStatementFields
          ? values?.bic
          : null,
      },
    };
    selectedAccountID
      ? axiosInstance
          .put(`/accounts/${values?.id}`, payload)
          .then((res) => {
            if (res.message.status !== "200") {
              Toast(res.message.description, "error");
            } else {
              Toast("Recon Account Updated Successfully", "success");
              setFormValues({
                id: null,
                name: "",
                accountNumber: "",
                description: "",
                accountType: "",
                currency: "",
                activeInd: "true",
                associatedAccounts: [],
                financialInstitution: "",
                iban: "",
                bic: "",
              });
              setSelectedAccount(null);
              setShowModal(false);
              getAccounts();
            }
          })
          .catch((err) => {
            Toast(err.response.data.message.description, "error");
          })
      : axiosInstance
          .post("/accounts", payload)
          .then((res) => {
            if (res.message.status !== "200") {
              Toast(res.message.description, "error");
            } else {
              Toast("Recon Account Added Successfully", "success");
              setFormValues({
                id: null,
                name: "",
                accountNumber: "",
                description: "",
                accountType: "",
                currency: "",
                activeInd: "true",
                associatedAccounts: [],
                financialInstitution: "",
                iban: "",
                bic: "",
              });
              setSelectedAccount(null);
              setShowModal(false);
              getAccounts();
            }
          })
          .catch((err) => {
            Toast(err.response.data.message.description, "error");
          });
  };

  useState(() => {
    getRelationshipsType();
    getAccountsIndicator();
    getAssociatedAccounts();
  }, []);
  useEffect(() => {
    if (showModal && selectedAccountID) {
      getSingleAccount();
    }
  }, [showModal, selectedAccountID, getSingleAccount]);
  useEffect(() => {
    if (
      formValues?.associatedAccounts?.length > 0 &&
      formValues?.associatedAccounts[0]?.relationshipType &&
      formValues?.associatedAccounts[0]?.associatedAccountId
    )
      return setShowRelationship(true);
  }, [formValues]);
  return (
    <Modal
      backdrop={true}
      size="lg"
      show={showModal}
      onHide={() => {
        setSelectedAccount(null);
        setShowModal(false);
        setFormValues({
          id: null,
          name: "",
          accountNumber: "",
          description: "",
          accountType: "",
          currency: "",
          activeInd: "true",
          associatedAccounts: [],
          financialInstitution: "",
          iban: "",
          bic: "",
        });
      }}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <h3 className=" ">
          {selectedAccountID ? "Edit Recon Account" : "Add Recon Account"}
        </h3>
      </Modal.Header>
      <Modal.Body scrollable="true">
        <>
          <Formik
            initialValues={formValues}
            enableReinitialize={true}
            validationSchema={formValidation}
            onSubmit={(values) => {
              addUpdateAccount(values);
            }}
          >
            {({ values, errors, setFieldValue, handleSubmit }) => (
              <Form id="add-account" className="form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-5">
                    <div className="workflowModalTitle">Account Type</div>
                    <Select
                      id="accountType"
                      options={accountTypes}
                      name="Account Type"
                      classNamePrefix={"automate"}
                      value={
                        accountTypes
                          ? accountTypes?.find(
                              (option) => option?.value === values?.accountType
                            )
                          : ""
                      }
                      onChange={(e) => setFieldValue("accountType", e.value)}
                      placeholder="Select Account Type"
                    />
                    <ErrorMessage
                      component="div"
                      name="accountType"
                      className="field-error"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-5 mt-3">
                    <div className="workflowModalTitle">Account Name</div>
                    <Field
                      id="accountName"
                      className="form-control rs-input"
                      type="text"
                      placeholder="Account Name"
                      name="name"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      component="div"
                      name="name"
                      className="field-error"
                    />
                  </div>
                  {accountTypes?.find(
                    (option) => option?.value === values?.accountType
                  )?.showAccountFields ?? true ? (
                    <div className="col-5 mt-3">
                      <div className="workflowModalTitle">Account Number</div>
                      <Field
                        id="accountNumber"
                        className="form-control rs-input"
                        type="text"
                        placeholder="Account No"
                        name="accountNumber"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        component="div"
                        name="accountNumber"
                        className="field-error"
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <div className="col-5 mt-3">
                    <div className="workflowModalTitle">Description</div>
                    <Field
                      id="description"
                      className="form-control rs-input"
                      type="text"
                      placeholder="Description"
                      name="description"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      component="div"
                      name="description"
                      className="field-error"
                    />
                  </div>
                  {accountTypes?.find(
                    (option) => option?.value === values?.accountType
                  )?.showAccountFields ?? true ? (
                    <div className="col-5 mt-3">
                      <div className="workflowModalTitle">Currency</div>
                      <Field
                        id="currency"
                        className="form-control rs-input"
                        type="text"
                        placeholder="Currency"
                        name="currency"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        component="div"
                        name="currency"
                        className="field-error"
                      />
                    </div>
                  ) : null}
                  {accountTypes?.find(
                    (option) => option?.value === values?.accountType
                  )?.showStatementFields ?? true ? (
                    <div className="col-5 mt-3">
                      <div className="workflowModalTitle">
                        Financial Institution
                      </div>
                      <Field
                        className="form-control rs-input"
                        type="text"
                        placeholder="Financial Institution"
                        name="financialInstitution"
                        autoComplete="off"
                      />
                    </div>
                  ) : null}
                  {accountTypes?.find(
                    (option) => option?.value === values?.accountType
                  )?.showStatementFields ?? true ? (
                    <div className="col-5 mt-3">
                      <div className="workflowModalTitle">IBAN</div>
                      <Field
                        className="form-control rs-input"
                        type="text"
                        placeholder="IBAN"
                        name="iban"
                        autoComplete="off"
                      />
                    </div>
                  ) : null}
                  {accountTypes?.find(
                    (option) => option?.value === values?.accountType
                  )?.showStatementFields ?? true ? (
                    <div className="col-5 mt-3">
                      <div className="workflowModalTitle">BIC</div>
                      <Field
                        className="form-control rs-input"
                        type="text"
                        placeholder="BIC"
                        name="bic"
                        autoComplete="off"
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <div className="col-5 mt-3">
                    <a
                      id="relationshipTypeBtn"
                      className="workflowModalTitle"
                      style={{
                        cursor: "pointer",
                        fontSize: "13px",
                        textDecoration: "none",
                      }}
                      onClick={() => setShowRelationship(!showRelationship)}
                      aria-controls="example-collapse-text"
                      aria-expanded={showRelationship}
                    >
                      {showRelationship ? (
                        <i className="fa fa-minus"></i>
                      ) : (
                        <i className="fa fa-plus"></i>
                      )}{" "}
                      {""} Add Relationship Type
                    </a>
                  </div>
                </div>
                <Collapse
                  in={showRelationship ? true : false}
                  dimension="height"
                  className="col-12"
                >
                  <div id="example-collapse-text" className="p-0 m-0">
                    {values?.associatedAccounts?.map((i, index) => (
                      <div className="row" key={index}>
                        <div className="col-5 mt-3">
                          <div className="workflowModalTitle">
                            Relationship Type
                          </div>
                          <Select
                            id="relationshipType"
                            options={relationTypes}
                            name="Relationship Type"
                            classNamePrefix={"automate1"}
                            value={
                              relationTypes && i?.relationshipType !== ""
                                ? relationTypes?.find(
                                    (option) =>
                                      option?.value === i?.relationshipType
                                  )
                                : ""
                            }
                            onChange={(e) =>
                              setFieldValue(
                                `associatedAccounts.${index}.relationshipType`,
                                e.value
                              )
                            }
                            placeholder="Select Relationship Type"
                          />
                          <ErrorMessage
                            component="div"
                            name={`associatedAccounts.${index}.relationshipType`}
                            className="field-error"
                          />
                        </div>
                        <div className="col-5 mt-3">
                          <div className="workflowModalTitle">
                            Associated Account
                          </div>
                          <Select
                            id="associatedAccountId"
                            options={sourceAccounts}
                            name="Associated Account"
                            classNamePrefix={"automate2"}
                            value={
                              sourceAccounts && i?.associatedAccountId !== ""
                                ? sourceAccounts?.find(
                                    (option) =>
                                      option?.value === i?.associatedAccountId
                                  )
                                : ""
                            }
                            onChange={(e) => {
                              setFieldValue(
                                `associatedAccounts.${index}.id`,

                                i?.id ?? null
                              );
                              setFieldValue(
                                `associatedAccounts.${index}.associatedAccountId`,

                                e.value
                              );
                            }}
                            placeholder="Select Associated Accounts"
                            isSearchable={true}
                          />
                          <ErrorMessage
                            component="div"
                            name={`associatedAccounts.${index}.associatedAccountId`}
                            className="field-error"
                          />
                        </div>
                        <div className="col-2 mt-3 d-flex justify-content-center align-items-center pt-3">
                          <div
                            className="pl-2 pr-2"
                            style={{ display: index == 0 ? "block" : "none" }}
                          >
                            <button
                              className="btn btn-outline-success"
                              type="button"
                              onClick={() =>
                                setFieldValue(`associatedAccounts`, [
                                  ...values.associatedAccounts,
                                  {
                                    id: null,
                                    associatedAccountId: "",
                                    relationshipType: "",
                                  },
                                ])
                              }
                            >
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </div>

                          <div className={index == 0 ? `pl-1 pr-2` : `pl-5`}>
                            <button
                              className="btn btn-outline-danger"
                              type="button"
                              onClick={() => {
                                setFieldValue(
                                  `associatedAccounts`,
                                  values?.associatedAccounts?.length > 1
                                    ? values.associatedAccounts
                                        .slice(0, index)
                                        .concat(
                                          values.associatedAccounts.slice(
                                            index + 1
                                          )
                                        )
                                    : [
                                        {
                                          id: null,
                                          associatedAccountId: "",
                                          relationshipType: "",
                                        },
                                      ]
                                );
                              }}
                            >
                              <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Collapse>
                <div className="row">
                  <div className="col-5 mt-3">
                    <div className="d-flex flex-column mt-2">
                      <div className="workflowModalTitle">
                        Select Active/Inactive
                      </div>
                      <Field
                        id="activeIndicator"
                        name="activeInd"
                        autocomplete="off"
                        render={({ field }) => (
                          <div className="d-flex">
                            <div className="form-check pl-1">
                              <input
                                {...field}
                                id="active"
                                value="true"
                                checked={field?.value?.toString() === "true"}
                                name="activeInd"
                                type="radio"
                              />
                              <label
                                className="form-check-label ml-1"
                                htmlFor="active"
                              >
                                Active
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                {...field}
                                id="inactive"
                                value="false"
                                name="activeInd"
                                checked={field?.value?.toString() === "false"}
                                type="radio"
                              />
                              <label
                                className="form-check-label ml-1"
                                htmlFor="inactive"
                              >
                                Inactive
                              </label>
                            </div>
                          </div>
                        )}
                      />
                      <ErrorMessage
                        component="div"
                        name="activeInd"
                        className="field-error"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <ButtonBasic
                    id="recon-account-cancel"
                    title="Cancel"
                    onClick={() => {
                      setFormValues({
                        id: null,
                        name: "",
                        accountNumber: "",
                        description: "",
                        accountType: "",
                        currency: "",
                        activeInd: "true",
                        associatedAccounts: [],
                      });
                      setSelectedAccount(null);
                      setShowModal(false);
                    }}
                  />
                  <ButtonBasic
                    id="recon-account"
                    title={selectedAccountID ? "Update" : "Add"}
                    onClick={handleSubmit}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default AddUpdateReconAccount;
