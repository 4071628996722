import { Field } from "formik";
import CurrencyFormat from "react-currency-format";
import { useDateTime } from "../../../components/Helper/DateTime";

const AddCassettesConfiguration = ({
  rowsData,
  // deleteTableRows,
  handleChange,
  isCassetteType,
  isReview,
  errors,
  isDisabled,
}) => {
  const { currency } = useDateTime();

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      `${currency} 0`
    );
  return rowsData?.map((data, index) => {
    const { billCount, denomination, cassetteType, isNewRow } = data;
    return (
      <div
        key={index}
        className="row mt-3 d-flex justify-content-start  align-items-start"
      >
        <div className="col-md">
          {index === 0 && (
            <div>
              <div className="text-left">Denomination</div>
            </div>
          )}
          <Field
            className="form-control rs-input"
            type="number"
            placeholder=""
            name="denomination"
            value={denomination}
            onChange={(evnt) => handleChange(index, evnt)}
            disabled={isDisabled || !isNewRow || !!isReview} // Disable for prefilled rows and when in review mode
          />
        </div>
        <div className="col-md">
          {index === 0 && (
            <div>
              <div className="text-left">Bill Count</div>
            </div>
          )}
          <Field
            type="number"
            className={`form-control rs-input`}
            placeholder=""
            id="billCount"
            name="billCount"
            value={billCount}
            onChange={(evnt) => {
              handleChange(index, evnt);
            }}
            disabled={isDisabled || !!isReview}
          />
          {errors?.billCount && (
            <div className="field-error">{errors?.billCount[index]}</div>
          )}
        </div>
        {isCassetteType && (
          <div className="col-md">
            {index === 0 && (
              <div>
                <div className="text-left">Cassette Type</div>
              </div>
            )}
            <Field
              className="form-control rs-input"
              type="text"
              placeholder=""
              name="cassetteType"
              value={cassetteType}
              onChange={(evnt) => handleChange(index, evnt)}
              disabled={isDisabled || !!isReview || !isNewRow}
            />
          </div>
        )}
        <div className="col-md">
        {index === 0 && (
              <div>
                <div className="text-left">Total Amount</div>
              </div>
            )}
          <p className="text-left">{formatAmount(denomination * billCount)}</p>
        </div>
      </div>
    );
  });
};

export default AddCassettesConfiguration;
