import React, { useState, useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import queryString from "query-string";
import axiosInstance from "../../utils/axiosInstance";
import Pagination from "../../components/pagination/pagination";
import BadgeCircle from "../../components/BadgeCircle/BadgeCircle";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import FilterIcon from "../../assets/images/filter.png";
import TransactionlogModal from "./TransactionlogModal";
import SuspiciousModal from "./SuspiciousModal";
import Filter from "../../components/FilterDropdown/GenericFilter";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import doneIcon from "../../assets/images/done.png";
import CurrencyFormat from "react-currency-format";
import { useDateTime } from "../../components/Helper/DateTime";
import Toast from "../../components/Toast/Toast";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import {
  mapFilters,
  handleFilterChange,
  filtersParams,
  handleNewFilter,
} from "../../utils/filtersHelper";
import { DateTimeFormat } from "../../utils/formatDateTime";

const TransactionLog = (props) => {
  const { dateTime, currency, presistState, setPresistState, setRefresh } =
    useDateTime();
  //if user redirect from frontend side then set previous data
  const [suspeciousData] = useState(
    props.location?.state?.suspeciousData
      ? props.location?.state?.suspeciousData
      : presistState?.route
      ? presistState?.suspeciousData
      : null
  );

  const [transactionData, setTransactionData] = useState([]);
  const [data, setData] = useState({});
  const [dataSources, setDataSources] = useState([]);
  const [loading, setLoading] = useState(true);
    //if user redirect from Matching to Tlog using frontend side then set previous data
  const [page, setPage] = useState(
    presistState?.route ? presistState?.transactionLogData?.page : 1
  );
  const [pageSize, setPageSize] = useState(
    presistState?.route ? presistState?.transactionLogData?.pageSize : 15
  );
  const [totalPages, setTotalPages] = useState(
    presistState?.route ? presistState?.transactionLogData?.totalPages : 0
  );
  const [totalElements, setTotalElements] = useState(
    presistState?.route ? presistState?.transactionLogData?.totalElements : 0
  );
  const [showModal, setShowModal] = useState(false);
  const [showSuspiciousModal, setShowSuspiciousModal] = useState(false);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [filter, setFilter] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [showFilters, setShowFilters] = useState([]);
  const [id, setId] = useState("");
  const [selectedAllRows, setSelectedAllRows] = useState([]);
  const [disabledAdjBtn, setDisabledAdjBtn] = useState(false);
  const [warning, setWarning] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearch, setTempSearch] = useState("");
  const [breadCrumbState] = useState([
    {
      id: 0,
      title: "ATM Cash Balancing",
      isActive: false,
      routeTo: "/atm-cash-balancing",
    },
    {
      id: 1,
      title: "Suspicious Transactions",
      isActive: true,
      routeTo: "/",
    },
  ]);

  const [matchedColumns, setMatchedColumns] = useState([]);
  const handleOnSelectRow = (row, isSelect) => {
    // Clone the selected rows array to avoid mutating state directly
    const newSelection = [...selectedAllRows];

    if (isSelect) {
      // Add the selected row to the array
      newSelection.push(row);
    } else {
      // Remove the unselected row from the array
      const index = newSelection.findIndex((r) => r.id === row.id);
      newSelection.splice(index, 1);
    }

    const uniqueStatusValues = [
      ...new Set(newSelection.map((item) => item.inReview)),
    ];

    const hasProcessed = uniqueStatusValues.includes("PROCESSED");
    const hasInReview = uniqueStatusValues.includes("INREVIEW");

    // Toggle the button state based on the presence of 'PROCESSED' or 'INREVIEW' rows
    setDisabledAdjBtn(hasProcessed || hasInReview);

    // Show warning for the selected row only
    setWarning(
      isSelect && (row.inReview === "PROCESSED" || row.inReview === "INREVIEW")
    );

    // Update the selected rows state
    setSelectedAllRows(newSelection);
  };

  const handleOnSelectAllRows = (isSelect, rows) => {
    const newSelection = isSelect ? rows : [];

    const hasProcessedOrInReviewRows = newSelection.some(
      (item) => item.inReview === "PROCESSED" || item.inReview === "INREVIEW"
    );

    setSelectedAllRows(newSelection);

    if (hasProcessedOrInReviewRows) {
      setWarning(true);
      setDisabledAdjBtn(true);
    } else {
      setWarning(false);
      setDisabledAdjBtn(false);
    }
  };

  const rowEvent = {
    onClick: (e, row) => {
      setShowModal(true);
      setId(row.id);
    },
  };
  const selectRow = {
    mode: "checkbox",
    // clickToSelect: true,
    // selected: selectedAllRows,
    onSelect: handleOnSelectRow,
    onSelectAll: transactionData?.length > 0 && handleOnSelectAllRows,
  };

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const fetchTransactionData = useCallback(
    (filter, flag) => {
      setLoading(true);
      let allFilters = filtersParams(filter);
      const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;

      let urlATMSuspicious = `/transactions/atm-suspicious?page=${
        flag ? 0 : page - 1
      }&size=${pageSize}${allFilters}&terminalId=${
        suspeciousData?.terminalId
      }&channel=${suspeciousData?.channel}&balancingSummaryId=${
        suspeciousData?.balancingSummaryId
      }&business_date=${suspeciousData?.business_date}${
        sort.sort_by && sort.sort_order ? order : ""
      }`;

      let urlTL = `/transactions?page=${
        flag ? 0 : page - 1
      }&size=${pageSize}${allFilters}${
        sort.sort_by && sort.sort_order ? order : ""
      }${searchQuery !== "" ? "&search=" + searchQuery : ""}`;

      axiosInstance
        .get(suspeciousData ? urlATMSuspicious : urlTL)
        .then((res) => {
          if (res.message.status !== "200") {
            setTransactionData([]);
            setTotalElements(0);
            setTotalPages(0);
            setLoading(false);
          } else {
            setTransactionData(res?.data?.transactions || []);
            setData(res?.data || {});
            setPage(res?.page?.number + 1 || 1);
            setTotalPages(res?.page?.totalPages || 0);
            setTotalElements(res?.page?.totalElements || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setTransactionData([]);
          setTotalElements(0);
          setTotalPages(0);
          setLoading(false);
        });
    },
    // eslint-disable-next-line
    [page, pageSize, sort, props.location?.state, searchQuery]
  );

  const getFilters = useCallback(() => {
    setLoading(true);
    let data;
    if (props.location?.state?.data) {
      data = props.location.state.data;
    }
    let screen_id =
      suspeciousData && suspeciousData?.status === "SHORTAGE"
        ? "suspicious_transactions_shortage"
        : suspeciousData && suspeciousData?.status !== "SHORTAGE"
        ? "suspicious_transactions"
        : "transaction_log";
    axiosInstance
      .get(`hierarchical/filters?screen_id=${screen_id}`)
      .then((res) => {
        if (res.message.status !== "200") {
          fetchTransactionData();
          setLoading(false);
        } else {
          const filteredFilters = res.data.filters?.filter(
            (x) => x.showOnScreen
          );
          const showFiltersData = res.data.filters?.filter(
            (x) => !x.showOnScreen
          );
          if (data?.destination) {
            filteredFilters?.push(
              showFiltersData.find((x) => x.filterName === "destination")
            );
          }
          setShowFilters(showFiltersData);
          setFilterData(filteredFilters);
          // if user redirect then setting previous selected filters state
          const obj =
            presistState?.route &&
            presistState?.transactionLogData?.filter &&
            !presistState?.suspeciousData &&
            !presistState?.atmBalancingData
              ? {
                  ...presistState?.transactionLogData?.filter,
                  business_date: {
                    data: new Date(
                      presistState?.transactionLogData?.filter?.business_date?.filter?.[0]
                    ),
                    filter: [
                      presistState?.transactionLogData?.filter?.business_date
                        ?.filter?.[0],
                    ],
                  },
                }
              : mapFilters(filteredFilters);

          //if data is coming from atm balancing when someone click on redirection icon in each row
          if (props?.location?.state?.atmBalancingDataRedirection) {
            let data = props?.location?.state?.atmBalancingDataRedirection;
            if (data.business_date) {
              obj.business_date.data = new Date(data.business_date);
              obj.business_date.filter = [data.business_date]; //date passing in filter api as param.
            }
            if (data?.channel) {
              obj.channel.filter = [data?.channel?.toString()];
            }
            if (data?.terminalId) {
              obj.terminalId.filter = [data?.terminalId?.toString()];
            }
          }
          //end for click on redirection icon in each row

          if (data) {
            if (data.date) {
              obj.business_date.data = new Date(data.date);
              obj.business_date.filter = [data.date]; //date passing in filter api as param.
            }
            if (data.id) {
              obj.matching.filter = [data?.id?.toString()];
            }
            if (data.channel) {
              if (data.channel.length > 0) {
                obj.channel.filter = data?.channel;
              } else {
                obj.channel.filter = [data?.channel?.toString()];
              }
            }
            if (data.destination) {
              obj.destination.filter = [data?.destination?.toString()];
            }
            if (data?.transaction_type) {
              obj.transaction_type.filter = data?.transaction_type;
            }
            //if redirect from ATM dashboard we need to check all matching
            if (data.checkAllMatching === true) {
              obj.matching.filter = obj.matching?.data?.map(
                (item) => item.codeValue
              );
            }
            if (data.bank_host) {
              const updatedData = [...showFiltersData];
              updatedData[0].filterData.forEach((item) => {
                item.param1 = item.label === "Bank Host" ? "1" : "0";
              });
              setShowFilters(updatedData);
            }

            fetchTransactionData(obj, true);
          } else {
            fetchTransactionData(obj);
          }
          setFilter(obj);
           // After settign the filters remove data from presist state and setting route false
          if (
            presistState?.route &&
            presistState?.transactionLogData?.transactionId &&
            !presistState?.suspeciousData
          ) {
            (() => {
              setId(presistState?.transactionLogData?.transactionId);
              setShowModal(true);
              setPresistState(null);
            })();
          } else if (
            presistState?.route &&
            presistState?.transactionLogData?.transactionId &&
            presistState?.suspeciousData
          ) {
            setId(presistState?.transactionLogData?.transactionId);
            setShowModal(true);
            setPresistState({
              ...presistState,
              suspeciousData: null,
              route: null,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [props.location.state]);

  const getResources = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        "/interfaces/datasources?screen=transaction_log"
      );
      if (response.message.status !== "200") {
        setDataSources([]);
        // setLoading(false);
        Toast(response.message.description, "error");
      } else {
        setDataSources(response.data.datasources || []);
        // setLoading(false);
      }
    } catch (err) {
      console.log("err get data source ", err);
      setDataSources([]);
      // setLoading(false);
    }
  };

  useEffect(() => {
    setMatchedColumns(
      !suspeciousData
        ? dataSources?.map((item) => {
            return {
              dataField: "matched In",
              text: item?.datasource,
              formatter: (cell, row, column) =>
                matchInStatus(cell, row, item?.datasource),
              sort: true,
              // style: { marginBottom: "0px" },
            };
          })
        : []
    );
  }, [dataSources]);

  const reconStatus = (row) => (
    <BadgeRounded
      color={
        row === "MATCHED"
          ? "success"
          : row === "MISSING"
          ? "warning"
          : row === "SUGGESTED"
          ? "info"
          : "danger"
      }
      title={row}
      customStyle={{ backgroundColor: row === "ERROR" ? "#D70040" : null }}
    />
  );
  const inReview = (row) => (
    <BadgeRounded
      color={
        row === "PROCESSED"
          ? "success"
          : row === "UNPROCESSED"
          ? "warning"
          : row === "IN REVIEW"
          ? "info"
          : "danger"
      }
      title={row}
    />
  );

  const matchInStatus = (cell, row) => (
    <div className="d-flex justify-content-around">
      {row.matchedIn?.map((item, index) => (
        <BadgeCircle
          key={index}
          icon={doneIcon}
          color={dataSources?.find((x) => x?.id === item)?.colorCode}
        />
      ))}
    </div>
  );
  const formatAmount = (cell, row) => {
    return (
      <>
        <div className="d-flex justify-content-between">
          <span className="item px-3">{currency}</span>
          <span className="item">
            <CurrencyFormat
              value={row?.amount ?? 0}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
            />
          </span>
        </div>
      </>
    );
  };

  const formatSuspiciousAmount = (amount) => {
    return (
      <CurrencyFormat
        value={amount ?? 0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
        decimalScale={2}
      />
    );
  };
  const showTerminalId =
    transactionData?.length > 0 && transactionData[0]?.showTerminalId;

  const columns = [
    { hidden: true, dataField: "id", text: "ID" },

    {
      dataField: "dateTime",
      text: "Date Time",
      formatter: (cell) => DateTimeFormat(dateTime, cell),
      sort: true,
    },
    {
      dataField: "transactionType",
      text: "Transaction",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "pan",
      text: "Card",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: formatAmount,
      sort: true,
      headerAlign: "right",
    },
    {
      dataField: "stan",
      text: "STAN",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "responseCode",
      text: "Response Code",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    // Conditionally add the terminalId column
    ...(showTerminalId
      ? [
          {
            dataField: "terminalId",
            text: "Terminal Id",
            formatter: (cell) => cell ?? "-",
            sort: true,
          },
        ]
      : []),
    {
      dataField: "inReview",
      text: "Balancing State",
      formatter: inReview,
      sort: true,
      hidden: suspeciousData?.balancingSummaryId ? false : true,
    },
    !suspeciousData && {
      dataField: "reconStatus",
      text: "Recon Status",
      formatter: reconStatus,
      sort: true,
    },
    !suspeciousData && {
      dataField: "matchedIn",
      text: "Matched In",
      formatter: matchInStatus,
      sort: true,
    },
  ];

  const headers = [
    {
      label: "Date Time",
      key: "dateTime",
    },
    {
      label: "Transaction",
      key: "transactionType",
    },
    {
      label: "Card",
      key: "pan",
    },
    {
      label: "Amount",
      key: "amount",
    },
    {
      label: "STAN",
      key: "stan",
    },
    {
      label: "Other Ref",
      key: "otherRef",
    },
    {
      label: "Recon Status",
      key: "reconStatus",
    },
  ];

  useEffect(() => {
    getFilters();
    getResources();
  }, [getFilters]);

  useEffect(() => {
    if (filterData.length > 0) fetchTransactionData(filter);
    // eslint-disable-next-line
  }, [fetchTransactionData]);

  useEffect(() => {
    const { id } = queryString.parse(props.location.search);
    if (id) {
      setId(id);
      setShowModal(true);
    }
  }, [props.location.search]);
  useEffect(() => {
    setRefresh(() => () => {
      fetchTransactionData(filter, true);
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, [fetchTransactionData, filter]);

  useEffect(() => {
    if (warning)
      Toast(
        "Can't Adjust transactions with status PROCESSED or INREVIEW",
        "error"
      );
  }, [warning]);

  useEffect(() => {
    if (!presistState?.route) {
      presistState?.atmBalancingData
        ? setPresistState({
            atmBalancingData: presistState.atmBalancingData,
          })
        : setPresistState(null);
    }
  }, []);
  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}

      <div style={{ opacity: !loading ? "1" : "0.07" }} className="text-select">
        {suspeciousData && (
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <Breadcrumbs
                data={breadCrumbState}
                history={props.history}
                onClick={(e) => {
                  setPresistState({
                    ...presistState,
                    route: true,
                  });
                }}
              />
            </div>
          </div>
        )}
        <div className="row align-items-center d-lg-flex">
          {filterData.map(
            ({
              filterName,
              filterType,
              filterLabel,
              showOnScreen,
              filterId,
            }) => (
              <div
                className="filter-item mt-2 ml-3 d-flex"
                // style={{ minWidth: "210px" }}
                key={filterId}
              >
                <Filter
                  id={filterLabel ? filterLabel?.replace(/\s/g, "") : ""}
                  title={filterLabel}
                  filterId={filterId}
                  type={filterType}
                  data={filter[filterName]?.data}
                  filter={filter[filterName]?.filter}
                  removable={!showOnScreen}
                  onChange={(e) =>
                    handleFilterChange(e, filterName, filter, setFilter)
                  }
                  onRemove={(e) =>
                    handleNewFilter(
                      e,
                      filterData,
                      showFilters,
                      filter,
                      setFilter,
                      setFilterData
                    )
                  }
                  isSearchable
                />
              </div>
            )
          )}
          <div className="filter-item mt-2 ml-3">
            <Filter
              title={""}
              type={1006}
              data={showFilters}
              filter={filterData?.map((x) => x.filterId)}
              field="filterId"
              onChange={(e) =>
                handleNewFilter(
                  e,
                  filterData,
                  showFilters,
                  filter,
                  setFilter,
                  setFilterData
                )
              }
            />
          </div>

          <div className="col d-flex justify-content-start mt-2">
            <ButtonBasic
              wrapperClass={"d-flex justify-content-end mr-0"}
              icon={FilterIcon}
              title="Filter"
              onClick={() => {
                fetchTransactionData(filter, true);
              }}
            />
            {selectedAllRows?.length > 0 && suspeciousData && (
              <button
                className="btn btn-info ml-3"
                onClick={() => setShowSuspiciousModal(true)}
                style={{ backgroundColor: "#118AB2" }}
                disabled={disabledAdjBtn ? true : false}
              >
                Adjust Transactions
              </button>
            )}
          </div>
        </div>
        {!suspeciousData && (
          <div className="row mt-5">
            <div className="offset-3 col-9 legend-wrapper d-flex align-items-center mt-sm-2 mt-lg-0 justify-content-end">
              {dataSources?.map((item) => (
                <>
                  <BadgeCircle color={item.colorCode} size="md" />
                  <div className="legend-title color-gray-dim" key={item.id}>
                    {item.datasource}
                  </div>
                </>
              ))}
            </div>
          </div>
        )}
        <div className="row justify-content-between mt-4">
          <div className="col-3 d-flex align-items-center">
            <div className="item title">
              {props?.location.state?.suspeciousData
                ? "Suspicious Transactions"
                : "Transaction Logs"}
            </div>
          </div>
          {!suspeciousData && (
            <div div className="offset-5 col-4">
              <div class="form-group has-search">
                <span class="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search by STAN or Response Code"
                  value={tempSearch}
                  onChange={(e) => {
                    setTempSearch(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setSearchQuery(e.target.value);
                      setPage(1);
                    }
                  }}
                />
              </div>
            </div>
          )}
          {suspeciousData && (
            <div className="col-8 legend-wrapper d-flex align-items-center mt-sm-2 mt-lg-0 justify-content-end">
              <p>
                <strong className="text-info">
                  Device [{suspeciousData?.terminalId}] - Potential Transactions
                  Causing Out-of-Balance
                </strong>{" "}
              </p>
            </div>
          )}
        </div>

        {suspeciousData && (
          <BootstrapTable
            columns={columns}
            bootstrap4={true}
            keyField="id"
            bordered={false}
            classes="rs-table table-layout-auto table-custom-style table-custom-style"
            wrapperClasses="overflow-y-auto fancy-scroll"
            data={transactionData || []}
            rowEvents={rowEvent}
            selectRow={suspeciousData && selectRow}
            remote={{ sort: true }}
            onTableChange={onSortTable}
            noDataIndication={"No data found!"}
          />
        )}
        {/* taking this table because of DOM manipulation. we have to hide this table from DOM */}
        {!suspeciousData && (
          <BootstrapTable
            columns={columns}
            bootstrap4={true}
            keyField="id"
            bordered={false}
            classes="rs-table table-layout-auto table-custom-style table-custom-style"
            wrapperClasses="overflow-y-auto fancy-scroll"
            data={transactionData || []}
            rowEvents={rowEvent}
            // selectRow={suspeciousData && selectRow}
            remote={{ sort: true }}
            onTableChange={onSortTable}
            noDataIndication={"No data found!"}
          />
        )}

        <div className="col-12 mb-3">
          <Pagination
            setPage={setPage}
            page={page}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={totalPages}
            showCSV
            totalElements={totalElements}
            data={transactionData || []}
            CSVHeaders={headers}
            csvName="Transactions Log Data"
          />
          {suspeciousData && (
            <div className="container">
              <div className="row d-flex justify-content-center mr-5">
                <div className="item">
                  <strong style={{ color: "#000" }}>Total Amount: </strong>
                  {formatSuspiciousAmount(data?.totalAmount)}
                </div>
                <div className="item ml-5">
                  <strong style={{ color: "#000" }}>Difference Amount: </strong>
                  {formatSuspiciousAmount(data?.differenceAmount)}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* This modal is shown for transactions log on single row click */}
        {showModal && (
          <div className="col-md-12">
            <TransactionlogModal
              show={showModal}
              onHide={() => setShowModal(false)}
              transactionId={id}
              setShowModal={setShowModal}
              filter={filter}
              pagination={{ page, pageSize, totalPages, totalElements }}
              showMatchButton={true}
              suspeciousData={suspeciousData}
            ></TransactionlogModal>
          </div>
        )}
        {/* This modal is shown for suspicious data when some on land from atm-balancing screen by clicking on suspicious data button */}
        {suspeciousData && (
          <div className="col-md-12">
            <SuspiciousModal
              showSuspiciousModal={showSuspiciousModal}
              selectedAllRows={selectedAllRows}
              setShowSuspiciousModal={setShowSuspiciousModal}
              balancingSummaryId={suspeciousData?.balancingSummaryId}
              deviceId={suspeciousData?.deviceId}
              ReconStatus={suspeciousData?.status}
              fetchTransactionData={fetchTransactionData}
              filter={filter}
              batchId={suspeciousData?.batchId}
              reconAccountId={suspeciousData?.reconAccountId}
            ></SuspiciousModal>
          </div>
        )}
      </div>
    </>
  );
};
export default TransactionLog;
