import React, { useEffect, useState } from "react";
import AddCassettesConfiguration from "./AddCassettesConfiguration";
import { Formik } from "formik";
import CurrencyFormat from "react-currency-format";
import { useDateTime } from "../../../components/Helper/DateTime";
import { Alert, Modal } from "react-bootstrap";
import moment from "moment";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import axiosInstance from "../../../utils/axiosInstance";
import Toast from "../../../components/Toast/Toast";
import BadgeRounded from "../../../components/BadgeRounded/BadgeRounded";

const ATMCashBalancing = ({ showModal, setShowModal, rowData }) => {
  const { currency, dateTime } = useDateTime();
  const convertedDateTime = moment(
    new Date(`${rowData?.date}T${rowData?.time}`)
  );
  const formattedDateTime = convertedDateTime.format(dateTime);
  const [details, setDetails] = useState(null);
  const [totalSwitchTxn, setTotalSwitchTxn] = useState(null);
  const [citCashLoadData, setCitCashLoadData] = useState([]);
  const [citCashUnLoadData, setCitCashUnLoadData] = useState([]);
  const [citRejectedCashUnLoadData, setCitRejectedCashUnLoadData] = useState(
    []
  );
  const [citRetractCashUnLoadData, setCitRetractCashUnLoadData] = useState([]);
  const [ejCashUnLoadData, setEjCashUnLoadData] = useState([]);
  const [ejRejectedCashUnLoadData, setEjRejectedCashUnLoadData] = useState([]);
  const [ejRetractCashUnLoadData, setEjRetractCashUnLoadData] = useState([]);
  const [grandTotal, setGrandTotal] = useState({
    citCashLoadTotal: 0,
    citCashUnLoadTotal: 0,
    ejCashUnLoadTotal: 0,
    citRejectedCashUnLoadTotal: 0,
    citRetractCashUnLoadTotal: 0,
    ejRejectedCashUnLoadTotal: 0,
    ejRetractCashUnLoadTotal: 0,
  });
  const [totalDifference, setTotalDifference] = useState(null);
  const handleChange = (index, evnt, type) => {
    const { name, value } = evnt.target;
    if (type === "citCashLoadData") {
      setCitCashLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    } else if (type === "citCashUnLoadData") {
      setCitCashUnLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    } else if (type === "ejCashUnLoadData") {
      setEjCashUnLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    } else if (type === "citRejectedCashUnLoadData") {
      setCitRejectedCashUnLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    } else if (type === "citRetractCashUnLoadData") {
      setCitRetractCashUnLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    } else if (type === "ejRejectedCashUnLoadData") {
      setEjRejectedCashUnLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    } else if (type === "ejRetractCashUnLoadData") {
      setEjRetractCashUnLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    }
  };

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      `${currency} 0`
    );

  const getDevice = async () => {
    try {
      let response = await axiosInstance.get(
        `/devices/atm-devices?terminal_id=${rowData?.terminalId}`
      );
      if (response.message.status !== "200") {
        setDetails(null);
      } else {
        setDetails(response.data);
      }
    } catch (error) {
      console.log("devices error", error);
    }
  };
  useEffect(() => {
    getDevice();
  }, []);
  const handleSaveAndPerform = async () => {
    let data = {
      deviceId: rowData?.deviceId,
      balancingSummaryId: rowData?.balancingSummaryId,
      dateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      performBalancing: true,
      ejCashUnLoadBillData: ejCashUnLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      ejCashUnLoadRetractedData: ejRetractCashUnLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      ejCashUnLoadRejectedData: ejRejectedCashUnLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      citCashUnLoadBillData: citCashUnLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      citCashUnLoadRetractedData: citRetractCashUnLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      citCashUnLoadRejectedData: citRejectedCashUnLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      citCashLoadData: citCashLoadData?.map((item) => {
        return {
          ...item,
          totalAmount: item?.denomination * item?.billCount,
        };
      }),
      citCashLoadTotalAmount: grandTotal?.citCashLoadTotal,
      ejCashUnLoadTotalAmount:
        grandTotal?.ejCashUnLoadTotal +
        grandTotal?.ejRejectedCashUnLoadTotal +
        grandTotal?.ejRetractCashUnLoadTotal,
      citCashUnLoadTotalAmount:
        grandTotal?.citCashUnLoadTotal +
        grandTotal?.citRejectedCashUnLoadTotal +
        grandTotal?.citRetractCashUnLoadTotal,
      switchTransactionTotal: totalSwitchTxn,
      payload: {
        branchName: details?.[0]?.branchName,
        branchCode: details?.[0]?.branchCode,
        terminalId: rowData?.terminalId,
        grandTotal: {
          ...grandTotal,
          difference: totalDifference,
        },
      },
    };
    try {
      let response = await axiosInstance.post(
        "/devices/atm-cash-replenishment",
        {
          data,
        }
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        Toast(response.message.description, "success");
        setShowModal(false);
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
    }
  };

  useEffect(() => {
    setGrandTotal({
      citCashLoadTotal: citCashLoadData.reduce(
        (acc, curr) => acc + Number(curr.billCount) * Number(curr.denomination),
        0
      ),
      citCashUnLoadTotal: citCashUnLoadData.reduce(
        (acc, curr) => acc + Number(curr.billCount) * Number(curr.denomination),
        0
      ),
      citRejectedCashUnLoadTotal: citRejectedCashUnLoadData.reduce(
        (acc, curr) => acc + Number(curr.billCount) * Number(curr.denomination),
        0
      ),
      citRetractCashUnLoadTotal: citRetractCashUnLoadData.reduce(
        (acc, curr) => acc + Number(curr.billCount) * Number(curr.denomination),
        0
      ),
      ejCashUnLoadTotal: ejCashUnLoadData.reduce(
        (acc, curr) => acc + Number(curr.billCount) * Number(curr.denomination),
        0
      ),
      ejRejectedCashUnLoadTotal: ejRejectedCashUnLoadData.reduce(
        (acc, curr) => acc + Number(curr.billCount) * Number(curr.denomination),
        0
      ),
      ejRetractCashUnLoadTotal: ejRetractCashUnLoadData.reduce(
        (acc, curr) => acc + Number(curr.billCount) * Number(curr.denomination),
        0
      ),
    });
  }, [
    citCashLoadData,
    citCashUnLoadData,
    citRejectedCashUnLoadData,
    citRetractCashUnLoadData,
    ejCashUnLoadData,
    ejRejectedCashUnLoadData,
    ejRetractCashUnLoadData,
  ]);
  useEffect(() => {
    //     Difference formula
    //     cash dispensed by EOD = (total cash loaded previous day - remaining dispensible cash by EOD)
    //     total dispensed = switch total txns + cash dispensed by EOD
    //     difference = (total dispensed + remaining cash) -  total cash loaded previous day
    //     Differnece = 0
    const cashDispensedByEOD =
      grandTotal?.citCashLoadTotal - rowData?.eodEjCashDispensed ?? 0;
    const totalDispensed = Number(totalSwitchTxn ?? 0) + cashDispensedByEOD;
    const totalDifference =
      totalDispensed +
      (grandTotal?.ejCashUnLoadTotal +
        grandTotal?.ejRejectedCashUnLoadTotal +
        grandTotal?.ejRetractCashUnLoadTotal) -
      grandTotal?.citCashLoadTotal;
    setTotalDifference(totalDifference);
  }, [
    grandTotal?.citCashLoadTotal,
    grandTotal?.ejCashUnLoadTotal,
    grandTotal?.ejRejectedCashUnLoadTotal,
    grandTotal?.ejRetractCashUnLoadTotal,
    rowData?.eodEjCashDispensed,
    totalSwitchTxn,
  ]);

  useEffect(() => {
    const citCashLoadDeviceCassettes =
      rowData?.cashReportCashLoad?.map((item) => ({
        denomination: item.denom,
        cassetteType: item.type,
        billCount: item.counts,
      })) || [];
    const citCashUnLoadDataDeviceCassettes =
      details?.[0]?.billCassetteConfig?.map((item) => ({
        denomination: item.denom,
        cassetteType: item.type,
        billCount: "",
      }));
    const citRejectedCashUnLoadDeviceCassettes =
      details?.[0]?.rejectedCassetteConfig?.map((item) => ({
        denomination: item.denom,
        cassetteType: item.type,
        billCount: "",
      }));
    const citRetractCashUnLoadDeviceCassettes =
      details?.[0]?.retractCassetteConfig?.map((item) => ({
        denomination: item.denom,
        cassetteType: item.type,
        billCount: "",
      }));
    const ejCashUnLoadDeviceCassettes = details?.[0]?.billCassetteConfig?.map(
      (item) => ({
        denomination: item.denom,
        cassetteType: item.type,
        billCount: "",
      })
    );
    const ejCashUnLoadRejectedDeviceCassettes =
      details?.[0]?.rejectedCassetteConfig?.map((item) => ({
        denomination: item.denom,
        cassetteType: item.type,
        billCount: "",
      }));
    const ejRetractCashUnLoadDeviceCassettes =
      details?.[0]?.retractCassetteConfig?.map((item) => ({
        denomination: item.denom,
        cassetteType: item.type,
        billCount: "",
      }));

    setEjCashUnLoadData(ejCashUnLoadDeviceCassettes || []);
    setEjRejectedCashUnLoadData(ejCashUnLoadRejectedDeviceCassettes || []);
    setEjRetractCashUnLoadData(ejRetractCashUnLoadDeviceCassettes || []);
    setCitCashLoadData(citCashLoadDeviceCassettes || []);
    setCitCashUnLoadData(citCashUnLoadDataDeviceCassettes || []);
    setCitRejectedCashUnLoadData(citRejectedCashUnLoadDeviceCassettes || []);
    setCitRetractCashUnLoadData(citRetractCashUnLoadDeviceCassettes || []);
  }, [details, rowData?.cashReportCashLoad]);

  const Status = ({ cell }) => (
    <BadgeRounded
      color={
        cell === "CASH BALANCED"
          ? "success"
          : cell === "CASH SHORTAGE"
          ? "danger"
          : ""
      }
      customStyle={
        cell === "CASH EXCESS" ? { backgroundColor: "#007bff" } : null
      }
      title={cell}
    />
  );
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="xl"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>ATM Cash Balancing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Branch Name:</div>
                  <div className="value color-dark">
                    {details?.[0]?.branchName}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">ATM Terminal ID:</div>
                  <div className="value color-dark">{rowData?.terminalId}</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Branch Code:</div>
                  <div className="value color-dark">
                    {details?.[0]?.branchCode}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">
                    Cash Replenishment Date:
                  </div>
                  <div className="value color-dark">{formattedDateTime}</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Cash Balancing Date:</div>
                  <div className="value color-dark">
                    {moment(new Date()).format(dateTime)}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Type of Sheet:</div>
                  <div className="value color-dark">Current/Revised</div>
                </div>
              </div>
            </div>
            <br />
            {citCashLoadData?.length > 0 && (
              <>
                {/* First Section */}
                <section className="border border-dark p-2 py-3">
                  <div className="row">
                    <div className="col-5">
                      <h5 className="text-left text-bold">
                        Cash Added in ATM Previous Day
                      </h5>
                      <div className="text-left pl-5">(Physically Counted)</div>
                    </div>
                    <div className="col-7">
                      <AddCassettesConfiguration
                        rowsData={citCashLoadData}
                        handleChange={(index, evnt) =>
                          handleChange(index, evnt, "citCashLoadData")
                        }
                        isCassetteType={false}
                        isDisabled={true}
                      />
                    </div>
                    <div className="offset-5 col-6 mt-3">
                      <h5 className="text-info text-right">
                        Total: {formatAmount(grandTotal?.citCashLoadTotal)}
                      </h5>
                    </div>
                  </div>
                </section>
              </>
            )}

            {/* Second section */}
            <section className="border border-dark p-2 py-3 mt-3">
              <div className="row">
                <div className="col-5">
                  <h5>Total Remaining Dispensible Cash at last EOD</h5>
                </div>
                <div className="offset-4 col-3">
                  <div className="form-group m-0">
                    <input
                      className="form-control rs-input"
                      value={rowData?.eodEjCashDispensed}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </section>

            {/* Third section */}
            <section className="border border-dark p-2 py-3 mt-3">
              <div className="row">
                <div className="col-5">
                  <h5 className="text-left text-bold">
                    Total Cash Dispensed after EOD. (From RAMS Report)
                  </h5>
                  <p> (From last EOD to Cash Replenishment Date/Time)</p>
                </div>
                <div className="offset-4 col-3">
                  <div className="form-group m-0">
                    <input
                      className="form-control rs-input"
                      placeholder="Total Switch Txn"
                      value={totalSwitchTxn}
                      onChange={(e) => {
                        setTotalSwitchTxn(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>

            {/* Fourth section */}
            {ejCashUnLoadData?.length > 0 && (
              <>
                <section className="border border-dark p-3 mt-3">
                  <div className="row border-bottom border-dark">
                    <div className="col-12">
                      <h4 className="text-center text-bold">
                        Remaining Cash Verification
                      </h4>
                      <p className="text-center">
                        (On Cash Replenishment Date/Time)
                      </p>
                    </div>
                  </div>
                  {/* First row of the section  */}
                  <div className="row d-flex justify-content-center align-items-center mt-3">
                    <div className="col-2">
                      <h6 className="text-left text-bold">Cash in Casettes</h6>
                    </div>
                    <div className="col-5">
                      <h6 className="text-center text-bold text-info">
                        ATM (Scroll)
                      </h6>
                      <AddCassettesConfiguration
                        rowsData={ejCashUnLoadData}
                        handleChange={(index, evnt) =>
                          handleChange(index, evnt, "ejCashUnLoadData")
                        }
                        isCassetteType={false}
                      />
                      <div className="offset-5 col-6 mt-3">
                        <h6 className="text-info text-right">
                          Total: {formatAmount(grandTotal?.ejCashUnLoadTotal)}
                        </h6>
                      </div>
                    </div>

                    {citCashUnLoadData?.length > 0 && (
                      <>
                        <div className="col-5 p-3">
                          <h6 className="text-center text-bold text-info">
                            Physically Counted Cash Found
                          </h6>
                          <AddCassettesConfiguration
                            rowsData={citCashUnLoadData}
                            handleChange={(index, evnt) =>
                              handleChange(index, evnt, "citCashUnLoadData")
                            }
                            isCassetteType={false}
                          />
                          <div className="offset-5 col-6 mt-3">
                            <h6 className="text-info text-right">
                              Total:{" "}
                              {formatAmount(grandTotal?.citCashUnLoadTotal)}
                            </h6>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {/* Second row of the section  */}
                  <div className="row d-flex justify-content-center align-items-center mt-3">
                    <div className="col-2">
                      <h6 className="text-left text-bold">
                        Cash in Reject Bin
                      </h6>
                    </div>

                    {/* cash in Reject bin -> ATM Scroll  */}
                    <div className="col-5">
                      <h6 className="text-center text-bold text-info">
                        ATM (Scroll)
                      </h6>
                      <AddCassettesConfiguration
                        rowsData={ejRejectedCashUnLoadData}
                        handleChange={(index, evnt) =>
                          handleChange(index, evnt, "ejRejectedCashUnLoadData")
                        }
                        isCassetteType={false}
                      />

                      <div className="offset-5 col-6 mt-3">
                        <h6 className="text-info text-right">
                          Total:{" "}
                          {formatAmount(grandTotal?.ejRejectedCashUnLoadTotal)}
                        </h6>
                      </div>
                    </div>

                    {/* cash in Reject bin -> Physically Counted */}
                    <div className="col-5">
                      <h6 className="text-center text-bold text-info">
                        Physically Counted Cash Found
                      </h6>
                      <AddCassettesConfiguration
                        rowsData={citRejectedCashUnLoadData}
                        handleChange={(index, evnt) =>
                          handleChange(index, evnt, "citRejectedCashUnLoadData")
                        }
                        isCassetteType={false}
                      />
                      <div className="offset-5 col-6 mt-3">
                        <h6 className="text-info text-right">
                          Total:{" "}
                          {formatAmount(grandTotal?.citRejectedCashUnLoadTotal)}
                        </h6>
                      </div>
                    </div>
                  </div>

                  {/* Third row of the section */}
                  <div className="row d-flex justify-content-center align-items-center mt-3">
                    <div className="col-2">
                      <h6 className="text-left text-bold">
                        Cash in Retract Bin
                      </h6>
                    </div>

                    {/* cash in retract bin -> ATM Scroll  */}
                    <div className="col-5">
                      <h6 className="text-center text-bold text-info">
                        ATM (Scroll)
                      </h6>
                      <AddCassettesConfiguration
                        rowsData={ejRetractCashUnLoadData}
                        handleChange={(index, evnt) =>
                          handleChange(index, evnt, "ejRetractCashUnLoadData")
                        }
                        isCassetteType={false}
                      />
                      <div className="offset-5 col-6 mt-3">
                        <h6 className="text-info text-right">
                          Total:{" "}
                          {formatAmount(grandTotal?.ejRetractCashUnLoadTotal)}
                        </h6>
                      </div>
                    </div>

                    {/* cash in retract bin -> Physically Counted */}
                    <div className="col-5">
                      <h6 className="text-center text-bold text-info">
                        Physically Counted Cash Found
                      </h6>
                      <AddCassettesConfiguration
                        rowsData={citRetractCashUnLoadData}
                        handleChange={(index, evnt) =>
                          handleChange(index, evnt, "citRetractCashUnLoadData")
                        }
                        isCassetteType={false}
                      />
                      <div className="offset-5 col-6 mt-3">
                        <h6 className="text-info text-right">
                          Total:{" "}
                          {formatAmount(grandTotal?.citRetractCashUnLoadTotal)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}

            {/* Sixth Section */}

            <section className="p-2 py-3 mt-3">
              <div className="row">
                <div className="col-5">
                  <h5 className="text-left text-bold">Physical Cash Found</h5>
                </div>
                <div className="offset-3 col-3">
                  <h5 className="border border-dark text-dark text-center py-2">
                    Total:{" "}
                    {formatAmount(
                      grandTotal?.citCashUnLoadTotal +
                        grandTotal?.citRetractCashUnLoadTotal +
                        grandTotal?.citRejectedCashUnLoadTotal
                    )}
                  </h5>
                </div>
              </div>
            </section>

            {/* Seventh Section */}

            <section className="p-2 py-3 mt-3">
              <div className="row">
                <div className="col-5">
                  <h5 className="text-left text-bold">
                    {" "}
                    Cash Difference between Scroll and Physical Cash Found
                  </h5>
                </div>
                <div className="offset-3 col-3">
                  <h5 className="border border-dark text-dark text-center py-2">
                    Total:{` `}
                    {
                      <CurrencyFormat
                        value={
                          grandTotal?.ejCashUnLoadTotal +
                          grandTotal?.ejRetractCashUnLoadTotal +
                          grandTotal?.ejRejectedCashUnLoadTotal -
                          (grandTotal?.citCashUnLoadTotal +
                            grandTotal?.citRetractCashUnLoadTotal +
                            grandTotal?.citRejectedCashUnLoadTotal)
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </h5>
                </div>
              </div>
            </section>

            {/* Eighth Section */}
            <section className="p-2 py-3 mt-3">
              <div className="row">
                <div className="col-5">
                  <h5 className="text-left text-bold">
                    Cash difference between Cash Loaded - Dispensed by EOD +
                    Physical Cash Found
                  </h5>
                </div>
                <div className="col-2 ">
                  {totalDifference === 0 ? (
                    <Status cell={"CASH BALANCED"} />
                  ) : totalDifference < 0 ? (
                    <Status cell={"CASH SHORTAGE"} />
                  ) : totalDifference > 0 ? (
                    <Status cell={"CASH EXCESS"} />
                  ) : (
                    ""
                  )}
                </div>
                <div className="offset-1 col-3">
                  <h5 className="border border-dark text-dark text-center py-2">
                    Difference:{" "}
                    {
                      <CurrencyFormat
                        value={totalDifference ?? 0}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </h5>
                </div>
              </div>
            </section>
          </div>
        </Formik>
        <Modal.Footer>
          <div className="row">
            <div className=" col-12 d-flex justify-content-end">
              <ButtonBasic title="Cancel" onClick={() => setShowModal(false)} />
              <ButtonBasic
                title="Submit for Review"
                onClick={() => handleSaveAndPerform()}
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default ATMCashBalancing;
