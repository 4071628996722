import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import axiosInstance from "../../utils/axiosInstance";
import Toast from "../../components/Toast/Toast";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import buttonChecker from "../../utils/buttonsPermissionsChecker";
import AddCassettesConfiguration from "../ATMModule/AtmList/atm-replenishment/AddCassettesConfiguration";
import { useDateTime } from "../../components/Helper/DateTime";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import { WorkQueueLogStyle } from "./Styles";
import { CustomTooltip } from "../../utils/helpers/custom-tooltip";
import { DateTimeFormat } from "../../utils/formatDateTime";

const FormSchema = Yup.object().shape({
  comment: Yup.string().min(2, "Too Short!").required("Required*"),
});

const AtmReplenishmentModal = ({
  id,
  handleCloseModal,
  fetchWorkQueue,
  show,
  onHide,
}) => {
  const { currency, dateTime } = useDateTime();
  const [workQueueData, setWorkQueueData] = useState({});
  const [loadingModal, setLoadingModal] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));

  const getWorkQueue = useCallback(async () => {
    setLoadingModal(true);
    try {
      const res = await axiosInstance.get(`/work-queue/work-item?itemId=${id}`);
      if (res.message.status !== "200") {
        setLoadingModal(false);
        Toast(res.message.description, "error");
      } else {
        setWorkQueueData(res?.data);
        setLoadingModal(false);
      }
    } catch (err) {
      setLoadingModal(false);
      console.log("error", err);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getWorkQueue();
    }
  }, [getWorkQueue, id]);

  const addNote = (values) => {
    let now = moment();
    let note = {
      comment: values?.comment,
      author: `${userData?.firstName} ${userData?.lastName}`,
      date: now.format("DD/MM/YY"),
      time: now.hour() + ":" + now.minute(),
    };
    // setComment("");
    let notes = workQueueData?.notes || [];
    notes.push(note);
    setWorkQueueData({ ...workQueueData, notes: notes });
  };

  const handleDone = async (value) => {
    setLoadingModal(true);
    try {
      let response = await axiosInstance.put("/work-queue/work-item", {
        workItemId: id,
        action: value,
        notes: workQueueData?.notes,
        userId: userData?.userId,
      });
      if (response.message.status !== "200") {
        setLoadingModal(false);
        Toast(response.message.description, "error");
      } else {
        handleCloseModal();
        setLoadingModal(false);
        fetchWorkQueue();
        setWorkQueueData(null);
        setShowNotes(false);
      }
    } catch (err) {
      setLoadingModal(false);
      Toast(err.response.data.message.description, "error");
      console.log(err);
    }
  };

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      "0"
    );
  return (
    <>
      <WorkQueueLogStyle>
        <Modal
          backdrop={true}
          size="xl"
          show={show}
          onHide={() => {
            onHide();
            setShowNotes(false);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header className="pb-0" closeButton>
            <Modal.Title id="work-queue-cash-replenishment-flow-title">
              Queue Item: {workQueueData?.deviceId || "Nill"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body scrollable="true">
            {loadingModal && (
              <div
                className="spinner-center"
                style={{ top: "0%", left: "0%", zIndex: 2000 }}
              >
                <LoaderComponent />
              </div>
            )}
            <div className="container">
              <div
                className="row"
                style={{
                  opacity: !loadingModal ? "1" : "0.07",
                }}
              >
                <div
                  className={`${showNotes ? "col-8" : "col-12"} overflow-auto`}
                >
                  <div className="row">
                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="key color-light">Device ID</div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="value  ">
                          {workQueueData?.deviceId || "Nill"}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="key color-light">Last Ref</div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="value  ">
                          {workQueueData?.lastRef || "Nill"}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="key color-light">Raised By</div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="value  ">
                          {workQueueData?.raisedBy || "Nill"}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="key color-light">Authorizer</div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="value  ">
                          {workQueueData?.authorizer || "Nill"}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="key color-light">
                          Replenishment Date And Time
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="value  ">
                          {workQueueData?.dateTime
                            ? DateTimeFormat(dateTime, workQueueData?.dateTime)
                            : "Nill"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {showNotes && (
                  <div className="col-4">
                    <div className="notes-wrapper">
                      <h5 className="mb-3 color-gray-dim">Notes</h5>
                      <div
                        className="overflow-auto fancy-scroll"
                        style={{ height: "7.5em" }}
                      >
                        {workQueueData?.notes?.map(
                          ({ author, comment, date, time }, i) => (
                            <ul className="rs-notes-container" key={i}>
                              <li className="rs-notes-item">
                                <h6>{author}</h6>
                                <div className="note-comment">{comment}</div>
                                <sub>
                                  {date} {time}
                                </sub>
                              </li>
                            </ul>
                          )
                        )}
                      </div>

                      <Formik
                        initialValues={{
                          comment: "",
                        }}
                        validationSchema={FormSchema}
                        onSubmit={(values, { resetForm }) => {
                          addNote(values);
                          resetForm({ values: "" });
                        }}
                      >
                        {({ errors, touched, handleSubmit, handleReset }) => (
                          <Form
                            id="add-notes"
                            className="form"
                            onSubmit={handleSubmit}
                            onReset={handleReset}
                          >
                            <div className="row mt-3">
                              <div className="col-12 mt-3">
                                <div className="d-flex flex-column">
                                  <Field
                                    as="textarea"
                                    className="rs-input"
                                    placeholder="Write a Note"
                                    rows="3"
                                    name="comment"
                                  />
                                  {errors.comment && touched.comment ? (
                                    <div className="field-error">
                                      {errors.comment}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="w-100 mt-2">
                              <ButtonBasic
                                type="submit"
                                classes={"primary px-4 w-100"}
                                title={"Add Note"}
                                onClick={handleSubmit}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                    {!workQueueData?.notes ? (
                      <div className="field-error">
                        Notes field is required incase of Reject/Approve*{" "}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <Formik>
              {() => {
                return (
                  <>
                    <div
                      className="container"
                      style={{
                        opacity: !loadingModal ? "1" : "0.07",
                      }}
                    >
                      {/* cash Load starts */}
                      <div className="container mt-5 pb-5">
                        <h5>Cash Load</h5>
                        <div className="row">
                          <div className="col-md">
                            <h6 className="text-info pt-3">
                              Physical Cash Count
                            </h6>
                            <div className="row mt-3 d-flex justify-content-start  align-items-start">
                              <div className="col-md">
                                <div className="text-left">Denom</div>
                              </div>
                              <div className="col-md">
                                <div className="text-left">Bill Count</div>
                              </div>

                              <div className="col-md">
                                <div className="text-left">Total</div>
                              </div>
                            </div>
                            <AddCassettesConfiguration
                              rowsData={workQueueData?.citCashLoadData || []}
                              isCassetteType={false}
                              isReview={true}
                            />
                            <div className="row d-flex justify-content-center mt-4">
                              <div className="col-8">
                                <h6 className="text-info text-right">
                                  Total Cash Load:
                                  {formatAmount(
                                    workQueueData?.citCashLoadTotalAmount
                                  )}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* {workQueueData?.ejCashUnLoadTotalAmount !==
                        workQueueData?.citCashUnLoadTotalAmount && (
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center ">
                            <Alert variant="warning">
                              {`Warning: Total Cash Unload amounts (EJ Slip & Physical Cash Count ) are not equal!`}
                            </Alert>
                          </div>
                        </div>
                      )} */}

                      {/* {workQueueData &&
                        workQueueData?.ejCashLoadTotalAmount !==
                          workQueueData?.citCashLoadTotalAmount && (
                          <div className="row">
                            <div className="col-12 d-flex justify-content-center ">
                              <Alert variant="danger">
                                {`Error: Total Cash Load amounts (EJ & Physical Cash Count) are not equal. Go Back and correct Total amount values.`}
                              </Alert>
                            </div>
                          </div>
                        )} */}
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-5 mt-3">
                            {workQueueData?.workItemStatus === "APPROVED" ||
                            workQueueData?.workItemStatus === "DECLINED" ? (
                              ""
                            ) : (
                              <div className="alert alert-danger" role="alert">
                                This work item requires approval.
                              </div>
                            )}
                          </div>
                          <div
                            className="offset-sm-3 col-sm-4 text-right mt-3 d-flex"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <ButtonBasic
                              title={showNotes ? "Hide Notes" : "Show Notes"}
                              onClick={() => {
                                setShowNotes(!showNotes);
                                //In Modal Move to Top for Showing Notes Section
                                document
                                  .getElementsByClassName("modal")?.[0]
                                  .scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                              }}
                            />
                            <CustomTooltip
                              placement="top"
                              tooltipId="button-tooltip-2"
                              disabled={
                                !buttonChecker("clk_authorise_work_item") ||
                                workQueueData?.workItemStatus === "APPROVED" ||
                                workQueueData?.workItemStatus === "DECLINED" ||
                                !workQueueData?.notes ||
                                workQueueData?.raisedById === userData?.userId
                              }
                              disabledMessage={
                                workQueueData?.workItemStatus === "APPROVED"
                                  ? "Already Approved"
                                  : workQueueData?.workItemStatus === "DECLINED"
                                  ? "Already Declined"
                                  : workQueueData?.raisedById ===
                                    userData?.userId
                                  ? "You are not allowed to approve or reject the work item"
                                  : !workQueueData?.notes
                                  ? "Add Notes"
                                  : "Permission Denied"
                              }
                              onClick={() => {
                                if (
                                  workQueueData?.notes &&
                                  workQueueData?.notes !== undefined
                                ) {
                                  handleDone(0);
                                }
                              }}
                              buttonText="Reject"
                            >
                              Reject
                            </CustomTooltip>{" "}
                            <CustomTooltip
                              placement="top"
                              tooltipId="button-tooltip-2"
                              disabled={
                                !buttonChecker("clk_authorise_work_item") ||
                                workQueueData?.workItemStatus === "APPROVED" ||
                                workQueueData?.workItemStatus === "DECLINED" ||
                                !workQueueData?.notes ||
                                workQueueData?.raisedById === userData?.userId
                              }
                              disabledMessage={
                                workQueueData?.workItemStatus === "APPROVED"
                                  ? "Already Approved"
                                  : workQueueData?.workItemStatus === "DECLINED"
                                  ? "Already Declined"
                                  : workQueueData?.raisedById ===
                                    userData?.userId
                                  ? "You are not allowed to approve or reject the work item"
                                  : !workQueueData?.notes
                                  ? "Add Notes"
                                  : "Permission Denied"
                              }
                              onClick={() => handleDone(1)}
                              buttonText="Approve"
                            >
                              Approve
                            </CustomTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      </WorkQueueLogStyle>
    </>
  );
};
export default AtmReplenishmentModal;
