import React from "react";
import { Modal } from "react-bootstrap";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import { DateFormat } from "../../utils/formatDateTime";
import { useDateTime } from "../../components/Helper/DateTime";
const NostroViewModal = ({
  showModal,
  setShowModal,
  selectedRow,
  setSelectedRow,
  header,
}) => {
  const { date } = useDateTime();
  const reconcileStatus = (status) => (
    <BadgeRounded
      color={
        status === "MATCHED"
          ? "success"
          : status === "MISSING"
          ? "warning"
          : "danger"
      }
      title={status}
    />
  );
  const formatNumber = (number) => {
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
      });
    }
  };

  return (
    <>
      <div className="col-md-12">
        <Modal
          backdrop={true}
          size="xl"
          dialogClassName="modal-90mw"
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setSelectedRow(null);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>Transaction Details</Modal.Header>
          <Modal.Body scrollable="true">
            <div className="row">
              <div className="col-4">
                <div className="row">
                  <div className="col-sm-12 col-lg-5">
                    <div className="key text-info">Recon Status :</div>
                  </div>
                  <div className="col-sm-12 col-lg-4">
                    <div className="value   ">
                      {reconcileStatus(selectedRow?.status)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row">
                  <div className="col-sm-12 col-lg-5">
                    <div className="key text-info">Match Rule :</div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="value  ">
                      {selectedRow?.matchedBy ?? "N/A"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-12">
                <div className="row mt-4">
                  <div className="col-9">
                    <div
                      className={`table-responsive fancy-scroll`}
                      style={{ overflowY: "hidden" }}
                    >
                      <table className="table table-dim-dark table-bordered table-custom-style m-0">
                        <thead>
                          {selectedRow?.dsA?.length > 0 && (
                            <tr>
                              {header?.map((key, i) => (
                                <th
                                  className={`text-info ${
                                    key === "Balance" ||
                                    key === "Debit" ||
                                    key === "Credit"
                                      ? "text-right"
                                      : ""
                                  }`}
                                  key={i}
                                >
                                  {" "}
                                  {key
                                    .replace(/([a-z])([A-Z])/g, "$1 $2")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                </th>
                              ))}
                            </tr>
                          )}
                        </thead>
                        <tbody>
                          {selectedRow?.dsA
                            ?.filter((item) =>
                              Object.values(item).some(
                                (value) => value !== null
                              )
                            ) // Filter out items with all null values
                            .map((item) => (
                              <tr key={item.id}>
                                {header?.map((key) =>
                                  key === "Balance" ||
                                  key === "Debit" ||
                                  key === "Credit" ? (
                                    <td key={key} className="text-right">
                                      {item[key]
                                        ? formatNumber(item[key])
                                        : null}
                                    </td>
                                  ) : (
                                    <td
                                      key={key}
                                      style={
                                        key === "Description"
                                          ? {
                                              whiteSpace: "normal", // or 'pre-wrap'
                                              wordWrap: "break-word",
                                              minWidth: "322px",
                                            }
                                          : null
                                      }
                                    >
                                      {key === "Posting Date"
                                        ? DateFormat(date, item[key])
                                        : item[key] ?? "-"}
                                    </td>
                                  )
                                )}
                              </tr>
                            ))}

                          <tr>
                            {header?.map((key) => (
                              <td className="p-0">
                                <hr />
                              </td>
                            ))}
                          </tr>

                          {selectedRow?.dsB
                            ?.filter((item) =>
                              Object.values(item).some(
                                (value) => value !== null
                              )
                            ) // Filter out items with all null values
                            .map((item) => (
                              <tr key={item.id}>
                                {header?.map((key) =>
                                  key === "Balance" ||
                                  key === "Debit" ||
                                  key === "Credit" ? (
                                    <td key={key} className="text-right">
                                      {item[key]
                                        ? formatNumber(item[key])
                                        : null}
                                    </td>
                                  ) : (
                                    <td
                                      key={key}
                                      style={
                                        key === "Description"
                                          ? {
                                              whiteSpace: "normal", // or 'pre-wrap'
                                              wordWrap: "break-word",
                                              minWidth: "322px",
                                            }
                                          : null
                                      }
                                    >
                                      {key === "Posting Date"
                                        ? DateFormat(date, item[key])
                                        : item[key] ?? "-"}
                                    </td>
                                  )
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="notes-wrapper" style={{ height: "100%" }}>
                      <h5 className="mb-3 color-gray-dim">Notes</h5>
                      <div
                        className="overflow-auto fancy-scroll"
                        style={{ height: "22em" }}
                      >
                        <div className="mt-2">
                          {selectedRow?.notes?.map(
                            ({ author, comment, date, time }, i) => (
                              <ul className="rs-notes-container" key={i}>
                                <li className="rs-notes-item">
                                  <h6>{author}</h6>
                                  <div className="note-comment">{comment}</div>
                                  <sub>
                                    {date} {time}
                                  </sub>
                                </li>
                              </ul>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <ButtonBasic
                title="Close"
                onClick={() => {
                  setShowModal(false);
                  setSelectedRow(null);
                }}
              />
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default NostroViewModal;
