import React, { useContext, useState, useEffect } from "react";
import { Formik, isEmptyArray } from "formik";
import CurrencyFormat from "react-currency-format";
import "react-datepicker/dist/react-datepicker.css";
import AddCassettesConfiguration from "./AddCassettesConfiguration";
import { useDateTime } from "../../../../components/Helper/DateTime";
import MultiStepFormContext from "./MultiStepFormContext";
import { Alert } from "react-bootstrap";

export const CitCashUnload = () => {
  const {
    citCashUnLoadData,
    ejCashUnLoadData,
    setCitCashUnLoadData,
    citRetractCashUnLoadData,
    setCitRetractCashUnLoadData,
    citRejectedCashUnLoadData,
    setCitRejectedCashUnLoadData,
    next,
    prev,
    devicesData,
    details,
    setDetails,
  } = useContext(MultiStepFormContext);

  const { currency } = useDateTime();
  const [errors, setErrors] = useState({});

  // const addTableRows = () => {
  //   const rowsInput = {
  //     cassetteType: "",
  //     denomination: "",
  //     billCount: "",
  //     isNewRow: true,
  //   };
  //   setCitCashUnLoadData([...citCashUnLoadData, rowsInput]);
  // };

  const deleteTableRows = (index) => {
    const rows = [...citCashUnLoadData];
    rows.splice(index, 1);
    setCitCashUnLoadData(rows);
  };

  const handleChange = (index, evnt, type) => {
    const { name, value } = evnt.target;
    if (value)
      setErrors((prev) => ({
        ...prev,
        billCount: {
          ...prev.billCount,
          [index]: null,
        },
      }));
    if (type === "citCashUnLoadData") {
      setCitCashUnLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    } else if (type === "citRejectedCashUnLoadData") {
      setCitRejectedCashUnLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    } else if (type === "citRetractCashUnLoadData") {
      setCitRetractCashUnLoadData((prevState) => {
        const rowsInput = [...prevState];
        rowsInput[index][name] = value;
        return rowsInput;
      });
    }
  };

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      "0"
    );

  const grandTotal = () => {
    setDetails({
      ...details,
      citCashUnLoadTotalAmount: citCashUnLoadData?.reduce(
        (acc, row) =>
          acc + parseInt(row.denomination ?? 0) * parseInt(row.billCount),
        0
      ),
      citRejectedCashUnLoadTotalAmount: citRejectedCashUnLoadData?.reduce(
        (acc, row) =>
          acc + parseInt(row.denomination ?? 0) * parseInt(row.billCount),
        0
      ),
      citRetractCashUnLoadTotalAmount: citRetractCashUnLoadData?.reduce(
        (acc, row) =>
          acc + parseInt(row.denomination ?? 0) * parseInt(row.billCount),
        0
      ),
    });
  };

  useEffect(() => {
    if (!isEmptyArray(citCashUnLoadData)) {
      return;
    } else {
      const deviceCassettes = devicesData?.[0]?.billCassetteConfig?.map(
        (item) => ({
          denomination: item.denom,
          cassetteType: item.type,
          billCount: "",
        })
      );
      setCitCashUnLoadData(deviceCassettes || []);
    }
    if (!isEmptyArray(citRetractCashUnLoadData)) {
      return;
    } else {
      const deviceCassettes = devicesData?.[0]?.retractCassetteConfig?.map(
        (item) => ({
          denomination: item.denom,
          cassetteType: item.type,
          billCount: "",
        })
      );
      setCitRetractCashUnLoadData(deviceCassettes || []);
    }
    if (!isEmptyArray(citRejectedCashUnLoadData)) {
      return;
    } else {
      const deviceCassettes = devicesData?.[0]?.rejectedCassetteConfig?.map(
        (item) => ({
          denomination: item.denom,
          cassetteType: item.type,
          billCount: "",
        })
      );
      setCitRejectedCashUnLoadData(deviceCassettes || []);
    }
  }, [
    devicesData,
    setCitCashUnLoadData,
    citCashUnLoadData,
    setCitRetractCashUnLoadData,
    citRetractCashUnLoadData,
    setCitRejectedCashUnLoadData,
    citRejectedCashUnLoadData,
  ]);

  useEffect(() => {
    grandTotal();
  }, [citCashUnLoadData, citRejectedCashUnLoadData, citRetractCashUnLoadData]);

  return (
    <Formik
      initialValues={citCashUnLoadData}
      enableReinitialize={true}
      onSubmit={(values) => {
        let error = false;
        values.forEach((el, i) => {
          if (!el.billCount || isNaN(el.billCount)) {
            error = true;
            setErrors((prev) => ({
              ...prev,
              billCount: {
                ...prev.billCount,
                [i]: "Required number",
              },
            }));
          }
        });
        if (!error) {
          setCitCashUnLoadData(values);
          next();
        }
      }}
    >
      {({ handleSubmit, touched }) => {
        return (
          <>
            <div className="container pb-5">
              <div className="row pt-3">
                <h5 className="pl-3">{"Cash Unload > Physical Cash Count"}</h5>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="row mt-3 d-flex justify-content-start  align-items-start">
                    <div className="col-md">
                      <div className="text-left">Denomination</div>
                      {/* <p className={"error__feedback"}>{errors.denomination}</p> */}
                    </div>
                    <div className="col-md">
                      <div className="text-left">Bill Count</div>
                    </div>

                    <div className="col-md">
                      <div className="text-left">Total</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-10">
                  <AddCassettesConfiguration
                    type="UNLOAD_CASH"
                    rowsData={[citCashUnLoadData]}
                    prevRowsData={ejCashUnLoadData}
                    deleteTableRows={deleteTableRows}
                    handleChange={(index, evnt) =>
                      handleChange(index, evnt, "citCashUnLoadData")
                    }
                    isCassetteType={false}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              {/* <div className="row">
                <div className="col-12 mt-5">
                  <div className="text-center">
                    <h5>
                      <strong>
                        Total Cash Unload:{" "}
                        {formatAmount(details?.citCashUnLoadTotalAmount)}
                      </strong>
                    </h5>
                  </div>
                </div>
              </div> */}
              {citRetractCashUnLoadData.length > 0 && (
                <>
                  <div className="row pt-5">
                    <h5 className="pl-3">Retract Bins</h5>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <div className="row mt-3 d-flex justify-content-start  align-items-start">
                        <div className="col-md">
                          <div className="text-left">Denomination</div>
                        </div>
                        <div className="col-md">
                          <div className="text-left">Bill Count</div>
                        </div>
                        <div className="col-md">
                          <div className="text-left">Total</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10">
                      <AddCassettesConfiguration
                        rowsData={[citRetractCashUnLoadData]}
                        deleteTableRows={deleteTableRows}
                        handleChange={(index, evnt) =>
                          handleChange(index, evnt, "citRetractCashUnLoadData")
                        }
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>
                </>
              )}
              {citRejectedCashUnLoadData.length > 0 && (
                <>
                  <div className="row pt-5">
                    <h5 className="pl-3">Reject Bins</h5>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <div className="row mt-3 d-flex justify-content-start  align-items-start">
                        <div className="col-md">
                          <div className="text-left">Denomination</div>
                        </div>
                        <div className="col-md">
                          <div className="text-left">Bill Count</div>
                        </div>
                        <div className="col-md">
                          <div className="text-left">Total</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10">
                      <AddCassettesConfiguration
                        rowsData={[citRejectedCashUnLoadData]}
                        deleteTableRows={deleteTableRows}
                        handleChange={(index, evnt) =>
                          handleChange(index, evnt, "citRejectedCashUnLoadData")
                        }
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>
                </>
              )}
              {(citRejectedCashUnLoadData.length > 0 ||
                citRetractCashUnLoadData.length > 0) && (
                <div className="row">
                  <div className="col-12 mt-5">
                    <div className="text-center">
                      <h5>
                        <strong>
                          Total:{" "}
                          {formatAmount(
                            details?.citRejectedCashUnLoadTotalAmount +
                              details?.citCashUnLoadTotalAmount +
                              details?.citRetractCashUnLoadTotalAmount
                          )}
                        </strong>
                      </h5>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="d-flex">
                  <button className="btn btn-info" onClick={prev}>
                    Back
                  </button>
                  <button className="btn btn-info ml-2" onClick={handleSubmit}>
                    Next
                  </button>
                </div>
              </div>
              {details?.ejCashUnLoadTotalAmount !==
                details?.citCashUnLoadTotalAmount && (
                <div className="row mt-3">
                  <div className="col-12 d-flex justify-content-start">
                    <Alert variant="warning">
                      {`Warning: Total Cash Unload amounts (ATM Scroll & Physical Cash Count ) are not equal!`}
                    </Alert>
                  </div>
                </div>
              )}
            </div>
          </>
        );
      }}
    </Formik>
  );
};
export default CitCashUnload;
