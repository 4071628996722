import React, { useState, useEffect, useCallback, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import axiosInstance from "../../../utils/axiosInstance";
import Pagination from "../../../components/pagination/pagination";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import FilterIcon from "../../../assets/images/filter.png";
import Filter from "../../../components/FilterDropdown/GenericFilter";
import Toast from "../../../components/Toast/Toast";
import BadgeRounded from "../../../components/BadgeRounded/BadgeRounded";
// import CurrencyFormat from "react-currency-format";
import "react-datepicker/dist/react-datepicker.css";
import { useDateTime } from "../../../components/Helper/DateTime";
import { regionCheck, mapdata } from "../../../utils/regionHelper";
import FilterDropdown from "../../../components/FilterDropdown/FilterDropdown";
import {
  filtersParams,
  mapFilters,
  handleFilterChange,
  handleNewFilter,
} from "../../../utils/filtersHelper";
import SuspiciousTransactionModal from "./SuspiciousTransactionModal";

const AtmSuspiciousListing = (props) => {
  const { setRefresh } = useDateTime();

  const [data, setData] = useState([]);
  const [txnData, setTxnData] = useState([]);
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [filter, setFilter] = useState({});
  const [rowData, setRowData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [showFilters, setShowFilters] = useState([]);
  const regionFilter = useRef("");
  const [columns, setColumns] = useState([]);

  const rowTableEvent = {
    onClick: (e, row) => {
      setRowData(row);
      setShowModal(true);
    },
  };
  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const fetchData = useCallback(
    (filter, flag, regionFilters) => {
      setLoading(true);
      let allFilters = filtersParams(filter, regionFilters);
      const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
      axiosInstance
        .get(
          `transactions/suspicious-transactions-details?number=${
            flag ? 0 : page - 1
          }&size=${pageSize}${allFilters}${
            sort.sort_by && sort.sort_order ? order : ""
          }`
        )
        .then((res) => {
          if (res.message.status !== "200") {
            setData([]);
            setTxnData([]);
            setTotalElements(0);
            setLoading(false);
          } else {
            setColumns(
              res?.data?.fields_mapping?.map((item, index) => ({
                ...item,
                key: `column-${index}`,
                align:
                  item?.text?.toLowerCase() === "transaction amount"
                    ? "right"
                    : "left",
                formatter:
                  item?.text?.toLowerCase() === "status"
                    ? reconStatus
                    : item?.text?.toLowerCase() === "transaction amount"
                    ? formatNumber
                    : (cell) => cell || "-",
              })) || []
            );
            setData(res.data?.suspicious_transactions || []);
            setTxnData(
              res.data?.suspicious_transactions?.map((item, index) => ({
                ...item?.txn_data,
                id: item?.id,
                key: `txn-${index}`,
                recon_status: item?.recon_status,
              })) || []
            );
            setPage(res?.page?.number + 1 || 1);
            setTotalPages(res?.page?.totalPages || 0);
            setTotalElements(res?.page?.totalElements);
          }
        })
        .catch((err) => {
          setData([]);
          setLoading(false);
          setTotalElements(0);
        })
        .finally(() => setLoading(false));
    },
    [page, pageSize, sort]
  );

  const getFilters = useCallback(() => {
    setLoading(true);
    let data;
    axiosInstance
      .get("/hierarchical/filters?screen_id=atm_suspicious_transactions")
      .then((res) => {
        if (res?.message?.status !== "200") {
          Toast(res.message.description, "error");
          fetchData();
        } else {
          //Region filter is unique and have different logic. so keeping this logic here
          let regions = res?.data?.filters.filter(
            (item) => item.filterName === "region"
          );
          //other filters on page - same kind of filters with different types like date-picker, radio, single check etc.
          let otherFilters = res?.data?.filters.filter(
            (x) => x.filterName !== "region"
          );

          //check other filters to show in plus button along with filters on screen and whether to show/not
          const filteredFilters = otherFilters?.filter((x) => x.showOnScreen); //filters that are showing on screen
          const showFiltersData = otherFilters?.filter((x) => !x.showOnScreen); //plus button filters

          //two states to keep plus button states and normal filters
          setShowFilters(showFiltersData);
          setFilterData(filteredFilters);

          //it maps filters and check types and which will be checked by default
          let obj = mapFilters(
            res?.data?.filters.filter((x) => x.showOnScreen)
          );

          let filter = "";

          //logic for region select, if receiving in props then will set props regions and will send those ids in filter api as params
          if (regions.length > 0) {
            const region_atm_dash = data?.region_atm_dash ?? null;
            regions[0].filterData =
              region_atm_dash ?? mapdata(regions[0].filterData);
            !region_atm_dash &&
              regions[0].filterData.forEach((x) => {
                x.checked = x.param1 === "1" ? true : false;
              });
            if (region_atm_dash) {
              filter = `&region_id=${region_atm_dash
                .filter((item) => item.checked)
                .map(({ codeValue }) => codeValue)
                .join(",")}`;
              regionFilter.current = filter;
            } else {
              filter = `&region_id=${regions[0]?.filterData
                .map(({ codeValue }) => codeValue)
                .join(",")}`;
              regionFilter.current = filter;
            }
          }

          fetchData(obj, true, filter); //object includes params for api, true is for flag(if true we have to pass 0), filter is for regionFilter
          setRegions(regions); // to keep track of the region filters (if refresh then it does not loss values/checks)
          setFilter(obj); //sets all  filters execpt region filter and plus sign filters.
        }
      })
      .catch((err) => {
        console.log("getting filters error...", err);
        setLoading(false);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [props.location.state]);

  const onRegionDropdownChange = (value) => {
    if (value?.length > 0) {
      regionFilter.current = `&region_id=${value
        .map((node) => node.codeValue)
        .join(",")}`;
    } else {
      regionFilter.current = "";
    }
    const regionData = [...regions];
    regionData[0].filterData = regionCheck(regions[0].filterData, value);
    setRegions(regionData);
  };

  const formatNumber = (number) => {
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
      });
    }
  };

  const reconStatus = (cell) =>
    cell ? (
      <BadgeRounded
        color={
          cell.toLowerCase() === "submitted"
            ? "success"
            : cell.toLowerCase() === "in review"
            ? "warning"
            : cell.toLowerCase() === "review failed"
            ? "danger"
            : "info"
        }
        title={cell}
      />
    ) : (
      "-"
    );

  useEffect(() => {
    if (filterData.length > 0) fetchData(filter, false, regionFilter.current);
    //eslint-disable-next-line
  }, [fetchData]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  useEffect(() => {
    setRefresh(() => () => {
      fetchData(filter, true, regionFilter.current);
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, [fetchData]);

  const userBranch = JSON.parse(localStorage.getItem("userData"))?.branchName;

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div style={{ opacity: !loading ? "1" : "0.07" }}>
        <div className="row mb-2 align-items-center d-xs-none d-lg-flex">
          {regions &&
            regions?.map((region, index) => {
              return (
                <div
                  className="filter-item mt-2 d-flex"
                  key={`region-${index}`}
                >
                  <FilterDropdown
                    title={region.filterLabel}
                    type="checkbox"
                    data={region.filterData}
                    onDropdownChange={(value) => onRegionDropdownChange(value)}
                  />
                </div>
              );
            })}
          {filterData.map(
            ({
              filterName,
              filterType,
              filterLabel,
              showOnScreen,
              filterId,
            }) => (
              <div
                className="filter-item mt-2 ml-3 d-flex"
                key={`filter-${filterId}`}
              >
                <Filter
                  id={filterLabel ? filterLabel?.replace(/\s/g, "") : ""}
                  title={filterLabel}
                  filterId={filterId}
                  type={filterType}
                  data={filter[filterName]?.data}
                  filter={filter[filterName]?.filter}
                  removable={!showOnScreen}
                  onChange={(e) =>
                    handleFilterChange(e, filterName, filter, setFilter)
                  }
                  onRemove={(e) =>
                    handleNewFilter(
                      e,
                      filterData,
                      showFilters,
                      setFilter,
                      setFilterData
                    )
                  }
                />
              </div>
            )
          )}

          <div className="col d-flex justify-content-start mt-2">
            <ButtonBasic
              wrapperClass={"d-flex justify-content-end mr-0"}
              icon={FilterIcon}
              title="Filter"
              onClick={() => {
                setRowData(null);
                fetchData(filter, true, regionFilter.current);
              }}
            />
          </div>
          <div className="d-flex justify-content-end align-items-start mt-2">
            <div style={{ maxWidth: "400px", wordWrap: "break-word" }}>
              <h5
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Branch Name: {userBranch}
              </h5>
              <p
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Selected ATMs: {filter?.terminalId?.filter?.join(", ") || "Nil"}
              </p>
            </div>
          </div>
        </div>

        <div className="row page-header d-flex justify-content-between mt-5">
          <div className="col-sm-12 col-lg-6 page-header d-flex justify-content-between m-0">
            <div className="title">ATM Suspicious Transactions List</div>
          </div>
        </div>

        {columns && columns?.length > 1 ? (
          <>
            <BootstrapTable
              columns={columns?.length > 0 ? columns : []}
              bootstrap4={true}
              keyField="id"
              bordered={true}
              classes="rs-table table-layout-auto"
              wrapperClasses="overflow-y-auto fancy-scroll"
              data={txnData || []}
              rowEvents={rowTableEvent}
              remote={{ sort: true }}
              onTableChange={onSortTable}
              noDataIndication={"No data found!"}
            />
            <div className="row">
              <div className="col-12">
                <Pagination
                  data={data || []}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  totalElements={totalElements}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-12">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td colSpan={columns.length} className="text-center">
                      No data found!
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        <SuspiciousTransactionModal
          showModal={showModal}
          setShowModal={setShowModal}
          rowData={rowData}
        />
      </div>
    </>
  );
};
export default AtmSuspiciousListing;
