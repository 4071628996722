import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Modal } from "react-bootstrap";
import Toast from "../../../components/Toast/Toast";
import axiosInstance from "../../../utils/axiosInstance";
import Select from "react-select";
import * as Yup from "yup";
import DateTimePicker from "../../../components/Helper/DateTimePicker";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import moment from "moment";
const validationSchema = Yup.object({
  branchMarkingDateTime: Yup.date().required("Required"),
  accountDebited: Yup.string().required("Required"),
  statusOnATMScroll: Yup.string().required("Required"),
  cashPosition: Yup.string().required("Required"),
  caseType: Yup.string().required("Required"),
  dispensedAmount: Yup.number().required("Required"),
  retractAmount: Yup.number().required("Required"),
  atmStatus: Yup.string().required("Required"),
});

const SuspiciousTransactionModal = ({ showModal, setShowModal, rowData }) => {
  const initialStateValues = {
    ...rowData,
    branchMarkingDateTime: null,
    accountDebited: "Yes",
    statusOnATMScroll: "",
    cashPosition: "",
    caseType: "",
    dispensedAmount: "",
    retractAmount: "",
    branchRemarks: "",
    atmStatus: "",
  };

  const accountDebitedOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const statusOnATMScrollOptions = [
    { value: "PE", label: "PE - Presenter Error" },
    { value: "CR", label: "CR - Cash Retracted" },
  ];

  const cashPositionOptions = [
    { value: "CE", label: "CE - Cash Excess" },
    { value: "CS", label: "CS - Cash Short" },
    { value: "CB", label: "CB - Cash Balanced" },
  ];

  const caseTypeOptions = [
    { value: "FR", label: "FR - Full Retract" },
    { value: "PR", label: "PR - Partial Retract" },
    { value: "ND", label: "ND - No Dispense" },
  ];

  const atmStatusOptions = [
    { value: "No Dispense", label: "No Dispense" },
    { value: "Partial Dispense", label: "Partial Dispense" },
    { value: "Full Dispense", label: "Full Dispense" },
  ];

  const handleSubmit = async (values) => {
    const body = {
      data: {
        transactionId: values?.id,
        transactionDetail: {
          branch_marking_date_time: moment(
            values?.branchMarkingDateTime
          ).format("YYYY-MM-DD HH:mm:ss"),
          account_debited: false,
          cash_position: values?.cashPosition,
          status_on_atm_scroll: values?.statusOnATMScroll,
          cash_type: values?.caseType,
          dispensed_amount: values?.dispensedAmount,
          retract_amount: values?.retractAmount,
          branch_remarks: values?.branchRemarks,
          atm_status: values?.atmStatus,
        },
        notes: [],
        payload: {},
      },
    };

    try {
      const response = await axiosInstance.post(
        `transactions/submit-suspicious-transaction`,
        body
      );

      if (response.message.status === "200") {
        Toast(response.message.description, "success");
      } else {
        Toast(response.message.description, "error");
      }
    } catch (error) {
      Toast(error.response?.data?.message?.description, "error");
    }
  };

  const formatNumber = (number) => {
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
      });
    }
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body scrollable="true">
        <Formik
          initialValues={initialStateValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Form>
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-12 mb-3">
                  <div className="table-responsive custom-table-border">
                    <table className="table table-bordered table-sm table-striped">
                      <thead className="thead-dark">
                        <tr>
                          <th colSpan={12} className="text-center">
                            Suspect Transaction Details
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="td-bold">STAN</td>
                          <td>{rowData?.stan ?? "-"}</td>
                        </tr>
                        <tr>
                          <td className="td-bold">Reference</td>
                          <td>{rowData?.rrn ?? "-"}</td>
                        </tr>
                        <tr>
                          <td className="td-bold">
                            {" "}
                            Card Acceptor Terminal ID
                          </td>
                          <td>{rowData?.terminal_id ?? "-"}</td>
                        </tr>
                        <tr>
                          <td className="td-bold">Issuer ID</td>
                          <td>{rowData?.posting_date ?? "-"}</td>
                        </tr>
                        <tr>
                          <td className="td-bold"> Response Code</td>
                          <td>{rowData?.response_code ?? "-"}</td>
                        </tr>
                        <tr>
                          <td className="td-bold"> Status Code</td>
                          <td>{rowData?.status_code ?? "-"}</td>
                        </tr>
                        <tr>
                          <td className="td-bold"> Customer Name</td>
                          <td>{rowData?.customer_name ?? "-"}</td>
                        </tr>
                        <tr>
                          <td className="td-bold"> Card Number</td>
                          <td>{rowData?.card_number ?? "-"}</td>
                        </tr>
                        <tr>
                          <td className="td-bold"> Account Number</td>
                          <td>{rowData?.account_number ?? "-"}</td>
                        </tr>
                        <tr>
                          <td className="td-bold">Branch Code</td>
                          <td>{rowData?.branch_code ?? "-"}</td>
                        </tr>
                        <tr>
                          <td className="td-bold">Transaction Date & Time</td>
                          <td>{rowData?.transaction_date_time ?? "-"}</td>
                        </tr>
                        <tr>
                          <td className="td-bold">Transaction Type</td>
                          <td>{rowData?.transaction_type ?? "-"}</td>
                        </tr>
                        <tr>
                          <td className="td-bold">Transaction Amount</td>
                          <td>
                            {rowData?.transaction_amount
                              ? formatNumber(rowData?.transaction_amount)
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="text-info">Branch Marking Date Time</label>
                  <DateTimePicker
                    name="branchMarkingDateTime"
                    futureDateTime={true}
                  />
                  <ErrorMessage
                    name="branchMarkingDateTime"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="accountDebited" className="text-info">
                    Account Debited
                  </label>
                  <Select
                    id="accountDebited"
                    name="accountDebited"
                    options={accountDebitedOptions}
                    onChange={(e) => {
                      setFieldValue("accountDebited", e.value);
                    }}
                  />
                  <ErrorMessage
                    name="accountDebited"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="statusOnATMScroll" className="text-info">
                    Status on ATM Scroll
                  </label>
                  <Select
                    id="statusOnATMScroll"
                    name="statusOnATMScroll"
                    options={statusOnATMScrollOptions}
                    onChange={(e) => {
                      setFieldValue("statusOnATMScroll", e.value);
                    }}
                  />
                  <ErrorMessage
                    name="statusOnATMScroll"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="cashPosition" className="text-info">
                    Cash Position
                  </label>
                  <Select
                    id="cashPosition"
                    name="cashPosition"
                    options={cashPositionOptions}
                    onChange={(e) => {
                      setFieldValue("cashPosition", e.value);
                    }}
                  />
                  <ErrorMessage
                    name="cashPosition"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="caseType" className="text-info">
                    Case Type (Marked by Branch)
                  </label>
                  <Select
                    id="caseType"
                    name="caseType"
                    options={caseTypeOptions}
                    onChange={(e) => {
                      setFieldValue("caseType", e.value);
                    }}
                  />
                  <ErrorMessage
                    name="caseType"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="dispensedAmount" className="text-info">
                    Dispensed Amount
                  </label>
                  <Field
                    type="number"
                    className="form-control rs-input"
                    id="dispensedAmount"
                    name="dispensedAmount"
                  />
                  <ErrorMessage
                    name="dispensedAmount"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="retractAmount" className="text-info">
                    Retract Amount
                  </label>
                  <Field
                    type="number"
                    className="form-control rs-input"
                    id="retractAmount"
                    name="retractAmount"
                  />
                  <ErrorMessage
                    name="retractAmount"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="recoveredAmount" className="text-info">
                    Recovered Amount
                  </label>
                  <Field
                    type="number"
                    className="form-control rs-input"
                    id="recoveredAmount"
                    name="recoveredAmount"
                  />
                  <ErrorMessage
                    name="recoveredAmount"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="status" className="text-info">
                    Status
                  </label>
                  <Select
                    id="atmStatus"
                    name="atmStatus"
                    options={atmStatusOptions}
                    onChange={(e) => {
                      setFieldValue("atmStatus", e.value);
                    }}
                  />
                  <ErrorMessage
                    name="status"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="branchRemarks" className="text-info">
                    Branch Remarks (Optional)
                  </label>
                  <Field
                    as="textarea"
                    className="form-control rs-input"
                    id="branchRemarks"
                    name="branchRemarks"
                    rows="2"
                  />
                </div>
              </div>
              <Modal.Footer>
                <div className="row d-flex justify-content-end">
                  <ButtonBasic
                    title="Cancel"
                    type="button"
                    onClick={() => setShowModal(false)}
                  />
                  <ButtonBasic title="Submit" onClick={handleSubmit} />
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
export default SuspiciousTransactionModal;
