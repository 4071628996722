import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import { useDateTime } from "../../../components/Helper/DateTime";
const POSDetailViewModal = ({ id }) => {
  const { currency } = useDateTime();
  const [data] = useState([
    {
      id: 1,
      pos_terminal_id: 12324,
      transaction: 234,
      purchases: 65,
      refunds: 53,
      reversals: 76,
      refund_reversals: 1234,
    },
    {
      id: 2,
      pos_terminal_id: 12324,
      transaction: 234,
      purchases: 65,
      refunds: 53,
      reversals: 76,
      refund_reversals: 1234,
    },
  ]);
  const columns = [
    { hidden: true, dataField: "id", text: "ID" },
    {
      dataField: "pos_terminal_id",
      text: "POS Terminal ID",
    },
    {
      dataField: "transaction",
      // formatter:
      text: "Transaction (Count)",
    },
    {
      dataField: "purchases",
      // formatter:
      text: "Purchases (Amount)",
    },
    {
      dataField: "refunds",
      // formatter:
      text: "Refunds (Amount)",
    },
    {
      dataField: "reversals",
      // formatter:
      text: "Reversals (Amount)",
    },
    {
      dataField: "refund_reversals",
      // formatter:
      text: "Refund Reversals (Amount)",
    },
  ];
  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-sm-12 col-lg-4">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Merchant Id:</div>
            <div className="value  ">{"ABC123"}</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Location:</div>
            <div className="value  ">{"Silver Street"}</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">GL Account:</div>
            <div className="value  ">{"123456"}</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-12">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Merchant Type:</div>
            <div className="value  ">{"Computers, Equipments, Software"}</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">GL Opening Balance:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">GL Closing Balance:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-12">Total amount:</div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Purchases:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Refunds:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Purchase Reversals:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Refund Reversals:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Chargebacks:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Rejected Payments:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-12">
          <div className="key-value-wrapper info-tile">
            <div className="key color-light">Net Amount:</div>
            <div className="value  ">{currency} 2000</div>
          </div>
        </div>
      </div>
      <div className="row p-3">
        <div className="ml-2 mb-3 mt-2  ">POS Devices</div>
        <BootstrapTable
          bootstrap4={true}
          classes="rs-table table-dim-dark border-0 modal-table table-layout-auto fancy-scroll table-custom-style"
          wrapperClasses="overflow-y-auto"
          keyField="cashDisbursementAmount"
          data={data}
          columns={columns}
        ></BootstrapTable>
      </div>
    </div>
  );
};
export default POSDetailViewModal;
