import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FormCheck, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select, { components } from "react-select";
import ButtonBasic from "../../../../components/Buttons/ButtonBasic";
import ButtonTransparent from "../../../../components/Buttons/ButtonTransparent";
import AddIcon from "../../../../assets/images/add-icon.png";
import Toast from "../../../../components/Toast/Toast";
import OperatorDescription from "../OperatorDescription.json";
import {
  DSBORValidationSchema,
  DSBValidationSchema,
  errorStyling,
} from "../ValidationSchema";

const DSBModal = ({
  values,
  operatorOptions,
  dsaFields,
  dsbFields,
  handleSubmit,
  show,
  onHide,
  editObject,
}) => {
  const [formValues, setFormValues] = useState(values);
  const [validateCheck, setValidateCheck] = useState(false);
  useEffect(() => {
    if (values) {
      setFormValues(values);
      values?.hasOwnProperty("OR")
        ? setValidateCheck(true)
        : setValidateCheck(false);
    }
  }, [values]);
  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={(values) => {
        if (values?.hasOwnProperty("OR") && values?.OR?.length < 2) {
          Toast("Please add atleast two fields", "error");
          return;
        }
        if (!values?.hasOwnProperty("OR") && values?.length === 0) {
          Toast("Please add atleast one field", "error");
          return;
        }
        if (values?.hasOwnProperty("OR")) {
          for (let i = 0; i < values?.OR?.length; i++) {
            if (
              !values?.OR[i]?.ds_a_field_name &&
              !values?.OR[i]?.ds_b_field_value
            ) {
              Toast(
                "It's mandatory to input the value of one field from the DSA Field Name or the DSB Field Value",
                "error"
              );
              return;
            }
            if (values?.OR[i]?.operator === "BTW") {
              if (
                values?.OR[i]?.tolerance_days[0] === "" ||
                values?.OR[i]?.tolerance_days[1] === ""
              ) {
                Toast(
                  "Min Tol and Max Tol are mandatory for BTW operator",
                  "error"
                );
                return;
              }
            }
          }
        } else {
          for (let i = 0; i < values?.length; i++) {
            if (
              !values?.[i]?.ds_a_field_name &&
              !values?.[i]?.ds_b_field_value
            ) {
              Toast(
                "It's mandatory to input the value of one field from the DSA Field Name or the DSB Field Value",
                "error"
              );
              return;
            }
            if (values?.[i]?.operator === "BTW") {
              if (
                values?.[i]?.tolerance_days[0] === "" ||
                values?.[i]?.tolerance_days[1] === ""
              ) {
                Toast(
                  "Min Tol and Max Tol are mandatory for BTW operator",
                  "error"
                );
                return;
              }
            }
          }
        }
        //exlude the empty fields from object
        if (values?.hasOwnProperty("OR")) {
          values = {
            ...values,
            OR: values?.OR?.map((item) =>
              Object.fromEntries(
                Object.entries(item).filter(([, v]) => v !== "")
              )
            ),
          };
        } else {
          values = [
            ...values?.map((item) =>
              Object.fromEntries(
                Object.entries(item).filter(([, v]) => v !== "")
              )
            ),
          ];
        }
        handleSubmit(editObject?.path, values);
        setFormValues({});
        onHide();
      }}
      validationSchema={
        validateCheck ? DSBORValidationSchema : DSBValidationSchema
      }
    >
      {({ handleSubmit, errors, values, setFieldValue, setValues }) => (
        <Form id="dsbForm" className="form" onSubmit={handleSubmit}>
          <Modal
            backdrop={true}
            size="xl"
            aria-labelledby="example-modal-sizes-title-lg"
            show={show}
            onHide={() => onHide()}
          >
            <Modal.Header closeButton>
              {editObject?.isNew
                ? "Add DSB Fetch Criteria Field"
                : "Edit DSB Fetch Criteria Field"}
            </Modal.Header>
            <Modal.Body scrollable="true">
              {values?.hasOwnProperty("OR") ? (
                <div className="p-3 my-3">
                  <div className="row">
                    <div className="col-4">
                      {/* Select Field for DSB Fetch Criteria it decide the operation between Fields object both AND OR has different object structure */}
                      <Select
                        options={[
                          {
                            label: "And Operations",
                            value: "AND",
                          },
                          {
                            label: "OR Operations",
                            value: "OR",
                          },
                        ]}
                        value={
                          values?.hasOwnProperty("OR")
                            ? {
                                label: "OR Operations",
                                value: "OR",
                              }
                            : {
                                label: "And Operations",
                                value: "AND",
                              }
                        }
                        onChange={(e) => {
                          if (
                            !values?.hasOwnProperty("OR") &&
                            e.value === "OR"
                          ) {
                            //OR operation Object Structure is same as AND operation but all objects array should be in OR
                            setValues({
                              OR: [...values],
                            });
                            setValidateCheck(true);
                          } else if (
                            values?.hasOwnProperty("OR") &&
                            e.value === "AND"
                          ) {
                            //AND operation Object Structure default like it before with any OR key it inside the Field Array
                            setValues([...values.OR]);
                            setValidateCheck(false);
                          }
                        }}
                      />
                    </div>
                    <div className="col-8 d-flex justify-content-end align-items-center">
                      {" "}
                      {/* Add Button to Add DSB Fetch Criteria Fields */}
                      <ButtonTransparent
                        title={"Add Field"}
                        icon={AddIcon}
                        onClick={() => {
                          setFieldValue("OR", [
                            ...values.OR,
                            {
                              label: "",
                              ds_a_field_name: "",
                              ds_b_field_name: "",
                              operator: "",
                            },
                          ]);
                        }}
                        border
                      />
                    </div>
                  </div>
                  <hr className="my-4" />
                  {values.OR.map((item, index) => (
                    <>
                      {index > 0 && <hr className="my-4" />}
                      <div className="row mb-3">
                        <div className="col-3">
                          {/* DS A Field Name DropDown For DSB Fetch Criteria */}
                          <div className="workflowModalTitle">
                            DSA Field Name
                          </div>
                          <div title={item?.ds_a_field_name}>
                            <Select
                              options={dsaFields}
                              name={"ds A Field Name"}
                              value={dsaFields?.find(
                                (field) => field.value === item?.ds_a_field_name
                              )}
                              onChange={(e) => {
                                const newValue = e?.value;

                                const dsBFieldName = item?.ds_b_field_name;
                                //check if ds_b_field_name and ds_a_field_name is recon_account or account_id
                                //if yes then show check_relationship and check_relationship should be true
                                if (
                                  newValue === "recon_account" ||
                                  newValue === "account_id" ||
                                  dsBFieldName === "recon_account" ||
                                  dsBFieldName === "account_id"
                                ) {
                                  if (!item.check_relationship) {
                                    setFieldValue(`OR.[${index}]`, {
                                      ...item,
                                      check_relationship: false,
                                    });
                                  }
                                } else {
                                  // if not then don't show check_relationship
                                  const {
                                    check_relationship,
                                    ...updatedField
                                  } = item;
                                  setFieldValue(`OR.[${index}]`, {
                                    ...updatedField,
                                  });
                                }
                                // Assign the new value to the ds_a_field_name field
                                setFieldValue(
                                  `OR.[${index}].ds_a_field_name`,
                                  newValue
                                );
                              }}
                              isClearable
                            />
                          </div>
                        </div>

                        <div className="col-3">
                          {/* Operator DropDown For DSB Fetch Criteria  */}
                          <div className="workflowModalTitle">Operator</div>
                          <div
                            title={
                              operatorOptions?.rightCriteriaOperators?.find(
                                (operator) => operator.value === item?.operator
                              )?.label
                            }
                          >
                            <Select
                              options={operatorOptions?.rightCriteriaOperators}
                              name={"Operator"}
                              value={operatorOptions?.rightCriteriaOperators?.find(
                                (operator) => operator.value === item?.operator
                              )}
                              onChange={(e) => {
                                // if operator is BTW then set tolerance_days to [0,0]
                                if (e?.value === "BTW") {
                                  const { tolerance_days, operator, ...obj } =
                                    item;
                                  setFieldValue(`OR.[${index}]`, {
                                    tolerance_days: [0, 0],
                                    operator: e?.value,
                                    ...obj,
                                  });
                                } else {
                                  // if operator is not BTW then remove tolerance_days
                                  const {
                                    tolerance_days,
                                    operator,
                                    ...updatedField
                                  } = item;
                                  setFieldValue(`OR.[${index}]`, {
                                    ...updatedField,
                                    operator: e?.value,
                                  });
                                }
                              }}
                              components={{
                                Option: (props) =>
                                  OperatorDescription?.find(
                                    (op) => op?.operator == props?.data?.value
                                  )?.description ? (
                                    <OverlayTrigger
                                      placement="right"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          {
                                            OperatorDescription?.find(
                                              (op) =>
                                                op?.operator ==
                                                props?.data?.value
                                            )?.description
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <components.Option {...props} />
                                      </div>
                                    </OverlayTrigger>
                                  ) : (
                                    <components.Option {...props} />
                                  ),
                              }}
                              isClearable
                            />
                          </div>
                          <ErrorMessage
                            component={() => (
                              <div style={errorStyling}>
                                {errors?.OR?.[index]?.operator}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-3">
                          {/* DS B Field Name DropDown For DSB Fetch Criteria  */}
                          <div className="workflowModalTitle">
                            DSB Field Name
                          </div>
                          <div title={item?.ds_b_field_name}>
                            <Select
                              options={dsbFields}
                              name={"ds B Field Name"}
                              value={dsbFields?.find(
                                (field) => field.value === item?.ds_b_field_name
                              )}
                              onChange={(e) => {
                                const newValue = e?.value;

                                const dsAFieldName = item?.ds_a_field_name;
                                //check if ds_b_field_name and ds_a_field_name is recon_account or account_id
                                //if yes then show check_relationship and check_relationship should be true
                                if (
                                  newValue === "recon_account" ||
                                  newValue === "account_id" ||
                                  dsAFieldName === "recon_account" ||
                                  dsAFieldName === "account_id"
                                ) {
                                  if (!item.check_relationship) {
                                    setFieldValue(`OR.[${index}]`, {
                                      ...item,
                                      check_relationship: false,
                                    });
                                  }
                                } else {
                                  // if not then don't show check_relationship
                                  const {
                                    check_relationship,
                                    ...updatedField
                                  } = item;
                                  setFieldValue(`OR.[${index}]`, updatedField);
                                }
                                // Assign the new value to the ds_b_field_name field
                                setFieldValue(
                                  `OR.[${index}].ds_b_field_name`,
                                  newValue
                                );
                              }}
                              isClearable
                            />
                          </div>
                          <ErrorMessage
                            component={() => (
                              <div style={errorStyling}>
                                {errors?.OR?.[index]?.ds_b_field_name}
                              </div>
                            )}
                          />
                        </div>
                        {/* Show Tolerance Days when operator is BTW */}
                        {item?.operator === "BTW" && (
                          <div className="col-1">
                            <div className="workflowModalTitle">Min Tol</div>
                            <Field
                              className="form-control rs-input highlight"
                              type="number"
                              name={`OR.[${index}].tolerance_days[0]`}
                              onChange={(e) => {
                                if (Number(e?.target?.value) > 0) {
                                  return;
                                }
                                setFieldValue(
                                  `OR.[${index}].tolerance_days[0]`,
                                  e?.target?.value
                                );
                              }}
                              style={{ width: "70px" }}
                            />
                            <ErrorMessage
                              component={() => (
                                <div style={errorStyling}>
                                  {errors?.OR?.[index]?.tolerance_days}
                                </div>
                              )}
                            />
                          </div>
                        )}
                        {item?.operator === "BTW" && (
                          <div className="col-1">
                            <div className="workflowModalTitle">Max Tol</div>
                            <Field
                              className="form-control rs-input highlight"
                              type="number"
                              name={`OR.[${index}].tolerance_days[1]`}
                              onChange={(e) => {
                                if (Number(e?.target?.value) < 0) {
                                  return;
                                }
                                setFieldValue(
                                  `OR.[${index}].tolerance_days[1]`,
                                  e?.target?.value
                                );
                              }}
                              style={{ width: "70px" }}
                            />
                          </div>
                        )}
                        {/* Tolerance Days Ends Here */}

                        <div className="col-1 d-flex align-items-center justify-content-around  pt-3">
                          <i
                            onClick={() => {
                              // remove current object
                              setFieldValue(
                                `OR`,
                                values?.OR?.filter((_, i) => i !== index)
                              );
                            }}
                            style={{ cursor: "pointer" }}
                            className={"fa fa-trash text-danger"}
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-3">
                          {/* Label Text Field For DSB Fetch Criteria  */}
                          <div className="workflowModalTitle">Label</div>
                          <Field
                            title={item?.label}
                            className="form-control rs-input highlight"
                            type="text"
                            placeholder="Label"
                            name={`OR.[${index}].label`}
                            onChange={(e) =>
                              setFieldValue(
                                `OR.[${index}].label`,
                                e.target.value
                              )
                            }
                          />
                        </div>
                        {item?.hasOwnProperty("ds_b_field_value") && (
                          <div className="col-3">
                            {/* DS B Field Value Text Field For DSB Fetch Criteria */}
                            <div className="workflowModalTitle">
                              DSB Field Value
                            </div>
                            <Field
                              title={item?.ds_b_field_value}
                              className="form-control rs-input highlight"
                              type="text"
                              placeholder="DSB Field Value"
                              name={`OR.[${index}].ds_b_field_value`}
                              onChange={(e) =>
                                setFieldValue(
                                  `OR.[${index}].ds_b_field_value`,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        )}
                        <div className="col-3">
                          {/* Provide Field Value Switch */}
                          <div className="workflowModalTitle">
                            Provide Field Value
                          </div>
                          <div className="form-check py-2">
                            <FormCheck
                              type="switch"
                              name="provideFieldValue"
                              style={{ zIndex: 0 }}
                              checked={item?.hasOwnProperty("ds_b_field_value")}
                              onChange={(e) => {
                                // If checked then show ds_b_field_value
                                if (e.target.checked) {
                                  setFieldValue(`OR.[${index}]`, {
                                    ...item,
                                    ds_b_field_value: "",
                                  });
                                } else {
                                  //remove  ds_b_field_value from object
                                  const { ds_b_field_value, ...updatedField } =
                                    item;
                                  // Update the state with the new object
                                  setFieldValue(`OR.[${index}]`, updatedField);
                                }
                              }}
                            />
                          </div>
                        </div>
                        {/* Check Account Relationship */}
                        {item?.hasOwnProperty("check_relationship") && (
                          <div className="col-3">
                            <div className="workflowModalTitle">
                              Check Account Relationship
                            </div>
                            <div className="form-check py-2">
                              <FormCheck
                                type="switch"
                                name="check_relationship"
                                style={{ zIndex: 0 }}
                                checked={item?.check_relationship}
                                onChange={(e) =>
                                  setFieldValue(
                                    `OR.[${index}].check_relationship`,
                                    e.target.checked
                                  )
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                <div className="p-3 my-3">
                  <div className="row">
                    <div className="col-4">
                      {/* Select Field for DSB Fetch Criteria it decide the operation between Fields object both AND OR has different object structure */}
                      <Select
                        options={[
                          {
                            label: "And Operations",
                            value: "AND",
                          },
                          {
                            label: "OR Operations",
                            value: "OR",
                          },
                        ]}
                        value={
                          values?.hasOwnProperty("OR")
                            ? {
                                label: "OR Operations",
                                value: "OR",
                              }
                            : {
                                label: "And Operations",
                                value: "AND",
                              }
                        }
                        onChange={(e) => {
                          if (
                            !values?.hasOwnProperty("OR") &&
                            e.value === "OR"
                          ) {
                            //OR operation Object Structure is same as AND operation but all objects array should be in OR
                            setValues({
                              OR: [...values],
                            });
                            setValidateCheck(true);
                          } else if (
                            values?.hasOwnProperty("OR") &&
                            e.value === "AND"
                          ) {
                            //AND operation Object Structure default like it before with any OR key it inside the Field Array
                            setValues([...values.OR]);
                            setValidateCheck(false);
                          }
                        }}
                      />
                    </div>
                    <div className="col-8 d-flex justify-content-end align-items-center">
                      {" "}
                      {/* Add Button to Add DSB Fetch Criteria Fields */}
                      <ButtonTransparent
                        title={"Add Field"}
                        icon={AddIcon}
                        onClick={() => {
                          setValues([
                            ...values,
                            {
                              label: "",
                              ds_a_field_name: "",
                              ds_b_field_name: "",
                              operator: "",
                            },
                          ]);
                        }}
                        border
                      />
                    </div>
                  </div>
                  <hr className="my-4" />
                  {values?.map((item, index) => (
                    <>
                      {index > 0 && <hr className="my-4" />}
                      <div className="row mb-3">
                        <div className="col-3">
                          {/* DS A Field Name DropDown For DSB Fetch Criteria */}
                          <div className="workflowModalTitle">
                            DSA Field Name
                          </div>
                          <div title={item?.ds_a_field_name}>
                            <Select
                              options={dsaFields}
                              name={"ds A Field Name"}
                              value={dsaFields?.find(
                                (field) => field.value === item?.ds_a_field_name
                              )}
                              onChange={(e) => {
                                const newValue = e?.value;

                                const dsBFieldName = item?.ds_b_field_name;
                                //check if ds_b_field_name and ds_a_field_name is recon_account or account_id
                                //if yes then show check_relationship and check_relationship should be true
                                if (
                                  newValue === "recon_account" ||
                                  newValue === "account_id" ||
                                  dsBFieldName === "recon_account" ||
                                  dsBFieldName === "account_id"
                                ) {
                                  if (!item.check_relationship) {
                                    setFieldValue(`[${index}]`, {
                                      ...item,
                                      check_relationship: false,
                                    });
                                  }
                                } else {
                                  // if not then don't show check_relationship
                                  const {
                                    check_relationship,
                                    ...updatedField
                                  } = item;
                                  setFieldValue(`[${index}]`, {
                                    ...updatedField,
                                  });
                                }
                                // Assign the new value to the ds_a_field_name field
                                setFieldValue(
                                  `[${index}].ds_a_field_name`,
                                  newValue
                                );
                              }}
                              isClearable
                            />
                          </div>
                        </div>

                        <div className="col-3">
                          {/* Operator DropDown For DSB Fetch Criteria  */}
                          <div className="workflowModalTitle">Operator</div>
                          <div
                            title={
                              operatorOptions?.rightCriteriaOperators?.find(
                                (operator) => operator.value === item?.operator
                              )?.label
                            }
                          >
                            <Select
                              options={operatorOptions?.rightCriteriaOperators}
                              name={"Operator"}
                              value={operatorOptions?.rightCriteriaOperators?.find(
                                (operator) => operator.value === item?.operator
                              )}
                              onChange={(e) => {
                                // if operator is BTW then set tolerance_days to [0,0]
                                if (e?.value === "BTW") {
                                  const { tolerance_days, operator, ...obj } =
                                    item;
                                  setFieldValue(`[${index}]`, {
                                    tolerance_days: [0, 0],
                                    operator: e?.value,
                                    ...obj,
                                  });
                                } else {
                                  // if operator is not BTW then remove tolerance_days
                                  const {
                                    tolerance_days,
                                    operator,
                                    ...updatedField
                                  } = item;
                                  setFieldValue(`[${index}]`, {
                                    ...updatedField,
                                    operator: e?.value,
                                  });
                                }
                              }}
                              components={{
                                Option: (props) =>
                                  OperatorDescription?.find(
                                    (op) => op?.operator == props?.data?.value
                                  )?.description ? (
                                    <OverlayTrigger
                                      placement="right"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          {
                                            OperatorDescription?.find(
                                              (op) =>
                                                op?.operator ==
                                                props?.data?.value
                                            )?.description
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <components.Option {...props} />
                                      </div>
                                    </OverlayTrigger>
                                  ) : (
                                    <components.Option {...props} />
                                  ),
                              }}
                              isClearable
                            />
                          </div>
                          <ErrorMessage
                            component={() => (
                              <div style={errorStyling}>
                                {errors?.[index]?.operator}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-3">
                          {/* DS B Field Name DropDown For DSB Fetch Criteria  */}
                          <div className="workflowModalTitle">
                            DSB Field Name
                          </div>
                          <div title={item?.ds_b_field_name}>
                            <Select
                              options={dsbFields}
                              name={"ds B Field Name"}
                              value={dsbFields?.find(
                                (field) => field.value === item?.ds_b_field_name
                              )}
                              onChange={(e) => {
                                const newValue = e?.value;

                                const dsAFieldName = item?.ds_a_field_name;
                                //check if ds_b_field_name and ds_a_field_name is recon_account or account_id
                                //if yes then show check_relationship and check_relationship should be true
                                if (
                                  newValue === "recon_account" ||
                                  newValue === "account_id" ||
                                  dsAFieldName === "recon_account" ||
                                  dsAFieldName === "account_id"
                                ) {
                                  if (!item.check_relationship) {
                                    setFieldValue(`[${index}]`, {
                                      ...item,
                                      check_relationship: false,
                                    });
                                  }
                                } else {
                                  // if not then don't show check_relationship
                                  const {
                                    check_relationship,
                                    ...updatedField
                                  } = item;
                                  setFieldValue(`[${index}]`, updatedField);
                                }
                                // Assign the new value to the ds_b_field_name field
                                setFieldValue(
                                  `[${index}].ds_b_field_name`,
                                  newValue
                                );
                              }}
                              isClearable
                            />
                          </div>
                          <ErrorMessage
                            component={() => (
                              <div style={errorStyling}>
                                {errors?.[index]?.ds_b_field_name}
                              </div>
                            )}
                          />
                        </div>
                        {/* Show Tolerance Days when operator is BTW */}
                        {item?.operator === "BTW" && (
                          <div className="col-1">
                            <div className="workflowModalTitle">Min Tol</div>
                            <Field
                              className="form-control rs-input highlight"
                              type="number"
                              name={`[${index}].tolerance_days[0]`}
                              onChange={(e) => {
                                if (Number(e?.target?.value) > 0) {
                                  return;
                                }
                                setFieldValue(
                                  `[${index}].tolerance_days[0]`,
                                  e?.target?.value
                                );
                              }}
                              style={{ width: "70px" }}
                            />
                            <ErrorMessage
                              component={() => (
                                <div style={errorStyling}>
                                  {errors?.[index]?.tolerance_days}
                                </div>
                              )}
                            />
                          </div>
                        )}
                        {item?.operator === "BTW" && (
                          <div className="col-1">
                            <div className="workflowModalTitle">Max Tol</div>
                            <Field
                              className="form-control rs-input highlight"
                              type="number"
                              name={`[${index}].tolerance_days[1]`}
                              onChange={(e) => {
                                if (Number(e?.target?.value) < 0) {
                                  return;
                                }
                                setFieldValue(
                                  `[${index}].tolerance_days[1]`,
                                  e?.target?.value
                                );
                              }}
                              style={{ width: "70px" }}
                            />
                          </div>
                        )}
                        {/* Tolerance Days Ends Here */}

                        <div className="col-1 d-flex align-items-center justify-content-around  pt-3">
                          <i
                            onClick={() => {
                              // remove current object
                              setValues(values?.filter((_, i) => i !== index));
                            }}
                            style={{ cursor: "pointer" }}
                            className={"fa fa-trash text-danger"}
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-3">
                          {/* Label Text Field For DSB Fetch Criteria  */}
                          <div className="workflowModalTitle">Label</div>
                          <Field
                            title={item?.label}
                            className="form-control rs-input highlight"
                            type="text"
                            placeholder="Label"
                            name={`[${index}].label`}
                            onChange={(e) =>
                              setFieldValue(`[${index}].label`, e.target.value)
                            }
                          />
                        </div>
                        {item?.hasOwnProperty("ds_b_field_value") && (
                          <div className="col-3">
                            {/* DS B Field Value Text Field For DSB Fetch Criteria */}
                            <div className="workflowModalTitle">
                              DSB Field Value
                            </div>
                            <Field
                              title={item?.ds_b_field_value}
                              className="form-control rs-input highlight"
                              type="text"
                              placeholder="DSB Field Value"
                              name={`[${index}].ds_b_field_value`}
                              onChange={(e) =>
                                setFieldValue(
                                  `[${index}].ds_b_field_value`,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        )}
                        <div className="col-3">
                          {/* Provide Field Value Switch */}
                          <div className="workflowModalTitle">
                            Provide Field Value
                          </div>
                          <div className="form-check py-2">
                            <FormCheck
                              type="switch"
                              name="provideFieldValue"
                              style={{ zIndex: 0 }}
                              checked={item?.hasOwnProperty("ds_b_field_value")}
                              onChange={(e) => {
                                // If checked then show ds_b_field_value
                                if (e.target.checked) {
                                  setFieldValue(`[${index}]`, {
                                    ...item,
                                    ds_b_field_value: "",
                                  });
                                } else {
                                  //remove  ds_b_field_value from object
                                  const { ds_b_field_value, ...updatedField } =
                                    item;
                                  // Update the state with the new object
                                  setFieldValue(`[${index}]`, updatedField);
                                }
                              }}
                            />
                          </div>
                        </div>
                        {/* Check Account Relationship */}
                        {item?.hasOwnProperty("check_relationship") && (
                          <div className="col-3">
                            <div className="workflowModalTitle">
                              Check Account Relationship
                            </div>
                            <div className="form-check py-2">
                              <FormCheck
                                type="switch"
                                name="check_relationship"
                                style={{ zIndex: 0 }}
                                checked={item?.check_relationship}
                                onChange={(e) =>
                                  setFieldValue(
                                    `[${index}].check_relationship`,
                                    e.target.checked
                                  )
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <ButtonBasic
                  id="cancel"
                  title="Cancel"
                  onClick={() => onHide()}
                />
              </div>
              <button
                id="submit"
                type="submit"
                name="submit"
                className={"btn btn-info btn-md mr-2"}
                onClick={handleSubmit}
              >
                {editObject?.isNew ? "Add" : "Update"}
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default DSBModal;
