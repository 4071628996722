import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import axiosInstance from "../../utils/axiosInstance.js";
import Toast from "../../components/Toast/Toast.js";
import ButtonBasic from "../../components/Buttons/ButtonBasic.js";
import buttonChecker from "../../utils/buttonsPermissionsChecker.js";
import AddCassettesConfiguration from "../ATMModule/ATMGLBalancing/AddCassettesConfiguration.js";
import { useDateTime } from "../../components/Helper/DateTime.js";
import LoaderComponent from "../../components/Spinner/LoaderComponent.jsx";
import { WorkQueueLogStyle } from "./Styles.js";
import { CustomTooltip } from "../../utils/helpers/custom-tooltip.js";
import { Alert } from "react-bootstrap";
import { DateTimeFormat } from "../../utils/formatDateTime.js";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded.js";

const FormSchema = Yup.object().shape({
  comment: Yup.string().min(2, "Too Short!").required("Required*"),
});

const AtmGLBalancingModal = ({
  workItemId,
  handleCloseModal,
  fetchWorkQueue,
  show,
  onHide,
}) => {
  const { currency, dateTime } = useDateTime();
  const [workQueueData, setWorkQueueData] = useState({});
  const [loadingModal, setLoadingModal] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));

  const getWorkQueue = useCallback(async () => {
    setLoadingModal(true);
    try {
      const res = await axiosInstance.get(
        `/work-queue/work-item?itemId=${workItemId}`
      );
      if (res.message.status !== "200") {
        setLoadingModal(false);
        Toast(res.message.description, "error");
      } else {
        setWorkQueueData(res?.data);
        setLoadingModal(false);
      }
    } catch (err) {
      setLoadingModal(false);
      console.log("error", err);
    }
  }, [workItemId]);

  useEffect(() => {
    if (workItemId) {
      getWorkQueue();
    }
  }, [getWorkQueue, workItemId]);

  const addNote = (values) => {
    let now = moment();
    let note = {
      comment: values?.comment,
      author: `${userData?.firstName} ${userData?.lastName}`,
      date: now.format("DD/MM/YY"),
      time: now.hour() + ":" + now.minute(),
    };
    // setComment("");
    let notes = workQueueData?.notes || [];
    notes.push(note);
    setWorkQueueData({ ...workQueueData, notes: notes });
  };

  const handleDone = async (value) => {
    setLoadingModal(true);
    try {
      let response = await axiosInstance.put("/work-queue/work-item", {
        workItemId: workItemId,
        action: value,
        notes: workQueueData?.notes,
        userId: userData?.userId,
      });
      if (response.message.status !== "200") {
        setLoadingModal(false);
        Toast(response.message.description, "error");
      } else {
        Toast(response.message.description, "success");
        handleCloseModal();
        setLoadingModal(false);
        fetchWorkQueue();
        setWorkQueueData(null);
        setShowNotes(false);
      }
    } catch (err) {
      setLoadingModal(false);
      Toast(err.response.data.message.description, "error");
      console.log(err);
    }
  };
  const Status = ({ cell }) => (
    <BadgeRounded
      color={
        cell === "CASH BALANCED"
          ? "success"
          : cell === "CASH SHORTAGE"
          ? "danger"
          : ""
      }
      customStyle={
        cell === "CASH EXCESS" ? { backgroundColor: "#007bff" } : null
      }
      title={cell}
    />
  );
  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      "0"
    );
  return (
    <>
      <WorkQueueLogStyle>
        <Modal
          backdrop={true}
          size="xl"
          show={show}
          onHide={() => {
            onHide();
            setShowNotes(false);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header className="pb-0" closeButton>
            <Modal.Title id="work-queue-cash-replenishment-flow-title">
              Queue Item: {workItemId || "Nill"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body scrollable="true">
            {loadingModal && (
              <div
                className="spinner-center"
                style={{ top: "0%", left: "0%", zIndex: 2000 }}
              >
                <LoaderComponent />
              </div>
            )}
            <div className="container">
              <div
                className="row"
                style={{
                  opacity: !loadingModal ? "1" : "0.07",
                }}
              >
                <div
                  className={`${showNotes ? "col-8" : "col-12"} overflow-auto`}
                >
                  <div className="row">
                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="key color-light">Device ID: </div>
                        <div className="value">
                          {workQueueData?.deviceId || "Nill"}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="key color-light">Last Ref</div>
                        <div className="value  ">
                          {workQueueData?.lastRef || "Nill"}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="key color-light">Raised By</div>
                        <div className="value  ">
                          {workQueueData?.raisedBy || "Nill"}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="key color-light">Authorizer</div>
                        <div className="value  ">
                          {workQueueData?.authorizer || "Nill"}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="key color-light">
                          Replenishment Date And Time
                        </div>
                        <div className="value  ">
                          {workQueueData?.dateTime
                            ? DateTimeFormat(dateTime, workQueueData?.dateTime)
                            : "Nill"}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                      <div className="key-value-wrapper info-tile">
                        <div className="key color-light">
                          switch Transaction Total
                        </div>
                        <div className="value  ">
                          {workQueueData?.switchTransactionTotal || "Nill"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {showNotes && (
                  <div className="col-4">
                    <div className="notes-wrapper">
                      <h5 className="mb-3 color-gray-dim">Notes</h5>
                      <div
                        className="overflow-auto fancy-scroll"
                        style={{ height: "7.5em" }}
                      >
                        {workQueueData?.notes?.map(
                          ({ author, comment, date, time }, i) => (
                            <ul className="rs-notes-container" key={i}>
                              <li className="rs-notes-item">
                                <h6>{author}</h6>
                                <div className="note-comment">{comment}</div>
                                <sub>
                                  {date} {time}
                                </sub>
                              </li>
                            </ul>
                          )
                        )}
                      </div>
                      <Formik
                        initialValues={{
                          comment: "",
                        }}
                        validationSchema={FormSchema}
                        onSubmit={(values, { resetForm }) => {
                          addNote(values);
                          resetForm({ values: "" });
                        }}
                      >
                        {({ errors, touched, handleSubmit, handleReset }) => (
                          <Form
                            id="add-notes"
                            className="form"
                            onSubmit={handleSubmit}
                            onReset={handleReset}
                          >
                            <div className="row mt-3">
                              <div className="col-12 mt-3">
                                <div className="d-flex flex-column">
                                  <Field
                                    as="textarea"
                                    className="rs-input"
                                    placeholder="Write a Note"
                                    rows="3"
                                    name="comment"
                                  />
                                  {errors.comment && touched.comment ? (
                                    <div className="field-error">
                                      {errors.comment}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="w-100 mt-2">
                              <ButtonBasic
                                type="submit"
                                classes={"primary px-4 w-100"}
                                title={"Add Note"}
                                onClick={handleSubmit}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                    {!workQueueData?.notes ? (
                      <div className="field-error">
                        Notes field is required incase of Reject/Approve*{" "}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <Formik>
              {() => {
                return (
                  <>
                    <div
                      className="container mt-4"
                      style={{
                        opacity: !loadingModal ? "1" : "0.07",
                      }}
                    >
                      <div className="container-fluid">
                        {/* First Section */}
                        {workQueueData?.payload?.remainingCashATM?.length >
                          0 && (
                          <section className="border border-dark p-2 py-3">
                            <div className="row">
                              <div className="col-5">
                                <h5 className="text-left text-bold">
                                  REMAINING CASH IN THE ATMs
                                </h5>
                                <div className="text-left pl-5">
                                  (Remaining cash in each ATM that physicaly
                                  counted at the time of Cash Replenishment at
                                  12:00 Hrs for balancing of ATM Cash for the
                                  day )
                                </div>
                              </div>
                              <div className="col-7">
                                <AddCassettesConfiguration
                                  rowsData={
                                    workQueueData?.payload?.remainingCashATM ||
                                    []
                                  }
                                  isDisabled={true}
                                />
                              </div>
                            </div>
                          </section>
                        )}

                        {/* Second section */}
                        {workQueueData?.payload?.additionalCashReplenishment
                          ?.length > 0 && (
                          <section className="border border-dark p-2 py-3 mt-3">
                            <div className="row">
                              <div className="col-5">
                                <h5 className="text-left text-bold">
                                  ADDITIONAL CASH REPLENISHED, IF ANY
                                </h5>
                                <div className="text-left pl-5">
                                  (Amount of 'additional cash replenished' after
                                  midnight (00:00) and before the Cash
                                  Replenishment time - 12:00 Hrs for the day. )
                                </div>
                              </div>
                              <div className="col-7">
                                <AddCassettesConfiguration
                                  rowsData={
                                    workQueueData?.payload
                                      ?.additionalCashReplenishment || []
                                  }
                                  isDisabled={true}
                                />
                              </div>
                            </div>
                          </section>
                        )}

                        {/* 3rd Section */}
                        <section className="border border-dark  p-2 py-3 mt-3">
                          <div className="row">
                            <div className="col-5">
                              <h5 className="text-left text-bold">
                                TOTAL CASH IN ATMs AT '00:00 Hrs' FOR THE DAY
                              </h5>
                            </div>
                            <div className="offset-3 col-3">
                              <h5 className="border border-dark text-dark text-center py-2">
                                Total:{" "}
                                {formatAmount(workQueueData?.cashUnloadedTotal)}
                              </h5>
                            </div>
                          </div>
                        </section>

                        {/* 4th Section */}
                        {workQueueData?.payload?.cashDispensedEOD?.length >
                          0 && (
                          <section className="border border-dark p-2 py-3 mt-3">
                            <div className="row">
                              <div className="col-5">
                                <h5 className="text-left text-bold">
                                  CASH DISPENSED AFTER END-OF-DAY
                                </h5>
                                <div className="text-left pl-5">
                                  (Note down the 'FIRST' transaction sequence
                                  number after midnight (00:00) Hrs and 'LAST'
                                  transaction sequence number before the
                                  replenishment time (12:00 Hrs) for the day to
                                  determine despensed amount value of each ATM
                                  from RAMS report. )
                                </div>
                              </div>
                              <div className="col-7">
                                <AddCassettesConfiguration
                                  rowsData={
                                    workQueueData?.payload?.cashDispensedEOD ||
                                    []
                                  }
                                  isDisabled={true}
                                />
                              </div>
                            </div>
                          </section>
                        )}

                        {/* 5th Section */}
                        <section className="border border-dark p-2 py-3 mt-3">
                          <div className="row">
                            <div className="col-5">
                              <h5 className="text-left text-bold">
                                Status of cash as per General Ledger
                              </h5>
                            </div>
                            <div className="offset-4 col-3">
                              <div className="form-group m-0">
                                <input
                                  className="form-control rs-input"
                                  placeholder="GL Balance Total"
                                  value={workQueueData?.glBalanceTotal}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </section>

                        {/* 6th Section */}
                        <section className="p-2 py-3 mt-3">
                          <div className="row">
                            <div className="col-5">
                              <h5 className="text-left text-bold">
                                Difference of Physical Cash VS Cash with ATM GL
                              </h5>
                            </div>
                            <div className="col-2 ">
                              {workQueueData?.cashUnloadedTotal >
                              workQueueData?.glBalanceTotal ? (
                                <Status cell={"CASH EXCESS"} />
                              ) : workQueueData?.cashUnloadedTotal <
                                workQueueData?.glBalanceTotal ? (
                                <Status cell={"CASH SHORT"} />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="offset-1 col-3">
                              <h5 className="border border-dark text-dark text-center py-2">
                                Total:{" "}
                                {formatAmount(
                                  workQueueData?.cashUnloadedTotal -
                                    workQueueData?.glBalanceTotal
                                )}
                              </h5>
                            </div>
                          </div>
                        </section>

                        <br />
                      </div>

                      <div className="container">
                        <div className="row">
                          <div className="col-sm-5 mt-3">
                            {workQueueData?.workItemStatus === "APPROVED" ||
                            workQueueData?.workItemStatus === "DECLINED" ? (
                              ""
                            ) : (
                              <div className="alert alert-danger" role="alert">
                                This work item requires approval.
                              </div>
                            )}
                          </div>
                          <div
                            className="offset-sm-3 col-sm-4 text-right mt-3 d-flex"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <ButtonBasic
                              title={showNotes ? "Hide Notes" : "Show Notes"}
                              onClick={() => {
                                setShowNotes(!showNotes);
                                //In Modal Move to Top for Showing Notes Section
                                document
                                  .getElementsByClassName("modal")?.[0]
                                  .scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                              }}
                            />
                            <CustomTooltip
                              placement="top"
                              tooltipId="button-tooltip-2"
                              disabled={
                                !buttonChecker("clk_authorise_work_item") ||
                                workQueueData?.workItemStatus === "APPROVED" ||
                                workQueueData?.workItemStatus === "DECLINED" ||
                                !workQueueData?.notes ||
                                workQueueData?.raisedById === userData?.userId
                              }
                              disabledMessage={
                                workQueueData?.workItemStatus === "APPROVED"
                                  ? "Already Approved"
                                  : workQueueData?.workItemStatus === "DECLINED"
                                  ? "Already Declined"
                                  : workQueueData?.raisedById ===
                                    userData?.userId
                                  ? "You are not allowed to approve or reject the work item"
                                  : !workQueueData?.notes
                                  ? "Add Notes"
                                  : "Permission Denied"
                              }
                              onClick={() => {
                                if (
                                  workQueueData?.notes &&
                                  workQueueData?.notes !== undefined
                                ) {
                                  handleDone(0);
                                }
                              }}
                              buttonText="Reject"
                            >
                              Reject
                            </CustomTooltip>{" "}
                            <CustomTooltip
                              placement="top"
                              tooltipId="button-tooltip-2"
                              disabled={
                                !buttonChecker("clk_authorise_work_item") ||
                                workQueueData?.workItemStatus === "APPROVED" ||
                                workQueueData?.workItemStatus === "DECLINED" ||
                                !workQueueData?.notes ||
                                workQueueData?.raisedById === userData?.userId
                              }
                              disabledMessage={
                                workQueueData?.workItemStatus === "APPROVED"
                                  ? "Already Approved"
                                  : workQueueData?.workItemStatus === "DECLINED"
                                  ? "Already Declined"
                                  : workQueueData?.raisedById ===
                                    userData?.userId
                                  ? "You are not allowed to approve or reject the work item"
                                  : !workQueueData?.notes
                                  ? "Add Notes"
                                  : "Permission Denied"
                              }
                              onClick={() => handleDone(1)}
                              buttonText="Approve"
                            >
                              Approve
                            </CustomTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      </WorkQueueLogStyle>
    </>
  );
};
export default AtmGLBalancingModal;
