import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";

const McbWelcomeScreen = () => {
  const history = useHistory();

  const userBranch = JSON.parse(localStorage.getItem("userData"))?.branchName;
  return (
    <div className="container-fluid p-3">
      <div className="row mb-2">
        <div className="col text-center">
          <h2 className="display-5 font-weight-bold">
            Welcome to Reconstream MCB Application Dashboard
          </h2>
          <p className="text-muted">
            Start gaining insights on how your branches are performing by
            visiting the dashboard.
          </p>
        </div>
      </div>
      <div className="row d-flex justify-content-center align-items-center">
        <h3 className="text-dark border border-info p-2 w-50 text-center">
          <span className="font-weight-bold text-info">Branch: </span>
          {userBranch}
        </h3>
      </div>

      <div className="row text-center mt-4">
        <div
          className="col-md-4 cursor-pointer"
          onClick={() => history.push("/workqueue")}
        >
          <div className="card shadow-sm border-0 p-3">
            <div className="card-body">
              <i className="fa fa-tasks fa-3x mb-3 text-info"></i>
              <h5 className="card-title font-weight-bold">Work Queue</h5>
              <p className="card-text text-muted">
                Track & manage tasks efficiently, ensuring timely completion
              </p>
            </div>
          </div>
        </div>

        <div
          className="col-md-4 cursor-pointer"
          onClick={() => history.push("/atm-cash-balancing")}
        >
          <div className="card shadow-sm border-0 p-3">
            <div className="card-body">
              <i className="fa fa-bars fa-3x mb-3 text-primary"></i>
              <h5 className="card-title font-weight-bold">
                ATM Cash Balancing
              </h5>
              <p className="card-text text-muted">
                Manage Cash Balances, update details, and submit for review.
              </p>
            </div>
          </div>
        </div>

        <div
          className="col-md-4 cursor-pointer"
          onClick={() => history.push("/atm-gl-balancing")}
        >
          <div className="card shadow-sm border-0 p-3">
            <div className="card-body">
              <i className="fa fa-building fa-3x mb-3 text-info"></i>
              <h5 className="card-title font-weight-bold">ATM GL Balancing</h5>
              <p className="card-text text-muted">
                Manage all GL accounts, update details, and submit for review.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row text-center g-4 mt-4">
        <div
          className="col-md-4 cursor-pointer"
          onClick={() => history.push("/suspicious-transactions")}
        >
          <div className="card shadow-sm border-0 p-3">
            <div className="card-body">
              <i className="fa fa-exchange fa-3x mb-3 text-danger"></i>
              <h5 className="card-title font-weight-bold">
                Suspicious Transactions
              </h5>
              <p className="card-text text-muted">
                Access suspicious transaction in real-time for financial
                analysis.
              </p>
            </div>
          </div>
        </div>

        <div
          className="col-md-4 cursor-pointer"
          onClick={() => history.push("/cash-replenishment")}
        >
          <div className="card shadow-sm border-0 p-3">
            <div className="card-body">
              <i className="fa fa-money fa-3x mb-3 text-success"></i>
              <h5 className="card-title font-weight-bold">Add Cash</h5>
              <p className="card-text text-muted">
                Ensure accurate cash deposits and update ATM balances for
                seamless transactions.
              </p>
            </div>
          </div>
        </div>

        <div
          className="col-md-4 cursor-pointer"
          onClick={() => history.push("/transaction-log")}
        >
          <div className="card shadow-sm border-0 p-3">
            <div className="card-body">
              <i className="fa fa-exchange fa-3x mb-3 text-warning"></i>
              <h5 className="card-title font-weight-bold">
                Transaction History
              </h5>
              <p className="card-text text-muted">
                Access transaction records in real-time for financial analysis.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row text-center g-4 mt-4">
        <div
          className="col-md-4 cursor-pointer"
          onClick={() => history.push("/reports")}
          id="reports"
        >
          <div className="card shadow-sm border-0 p-3">
            <div className="card-body">
              <i className="fa fa-shield fa-3x mb-3 text-info"></i>
              <h5 className="card-title font-weight-bold">Reports</h5>
              <p className="card-text text-muted">
                Generate detailed financial and operational reports .
              </p>
            </div>
          </div>
        </div>

        <div
          className="col-md-4 cursor-pointer"
          onClick={() => history.push("/Accounts?typeId=1100")}
        >
          <div className="card shadow-sm border-0 --3 p-3">
            <div className="card-body">
              <i className="fa fa-user-circle fa-3x mb-3 text-danger"></i>
              <h5 className="card-title font-weight-bold">
                Accounts Management
              </h5>
              <p className="card-text text-muted">
                Understand customer preferences and optimize services
                accordingly.
              </p>
            </div>
          </div>
        </div>

        {/* <div className="col-md-4">
          <div className="card shadow-sm border-0 p-3">
            <div className="card-body">
              <i className="fa fa-line-chart fa-3x mb-3 text-success"></i>
              <h5 className="card-title font-weight-bold">
                Cash Replenishment
              </h5>
              <p className="card-text text-muted">
                Monitor and manage ATM cash levels, ensuring timely
                replenishment.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default McbWelcomeScreen;
