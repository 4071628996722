import React, { useContext } from "react";
import MultiStepFormContext from "./MultiStepFormContext";

const TotalSwitchTxn = () => {
  const { next, prev, details, setDetails } = useContext(MultiStepFormContext);
  return (
    <div className="h-100">
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-12 text-center">
            <h4>Total Switch Txn</h4>
            <p>From 00:00 till now</p>
          </div>
          <div className="col-md-12 d-flex justify-content-center">
            <div className="form-group" style={{maxWidth: "250px"}}>
            <input
              className="form-control rs-input"
              placeholder="Total Switch Txn"
              value={details?.switchTransactionTotal}
              onChange={(e) => {
                setDetails({
                  ...details,
                  switchTransactionTotal: e.target.value,
                });
              }}
            />
              {!details?.switchTransactionTotal ? (
              <div className="field-error mt-2">Please enter Total Switch Txn</div>
            ) : null}
            </div>
          </div>
          <div className="d-flex mt-5">
            <button className="btn btn-info" onClick={prev}>
              Back
            </button>
            <button
              type="button"
              className="btn btn-info ml-2"
              onClick={details.switchTransactionTotal ? next : null}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalSwitchTxn;
