import React, { useState, useEffect, useCallback, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import axiosInstance from "../../../utils/axiosInstance";
import Pagination from "../../../components/pagination/pagination";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import FilterIcon from "../../../assets/images/filter.png";
import { columns } from "./helper";
import { Modal } from "react-bootstrap";
import POSModal from "./POSDetailViewModal";
import Toast from "../../../components/Toast/Toast";
import Filter from "../../../components/FilterDropdown/GenericFilter";
import { mapdata, regionCheck } from "../../../utils/regionHelper";
import FilterDropdown from "../../../components/FilterDropdown/FilterDropdown";
import { useDateTime } from "../../../components/Helper/DateTime";
import {
  mapFilters,
  filtersParams,
  handleFilterChange,
  handleNewFilter,
} from "../../../utils/filtersHelper";
const PosDetailView = (props) => {
  const { setRefresh } = useDateTime();
  const [data, setData] = useState([
    {
      merchant_id: 1,
      name: "Merchant 1",
      location: "Location 1",
      transaction_count: 234,
      transaction_amount: 23409,
      recon_status: "Balanced",
      merchant_GL_balance: 23409,
    },
    {
      merchant_id: 2,
      name: "Merchant 2",
      location: "Location 2",
      transaction_count: 234,
      transaction_amount: 23409,
      recon_status: "Shortage",
      merchant_GL_balance: 23409,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalElements, setTotalElements] = useState(2);

  const [pageSize] = useState(10);
  // eslint-disable-next-line
  const [totalPages, setTotalPages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [filter, setFilter] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [showFilters, setShowFilters] = useState([]);
  const [id, setId] = useState("");
  const [regions, setRegions] = useState([]);
  const regionFilter = useRef("");

  const rowEvent = {
    onClick: (e, row) => {
      setShowModal(true);
      setId(row.id);
    },
  };

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  // const fetchData = useCallback(
  //   (filter, flag) => {
  //     setLoading(true);
  //     let allFilters = filtersParams(filter, regionFilter.current);
  //     const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
  //     axiosInstance
  //       .get(
  //         `/apihere?page=${flag ? 0 : page - 1}&size=${pageSize}${
  //           sort.sort_by && sort.sort_order ? order : ""
  //         }${allFilters}`
  //       )
  //       .then((res) => {
  //         if (res.message.status !== "200") {
  //           Toast(res.message.description, "error");
  //           setLoading(false);
  //           setTotalElements(0);
  //         } else {
  //           setData(res.data.transactions);
  //           setPage(res.page.number + 1);
  //           setTotalPages(res.page.totalPages);
  //           setTotalElements(res?.page?.totalElements);
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //         setLoading(false);
  //       });
  //   },
  //   [page, pageSize, sort]
  // );

  const getFilters = useCallback(() => {
    let data;
    if (props.location?.state?.data) {
      // eslint-disable-next-line
      data = props.location.state.data;
    }
    axiosInstance
      .get("hierarchical/filters?screen_id=merchant_list")
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          let regions = res.data.filters.filter(
            ({ filterName }) => filterName === "region"
          );
          let otherFilters = res.data.filters.filter(
            ({ filterName }) => filterName !== "region"
          );
          // debugger;
          let obj = mapFilters(res.data.filters.filter((x) => x.showOnScreen));
          if (regions.length > 0) {
            regions[0].filterData = mapdata(regions[0].filterData);
            regions[0].filterData.forEach((x) => {
              x.checked = true;
            });
            regionFilter.current = `&region_id=${regions[0].filterData
              .map(({ codeValue }) => codeValue)
              .join(",")}`;
          }
          setFilter(obj);
          //fetchData(obj);
          setRegions(regions);
          const filteredFilters = otherFilters.filter((x) => x.showOnScreen);
          const showFiltersData = otherFilters.filter((x) => !x.showOnScreen);
          setFilterData(filteredFilters);
          setShowFilters(showFiltersData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [props.location.state]);

  const setRegionIds = (value) => {
    if (value?.length > 0) {
      regionFilter.current = `&region_id=${value
        .map((node) => node.codeValue)
        .join(",")}`;
    } else {
      regionFilter.current = "";
    }
    // const regionsData = { ...regions };
    // regionsData.filterData = regionCheck(regions[0].filterData, value);
    // setRegions(regionsData);
  };

  // useEffect(() => {
  //   // fetchData(filter);
  //   // eslint-disable-next-line
  // }, [fetchData]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  useEffect(() => {
    setRefresh(() => () => {
      //fetchData(filter, true);
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div style={{ opacity: !loading ? "1" : "0.07" }}>
        <div className="row mb-3 align-items-center d-lg-flex">
          {regions &&
            regions.map((region) => {
              return (
                <div
                  className="filter-item mt-2 d-flex"
                  // style={{ minWidth: "210px" }}
                >
                  <FilterDropdown
                    title={region.filterLabel}
                    type="checkbox"
                    data={region.filterData}
                    onDropdownChange={(value) => {
                      setRegionIds(value);
                    }}
                  />
                </div>
              );
            })}
          {filterData.map(
            ({
              filterName,
              filterType,
              filterLabel,
              showOnScreen,
              filterId,
            }) => (
              <div
                className="filter-item mt-2 ml-3 d-flex"
                // style={{ minWidth: "210px" }}
              >
                <Filter
                  title={filterLabel}
                  filterId={filterId}
                  type={filterType}
                  data={filter[filterName]?.data}
                  filter={filter[filterName]?.filter}
                  removable={!showOnScreen}
                  onChange={(e) =>
                    handleFilterChange(e, filterName, filter, setFilter)
                  }
                  onRemove={(e) =>
                    handleNewFilter(
                      e,
                      filterData,
                      showFilters,
                      setFilter,
                      setFilterData
                    )
                  }
                />
              </div>
            )
          )}
          <div className="filter-item mt-2 ml-3">
            <Filter
              title={""}
              type={1006}
              data={showFilters}
              filter={filterData.map((x) => x.filterId)}
              field="filterId"
              onChange={(e) =>
                handleNewFilter(
                  e,
                  filterData,
                  showFilters,
                  setFilter,
                  setFilterData
                )
              }
            />
          </div>
          <div className="col d-flex justify-content-start mt-2">
            <ButtonBasic
              wrapperClass={"d-flex justify-content-end"}
              icon={FilterIcon}
              title="Filter"
              onClick={() => {
                //fetchData(filter, true);
              }}
            />
          </div>
        </div>
        <div className="row page-header d-flex justify-content-between">
          <div className="col-sm-12 col-lg-6 page-header d-flex justify-content-between m-0">
            <div className="title">Merchant List</div>
          </div>
        </div>
        <BootstrapTable
          columns={columns}
          bootstrap4={true}
          keyField="id"
          bordered={false}
          classes="rs-table table-layout-auto table-custom-style"
          wrapperClasses="overflow-y-auto fancy-scroll"
          data={data}
          rowEvents={rowEvent}
          remote={{ sort: true }}
          onTableChange={onSortTable}
          noDataIndication={"No data found!"}
        />
        <Pagination
          setPage={setPage}
          page={page}
          totalPages={1}
          totalElements={totalElements}
        />
        <div className="col-md-12">
          <Modal
            backdrop={true}
            size="xl"
            show={showModal}
            onHide={() => setShowModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <div className=" ">Merchant List</div>
            </Modal.Header>
            <Modal.Body scrollable="true">
              <POSModal id={id}></POSModal>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default PosDetailView;
