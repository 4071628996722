import { Field } from "formik";

const AddCassettesConfiguration = ({
  rowsData,
  handleChange,
  errors,
  isDisabled,
}) => {

  return rowsData?.map((data, index) => {
    const { atmId, amount } = data;
    return (
      <div
        key={index}
        className="row mt-3 d-flex justify-content-start  align-items-start"
      >
        <div className="col-md">
          {index === 0 && (
            <div>
              <div className="text-left">ATM-ID</div>
            </div>
          )}
          <Field
            className="form-control rs-input"
            placeholder=""
            name="atmId"
            value={atmId}
            onChange={(evnt) => handleChange(index, evnt)}
            disabled={true} // Disable for prefilled rows and when in review mode
          />
        </div>
        <div className="col-md">
          {index === 0 && (
            <div>
              <div className="text-left">Amount</div>
            </div>
          )}
          <Field
            type="text"
            className={`form-control rs-input`}
            placeholder=""
            name="amount"
            value={amount}
            onChange={(evnt) => {
              handleChange(index, evnt);
            }}
            disabled={isDisabled}
          />
          {errors?.amount && (
            <div className="field-error">{errors?.amount[index]}</div>
          )}
        </div>
      </div>
    );
  });
};

export default AddCassettesConfiguration;
